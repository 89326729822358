import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { constant } from '@app/utils/constant';
import AOS from 'aos';
const FreeEquipmentAndInstallationNow = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>            
            <main className="free_equipment_install_pg">
                <section className="hero_blk landing_pg_hero">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <span className="note-sec">Limited Time Offer</span>
                                <h1>Free Equipment & Installation</h1>                                    
                                <p><strong>No gimmicks</strong>. With Slomin's as your home security provider, you'll receive an advanced system complete with control panels, motion detectors, door contacts, and more.... professionally installed & absolutely FREE!</p>
                                <a href={`${constant.ALARAM_SITEURL}`} className="white_btn">Shop Now</a>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="home_safety_blk" data-aos="fade-up">
                    <div className="home_safety_inr text-center">
                        <div className="container">
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='top_tiles'>
                                        <ul>
                                            <li>Protect now.  Pay Later.</li>
                                            <li>$0 Activation Fee </li>
                                            <li>Monitoring fees starting at around: $1/day*</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sec_title">
                                        <h2>Choose Slomin's to be your <span>Home Safety Provider</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row our-offer-items">
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/home-icon.svg" alt="icon"/>
                                        <h4>Security</h4>
                                        <p>24/7/365 monitoring of your home security system. We alert the authorities if your alarm is triggered.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/assistent.svg" alt="icon"/>
                                        <h4>Environment</h4>
                                        <p>Monitor the environment in your home. We offer options for fire / smoke / CO2 / temperature / flood.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/availablity.svg" alt="icon"/>
                                        <h4>Surveillance</h4>
                                        <p>Our video surveillance options offer the ability to remotely view and monitor your home from anywhere.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>

                    <section className="security_features_blk">
                        <div className="security_features_inr container">
                            <div className="row justify-content-center header_row">
                                <div className="col-12" data-aos="fade-up">
                                    <div className="sec_title">
                                        <h2>The Slomin's Shield® <span>Home Security Features</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6" data-aos="fade-right">
                                    <div className="security_features_content">
                                        <h3>Professional Installation</h3>
                                        <p>All of our employees are licensed, drug tested, and background checked to ensure your safety. Each technician has received extensive training in installing, troubleshooting, and servicing your alarm.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" data-aos="fade-left">
                                    <div className="security_features_img">
                                        <img src="../img/Home-Automation-No-New-System-Image.jpg" alt="Home Automation"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6" data-aos="fade-right">
                                    <div className="security_features_img">
                                        <img src="../img/Heating-Service-Contract-24-7-Image-1.jpg" alt="Heating Service Contract"/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" data-aos="fade-left">
                                    <div className="security_features_content">
                                        <h3>24/7/365 Monitoring</h3>
                                        <p>Only a connection to central station monitoring will provide significant assistance if you are not home. When your alarm is triggered, we respond immediately by notifying you and the police.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6" data-aos="fade-right">
                                    <div className="security_features_content">
                                        <h3>Touch Screen Keypad</h3>
                                        <p>The keypad is your central form of communication with your alarm system. With an LCD backlight and touch screen, the keypad is easy to read and easy to use.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" data-aos="fade-left">
                                    <div className="security_features_img">
                                        <img src="../img/Keypad-being-pushed.jpg" alt="Keypad being pushed"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6" data-aos="fade-right">
                                    <div className="security_features_img">
                                        <img src="../img/Remote-App-Hero-Image.jpg" alt="Remote App"/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" data-aos="fade-left">
                                    <div className="security_features_content">
                                        <h3>Remote Control</h3>
                                        <p>Control your home from any mobile device! When you download the Slomin’s MyShield app, you can arm and disarm your alarm system from your smartphone, tablet, or other mobile device.Only a connection to central station monitoring will provide significant assistance if you are not home. When your alarm is triggered, we respond immediately by notifying you and the police.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 more_btn_blk">
                                    <a className="red_btn" href={`${constant.ALARAM_SITEURL}`}>Shop Now</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="our_services_plans" data-aos="fade-up">
                        <div className="container">
                            <div className="row text-center justify-content-center">
                                <div className="col-md-12">
                                    <div className='sec_title'>
                                        <h2>The Slomin's Shield® <span>Essential Components</span></h2>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src="../img/Product-Listing-Control-Panel-Keypad-Product-Image.jpg" alt="Control Panel Keypad"/>
                                        </div>
                                        <div className="card-content">
                                            <h4>Control Panel</h4>
                                            <p>The control panel manages all parts of your home’s security and connects to our central monitoring station—giving you peace of mind knowing your home and loved ones are safe.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src="../img/Product-Listing-Door-Window-Contact-Product-Image.jpg" alt="Door Window Contact"/>
                                        </div>
                                        <div className="card-content">
                                            <h4>Door & Window Contacts</h4>
                                            <p>Our contacts instantly notify you when someone enters through a window or door whether you’re at home or away - no matter what.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src="../img/Product-Listing-Motion-Sensor-Product-Image.jpg" alt="Motion Sensor"/>
                                        </div>
                                        <div className="card-content">
                                            <h4>Motion Sensor</h4>
                                            <p>Motion sensors add a layer of interior protection to your home’s vast areas, expansive rooms, hallways, and stairwells.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src="../img/Product-Listing-Slomin-Decal-Product-Image.jpg" alt="Slomin Decal"/>
                                        </div>
                                        <div className="card-content">
                                            <h4>Lawn & Window Decals</h4>
                                            <p>Evidence that your home has a security system is a burglar’s biggest deterrent. Slomin’s lawn signs and window decals ward off any potential intruders.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 more_btn_blk">
                                    <a className="red_btn" href={`${constant.ALARAM_SITEURL}`}>See More Products</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="faq_blk" data-aos="fade-up">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sec_title">
                                        <h2>Frequently Asked<span> Questions</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Who do I call if I need assistance with my alarm system?</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Please call our office at 1-800-ALARM-ME  for all alarm system-related assistance, inquiries, or if you're concerned with a technician's status. We are always happy to help you.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">How long does an installation take?</button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Our basic alarm system installations typically take 3-4 hours. It's also important to note additional factors such as the size of your home, equipment type, and purchase specifics will determine exactly how long the install will take. Most of our alarm system installations are complete within 1 day.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Can you takeover my existing alarm?</button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                            <p>Our home security specialists will fully examine you existing alarm system during your free at-home consultation. In most cases, we can have your current system adapt to ours.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </main> 
        </>
    );
};

export default FreeEquipmentAndInstallationNow;
