import axios from 'axios';
import { constant } from '@app/utils/constant';
export const fetchTrustPilotReviews = async (tagValue: string) => { 
  try {
    const response = await axios.get(`${constant.TRUSTPILOT_API_URL}` + '?apikey=' + constant.TRUSTPILOT_API_KEY + '&perPage=' + constant.TRUSTPILOT_PER_PAGE + '&tagValue='+tagValue + constant.TRUSTPILOT_TAG_VALUE + '&stars=5', { headers: { "Content-Type": "application/json", "Accept": "application/json" } }); 
    return response.data;
  } catch (err) {
    return { 'data': err };
  }
};





