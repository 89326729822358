import { removeWindowClass } from '@app/utils/helpers';
import axios from 'axios';
import { constant } from '@app/utils/constant';  
export const GetAppointDate = async () => { 
  const response = await axios.get(`${constant.GETAPPOINT_DATE}`).then(function (res) {
    const resp = res.data;  
    if (resp.Acknowledge == 1) {
      return resp;
    } else {
      return false;
    }
  }).catch(err => {
    return false;
  });
  return response;
};  
export const submitResidentialForm =  async (data: any) => { 
  try {
    const response = await axios.post(constant.POSTLEAD_API_URL , data); 
    return response;
  } catch (err) {
    console.log(err);
  }
}; 
export const submitBusinessOwnerForm = async (data: any) => { 
  try {
    const response = await axios.post(constant.POSTLEAD_API_URL , data); 
    return response;
  } catch (err) {
    console.log(err);
  }
};  
export const submitContactUsForm = async (data: any) => { 
  try {
    const response = await axios.post(constant.POSTLEAD_API_URL , data); 
    return response;
  } catch (err) {
    console.log(err);
  }
}; 

/* on blog details page form  */  
export const submitYourRequestForm = async (data: any) => { 
  try {
    const response = await axios.post(constant.POSTLEAD_API_URL , data); 
    return response;
  } catch (err) {
    console.log(err);
  }
}; 
   
export const FetchTrustPilotReviews = async () => { 
  const response = await axios.get(`${constant.FEDDBACK_API_URL}`).then(function (res) {
    const resp = res.data;  
    if (resp.Acknowledge == 1) {
      return resp;
    } else {
      return false;
    }
  }).catch(err => {
    return false;
  });
  return response;
};

export const getIncidentsData = async () => {  
  const response = await axios.get(`${constant.INCIDENT_REPORT_API_URL}`).then(function (res) { 
    const resp = res.data;  
    if (resp) {
      return resp;
    } else {
      return false;
    }
  }).catch(err => {
    return false;
  }); 
    return response;
};
 




