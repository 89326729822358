import React, { useEffect } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import SliderComponents from "../../components/common/SliderComponents";
import PageTitleComponent from '../../components/PageTitleComponent';
import AOS from 'aos'; 
const AboutUsOverview = () => { 
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        AOS.init({
          disable: "phone",
          duration: 700,
          easing: "ease-out-cubic",
        });
      }, []); 
    return (
        <>
         <PageTitleComponent title={"Slomin's Inc can help you feel more comfortable and secure in your home."}/> 
            <main className='about_us_main'>
                <section className="hero_blk about_overview_hero">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <h1>Your Comfort is our Business</h1>
                                <p>Slomin's is a family-owned and operated company that has served communities since 1923. The key to our success is a commitment to every individual, family, and company that uses our services. We invite you to explore how Slomin's can help you feel more comfortable and secure in your home or business. It would be our pleasure to serve you.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="commitment_blk" data-aos="fade-up">
                    <div className="commitment_inr container-xxl">
                        <div className="sec_title">
                            <h2>Our Commitment to Customers</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="commitment_box">
                                    <div className="commitment_icon">
                                        <img src="../img/user-icon.svg" alt="icon" />
                                    </div>
                                    <h5>Provide the best trained employees</h5>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="commitment_box">
                                    <div className="commitment_icon">
                                        <img src="../img/hands-heart-icon.svg" alt="icon" />
                                    </div>
                                    <h5>Respect our employees, clients, and partners</h5>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="commitment_box">
                                    <div className="commitment_icon">
                                        <img src="../img/tag-icon.svg" alt="icon" />
                                    </div>
                                    <h5>Provide competitive and affordable pricing</h5>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="commitment_box">
                                    <div className="commitment_icon">
                                        <img src="../img/hand-shake-img.svg" alt="icon" />
                                    </div>
                                    <h5>Build long-term relationships with our clients</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="company_history_blk" data-aos="fade-up">
                    <div className="company_history_inr container-xxl">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active red_btn" id="pills-1923-tab" data-bs-toggle="pill" data-bs-target="#pills-1923" type="button" role="tab" aria-controls="pills-1923" aria-selected="true">1923</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link red_btn" id="pills-1959-tab" data-bs-toggle="pill" data-bs-target="#pills-1959" type="button" role="tab" aria-controls="pills-1959" aria-selected="false">1959</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link red_btn" id="pills-1981-tab" data-bs-toggle="pill" data-bs-target="#pills-1981" type="button" role="tab" aria-controls="pills-1981" aria-selected="true">1981</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link red_btn" id="pills-1990-tab" data-bs-toggle="pill" data-bs-target="#pills-1990" type="button" role="tab" aria-controls="pills-1990" aria-selected="true">1990's</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link red_btn" id="pills-1997-tab" data-bs-toggle="pill" data-bs-target="#pills-1997" type="button" role="tab" aria-controls="pills-1997" aria-selected="true">1997</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link red_btn" id="pills-today-tab" data-bs-toggle="pill" data-bs-target="#pills-today" type="button" role="tab" aria-controls="pills-today" aria-selected="true">Today</button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-1923" role="tabpanel" aria-labelledby="pills-1923-tab">
                                <div className="timeline_wrap">
                                    <div className="timeline_img">
                                        <img src="../img/About-Us-Timeline-1923.jpg" alt="Timeline image" />
                                    </div>
                                    <div className="timeline_txt">
                                        <h3>Starting the Business</h3>
                                        <p>Jacob Slomin opened a small general store in Massapequa, New York. Jacob sought to help people in the growing community while providing for his family’s future.</p>
                                        <p>Back then, buying kerosene for light and heat meant hauling it back to your house from the general store. When Jacob developed the idea to deliver it right to customers' doors, like ice or milk, customers responded by flooding Slomin's with orders - and our reputation for quality service was born.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-1959" role="tabpanel" aria-labelledby="pills-1959-tab">
                                <div className="timeline_wrap">
                                    <div className="timeline_img">
                                        <img src="../img/About-Us-Timeline-1959.jpg" alt="Timeline image" />
                                    </div>
                                    <div className="timeline_txt">
                                        <h3>Creating a Motto</h3>
                                        <p>Raymond, Jacob's son, began working in the store, he dedicated himself to providing customers with great service, developing the slogan, “Your comfort is our business.” </p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-1981" role="tabpanel" aria-labelledby="pills-1981-tab">
                                <div className="timeline_wrap">
                                    <div className="timeline_img">
                                        <img src="../img/About-Us-Timeline-1981.jpg" alt="Timeline image" />
                                    </div>
                                    <div className="timeline_txt">
                                        <h3>Providing Security</h3>
                                        <p>Slomin’s began offering home security services and has since grown into one of the nation’s biggest home security companies despite only operating regionally.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-1990" role="tabpanel" aria-labelledby="pills-1990-tab">
                                <div className="timeline_wrap">
                                    <div className="timeline_img">
                                        <img src="../img/About-Us-Timeline-2000s.jpg" alt="Timeline image" />
                                    </div>
                                    <div className="timeline_txt">
                                        <h3>Expanding into Cooling</h3>
                                        <p>We started offering homeowners and businesses cooling equipment, service, installation, and more.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-1997" role="tabpanel" aria-labelledby="pills-1997-tab">
                                <div className="timeline_wrap">
                                    <div className="timeline_img">
                                        <img src="../img/About-Us-Timeline-1997.jpg" alt="Timeline image" />
                                    </div>
                                    <div className="timeline_txt">
                                        <h3>Introducing Alarmo</h3>
                                        <p>We created the Alarmo action hero to emphasize our serious dedication to your security and safety.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-today" role="tabpanel" aria-labelledby="pills-today-tab">
                                <div className="timeline_wrap">
                                    <div className="timeline_img">
                                        <img src="../img/About-Us-Timeline-Today.jpg" alt="Timeline image" />
                                    </div>
                                    <div className="timeline_txt">
                                        <h3>Continuing Quality Service</h3>
                                        <p>Slomin's is one of the largest family-owned businesses in America thanks to our tradition of satisfying customers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="service_info_blk">
                    <div className="service_info_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-6 col-md-4" data-aos="fade-right">
                                <div className="service_info_box">
                                    <div className="service_info_icon">
                                        <img src="../img/house-security-icon.svg" alt="icon" />
                                    </div>
                                    <div className="service_info_txt">
                                        <h5>Free and Secure Installation</h5>
                                        <p>To guarantee top-notch quality at every job, we only send our own professionally trained technicians.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4" data-aos="fade-up">
                                <div className="service_info_box">
                                    <div className="service_info_icon">
                                        <img src="../img/availability-phone-icon.svg" alt="icon" />
                                    </div>
                                    <div className="service_info_txt">
                                        <h5>Professional 24/7 Monitoring</h5>
                                        <p>Our monitoring personnel is prepared to respond to any emergency around-the-clock.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4" data-aos="fade-left">
                                <div className="service_info_box">
                                    <div className="service_info_icon">
                                        <img src="../img/warrant-icon.svg" alt="icon" />
                                    </div>
                                    <div className="service_info_txt">
                                        <h5>One-year Product Warranty</h5>
                                        <p>All our products include a 1 year warranty to ensure optimal performance and your safety.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <SliderComponents/> 
                <SubscribeCompoents/>
            </main>


        </>
    );
};

export default AboutUsOverview;
