import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from '@app/App';
import store from '@store/store';
import './style.css';
import './developer.css';
import * as serviceWorker from './serviceWorker';
import "aos/dist/aos.css";
import TagManager from 'react-gtm-module';
import ReactGA from "react-ga4";
import Smartlook from 'smartlook-client';
import { constant } from '@app/utils/constant';
declare const window: any;

if (constant.GOOGLE_ANALYTICS) {
  const tagManagerArgs = { gtmId: constant.GOOGLE_TAGMANAGER_KEY }
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize(constant.GOOGLE_ANALYTICS_KEY);
}
constant.SMARTLOOK_TRACKING ? Smartlook.init(constant.SMARTLOOK_TRACKING_KEY, { region: 'eu' }) : "";

window.PF = {
  config: {
    mode: 'bs4'
  }
};


const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
serviceWorker.unregister();