import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { constant } from '@app/utils/constant';
import AOS from 'aos';
const AffordableMonitoring = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>            
            <main className="affordable_monitoring_pg">
                <section className="hero_blk landing_pg_hero">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <span className="note-sec">Limited Time Offer</span>
                                <h1>Paying too much for alarm monitoring? Let us lower it.</h1>                                    
                                <p>Professional monitoring rates starting at $24.95/mo.</p>
                                <p>Pay less for what you already have!</p>
                                <a href={`${constant.ALARAM_SITEURL}`} className="white_btn">View Local Offers</a>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="home_safety_blk" data-aos="fade-up">
                    <div className="home_safety_inr text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sec_title">
                                        <h2>Choose Slomin's to be your <span>Home Safety Provider</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row our-offer-items">
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/home-icon.svg" alt="icon"/>
                                        <h4>Total Home Security</h4>
                                        <p>Around-the-clock monitoring of your home. We'll notify authorities if your alarm is triggered.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/assistent.svg" alt="icon"/>
                                        <h4>Here when you need us</h4>
                                        <p>No matter what day or time, we're always available to ensure your needs are met. Just give us a call.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/availablity.svg" alt="icon"/>
                                        <h4>24/7/365 Rapid Response</h4>
                                        <p>Our central station is staffed 24/7/365 to respond promptly to your signal and notify authorities in the event of an emergency.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 more_btn_blk">
                                    <a className="red_btn" href={`${constant.ALARAM_SITEURL}`}>Shop Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>

                    <section className="security_features_blk">
                        <div className="security_features_inr container">
                            <div className="row justify-content-center header_row">
                                <div className="col-12" data-aos="fade-up">
                                    <div className="sec_title">
                                        <h2>Your Security. <span>Our Priority.</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6" data-aos="fade-right">
                                    <div className="security_features_content">
                                        <h3>We've got you covered - 24/7</h3>
                                        <p>Our professional home alarm monitoring station specializes in protection and security. In addition to home security monitoring, our team also monitors life safety devices to assist during other emergencies like fires, carbon monoxide leaks, frozen pipes, water damage and fall accidents.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" data-aos="fade-left">
                                    <div className="security_features_img">
                                        <img src="../img/Professional-Monitoring-Got-You-Covered-Image.jpg" alt="Professional Monitoring"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6" data-aos="fade-right">
                                    <div className="security_features_img">
                                        <img src="../img/Professional-Monitoring-Dedicated-Staff-Image.jpg" alt="Dedicated Staff"/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" data-aos="fade-left">
                                    <div className="security_features_content">
                                        <h3>A fully Dedicated Staff</h3>
                                        <p>Slomin’s monitors everything directly from our corporate headquarters. We do not hire subcontractors or 3rd party dealers to deploy our home security systems. All Slomin’s specialists are licensed, background-checked, and drug-tested to ensure the best team is keeping a careful eye on your home.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6" data-aos="fade-right">
                                    <div className="security_features_content">
                                        <h3>Direct Connection. Confirmed Response.</h3>
                                        <p>Only a connection to central station monitoring will provide significant assistance if you are not home. When your alarm is triggered, we respond immediately by notifying you and the police.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" data-aos="fade-left">
                                    <div className="security_features_img">
                                        <img src="../img/Senior-Citizens-Life-Safety-Professinal-Monitoring-Blog-Image.jpg" alt="Keypad being pushed"/>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 more_btn_blk">
                                    <a className="red_btn" href={`${constant.ALARAM_SITEURL}`}>Shop Now</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="slomins_promise_blk">
                        <div className="slomins_promise_inr container">
                            <div className="row justify-content-center header_row">
                                <div className="col-12" data-aos="fade-up">
                                    <div className="sec_title">
                                        <h2>The Slomin's <span>Promise</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-6" data-aos="fade-right">
                                    <div className="slomins_promise_img">
                                        <img src="../img/Screen-Shot-2022-03-03-at-1.34.03-PM.png" alt="Professional Monitoring"/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" data-aos="fade-left">
                                    <div className="slomins_promise_content">
                                        <h3>Family-owned and operated for nearly 100 years, Slomin’s has supplied customers quality services for generations prior, and generations to come.</h3>
                                        <ul>
                                            <li>Services you can trust.</li>                                            <li>Support you can reach anytime.</li>
                                            <li>Peace of mind you can depend on.</li>
                                        </ul>
                                        <p className='red_txt'>Proven since 1923.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="faq_blk" data-aos="fade-up">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sec_title">
                                        <h2>Frequently Asked<span> Questions</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Who do I call if I need assistance with my alarm system?</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Please call our office at 1-800-ALARM-ME  for all alarm system-related assistance, inquiries, or if you're concerned with a technician's status. We are always happy to help you.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">How long does an installation take?</button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Our basic alarm system installations typically take 3-4 hours. It's also important to note additional factors such as the size of your home, equipment type, and purchase specifics will determine exactly how long the install will take. Most of our alarm system installations are complete within 1 day.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Can you takeover my existing alarm?</button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                            <p>Our home security specialists will fully examine you existing alarm system during your free at-home consultation. In most cases, we can have your current system adapt to ours.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </main> 
        </>
    );
};

export default AffordableMonitoring;
