import React from "react";
import SliderComponents from "../../components/common/SliderComponents";
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { constant } from '@app/utils/constant';
const CoolingReplacements = () => {
    return (
        <>
    <main>
        <section className="hero_blk replacement_hero">
            <div className="hero_inr container-xxl">
                <div className="hero_content">
                    <h1>Eliminate expensive bills<br /> caused by an old air<br /> conditioner</h1>
                    <p>Slomin’s will help you choose the best new air conditioner<br /> for your budget and lower energy costs.</p>
                    <a className="red_btn" href={`${constant.OILSITE_BASEPATH}get-a-quote/?aoi=04`}>Schedule free consultation</a>
                </div>
            </div>
        </section>
        <section className="installation_reason_blk reason_blk_white">
            <div className="installation_reason_inr container-xxl">
                <div className="row">
                    <div className="col-12 installation_reason_title">
                        <h2><span className="red_txt">5 signs it's time</span> for a new air<br /> conditioner</h2>
                        <p>Replacing your HVAC system is a big decision. It’s one of the largest expenses any homeowner faces. You plan to extend the life of your old air conditioner with repairs to delay replacement. Not sure if your air conditioner really needs to be replaced? Consider the following questions to decide when to invest in a replacement air conditioner.</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-xxl-4">
                        <div className="inst_reason_box">
                            <div className="icon">1</div>
                            <p>How old is your air conditioner? Air conditioners usually last for around 15 years. Once your system reaches about 10 years old, it’s probably time to look into a replacement model.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <div className="inst_reason_box">
                            <div className="icon">2</div>
                            <p>Does the system use R22 Freon? R22 Freon is being phased out by the federal government and only recycled R22 refrigerant will be available to Central Air technicians to service these systems. If your air conditioner is older than 10 years, it probably uses R22 Freon and should be replaced.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <div className="inst_reason_box">
                            <div className="icon">3</div>
                            <p>How often have you repaired your air conditioner? You can expect routine repairs now and then but an air conditioner that requires multiple repairs over a couple of years is probably ready for a major system failure. Don’t waste money on an air conditioner that’s at the end of its life. The next repair bill will be better spent toward a new air conditioner.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xxl-6">
                        <div className="inst_reason_box">
                            <div className="icon">4</div>
                            <p>What are the expected costs to repair the system? If your air conditioner needs repairs now, consider the cost. If the repair bill would be around half of the cost of a new system, spend your money upgrading to an energy efficient model instead of repairs.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xxl-6">
                        <div className="inst_reason_box">
                            <div className="icon">5</div>
                            <p>Do you notice rising cooling costs? Air conditioners today are much more efficient than they were just 10 years ago. In fact, replacing a 10-year-old model with a new energy efficient air conditioner can save you 20-40% on your cooling costs, according to the Department of Energy. With an older unit, energy costs are only going to get higher as the system ages.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="save_money_blk">
            <div className="save_money_inr container-xxl">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="save_money_content">
                            <h2>Avoid discomfort and<br /> <span className="red_txt">save money</span></h2>
                            <p>As your air conditioner ages, it will become less reliable, more expensive to operate, and eventually fail<br /> unexpectedly. Contact Slomin’s Air Conditioning today to request a free estimate on residential HVAC<br /> system replacement to enjoy years of worry-free and reliable air conditioning.</p>
                            <img src="img/Cooling-Installation-Module-1.jpg" alt="Cooling Installation Module" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <SliderComponents />

        <SubscribeCompoents />
    </main>


        </>
    );
};

export default CoolingReplacements;
