import React, { useEffect } from 'react';
import AOS from 'aos';
import SeedProdPageForm  from "../../components/common/SeedProdPageForm";
const Spotify = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>            
            <main className="spotify_pg">
                <section className="hero_blk landing_pg_hero">
                    <div className="hero_inr container-xxl">
                        <div className="row align-items-center">
                            <div className="hero_content text-left col-lg-6 col-md-6 col-12 hero_box_outer">
                                <div className='hero_box'>
                                    <span className="note-sec">Limited Time Offer</span>
                                    <h1>Free Equipment, Free Installation and Free Doorbell Camera</h1>
                                    <a href="tel:18333071010" className="white_btn"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                                        <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28" />
                                    </svg> (833) 307-1010</a>
                                    <p>Save <strong>$1,445</strong> on a new home security system.</p>
                                </div>
                            </div>
                            <div className="hero_content_img text-left col-lg-6 col-md-6 col-12">
                                <div className='hr_img_blk'>
                                    <img src="../img/Affordable-Alarm-Security-Landing-Page-Image.png" alt="Products"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Service plans section*/}
                <section className="our_services_plans">
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-12" data-aos="fade-up">
                                <div className='sec_title'>
                                    <h2>Slomin's Home Security - <span>Special Offers and Savings</span></h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12" data-aos="fade-right">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/Why-Us-Not-Just-A-Doorbell-Module.jpg" alt="slomins door/window sensor gray box"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>24/7 Professional Home Monitoring Starting at <span>$35.95/mo</span></h4>
                                        <ul>
                                            <li>24/7 Central Station Monitoring</li>
                                            <li>Over 95 years of experience</li>
                                            <li>A fully dedicated and inhouse Slomin's staff</li>
                                            <li>Life safety monitoring available</li>
                                        </ul>
                                        <a className="white_btn" href="tel:18333071010">Call: (833) 307-1010</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12" data-aos="fade-up">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/LP-Module.jpg" alt="slomins door/window sensor gray box"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>Free Equipment and Free Expert Installation <span>(Worth $1,145)</span></h4>
                                        <p>The free security system includes:</p>
                                        <ul>
                                            <li>Control Panel</li>
                                            <li>Motion Sensor</li>
                                            <li>3 Door/Window Contacts</li>
                                            <li>Lawn Signs and Window Decals</li>
                                        </ul>
                                        <a className="white_btn" href="tel:18333071010">Call: (833) 307-1010</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12" data-aos="fade-left">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/Offer-Free-Doorbell-Module-1.jpg" alt="slomins door/window sensor gray box"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>Save even more with a Free Doorbell Camera <span>(Worth $290)</span></h4>
                                        <ul>
                                            <li>HD Video Recording</li>
                                            <li>Motion Activated</li>
                                            <li>Access video from your phone</li>
                                            <li>Mobile alerts</li>
                                            <li>2-way audio</li>
                                        </ul>
                                        <a className="white_btn" href="tel:18333071010">Call: (833) 307-1010</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="claim_form_blk" id='contact-form'>
                    <div className="claim_form_inr container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-4" data-aos="fade-right">
                                <div className='claim_form_info'>
                                    <h2>Ready to Claim your Free Home Security System?</h2>
                                    <p>Call <a href="tel:18333071010">833-307-1010</a> to schedule your free installation or fill out the form to have a specialist contact you.</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-8" data-aos="fade-left">
                                <SeedProdPageForm /> 
                            </div>
                        </div>
                    </div>
                </section>
            </main> 
        </>
    );
};

export default Spotify;
