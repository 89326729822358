import React, { useState } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents"; 
import SliderComponents from "../../components/common/SliderComponents";
import { Link } from 'react-router-dom'; 
import AOS from 'aos';
import { useEffect } from "react";
import PageTitleComponent from '../../components/PageTitleComponent';
const ProfessionalMonitoring = () => {   
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
          disable: "phone",
          duration: 700,
          easing: "ease-out-cubic",
        });
      }, []); 
    return (
        <>
        <PageTitleComponent title={"Professional Alarm Monitoring by Slomin's Own Central Station"}/>
            <main className="home_professional-monitoring_page"> 
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center hero_box_outer">
                            <div className='hero_box'>
                                <h1>Professional Home Monitoring Services</h1>
                                <p>Our central monitoring station stays vigilant 24/7 to keep your home safe.</p>
                                <a href="tel:18667566467" className="white_btn"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                                    <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28" />
                                </svg> 1-866-756-6467</a>
                            </div>
                        </div>
                    </div>
                </section> 
                <section className="slomins-professional">
                    <div className="slomins-professional-inr container">
                        <div className="row">
                            <div className="col-md-8" data-aos="fade-right">
                                <div className="row">
                                    <div className='col-12'>
                                    <div className="alert alert-primary" role="alert">Slomin's Professional Alarm Monitoring Teams Respond Quickly to any Circumstance.</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="slomins-professional-card">
                                            <h5>The Slomin's Shield</h5>
                                            <p>The power of The Slomin's Shield is our own professional alarm monitoring center. Built and maintained by Slomin's for only our customers, we guarantee that your home is being monitored 24 hours a day 7 days a week by Slomin's trusted employees.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="slomins-professional-card">
                                            <h5>Direct Connection, Confirmed Response</h5>
                                            <p>While any home security system sounds a siren, only a system directly connected to a professional central station alarm monitoring will provide significant assistance at a moment's notice.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="slomins-professional-card">
                                            <h5>False Alarm Compatibility</h5>
                                            <p>Accidentally set off your alarm? Our operators have a set of procedures to confirm there is an emergency and only send responders when necessary.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="slomins-professional-card">
                                            <h5>Authorities Alerted, Guaranteed</h5>
                                            <p>When our professional alarm monitoring team receives the signal when your alarm is triggered we act instantaneously to notify the proper authorities.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 request-for-quote" data-aos="fade-left">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Ready for reliable home monitoring from a trusted brand?</h4> 
                                        <Link className="red_btn" to={`/get-a-quote?aoi=11`}>Request a free quote</Link> 
                                        <hr />
                                        <p>Have questions? Call now.</p>
                                        <a href="tel:1866756-6467" className="white_btn"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                                            <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28" />
                                        </svg> 1-866-756-6467</a>
                                        <p className="note">Monday-Friday, 8:00 AM to 4:00 PM EST</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <section className="got_covered_blk">
                    <div className="got_covered_inr container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="got_covered_content">
                                    <h2>We've got you Covered</h2>
                                    <p>Our professional home alarm monitoring station specializes in protection and security. In addition to home security monitoring, our team also monitors life safety devices to assist during other emergencies like fires, carbon monoxide leaks, frozen pipes, water damage and fall accidents.</p>                                    
                                    <Link className="red_btn" to={`/get-a-quote`}>Learn More</Link>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="got_covered_img">
                                    <img src="../img/got-covered-img.png" alt="got covered" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <section className="dedicated-staff_blk">
                    <div className="dedicated-staff_inr container-xxl">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="dedicated-staff_img">
                                    <img src="../img/dedicated-staff-img.png" alt="got covered" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="dedicated-staff_content">
                                    <h2>A Fully Dedicated Staff</h2>
                                    <p>Slomin's monitors everything directly from our corporate headquarters. We do not hire subcontractors or 3rd party dealers to deploy our home security systems. All Slomin's specialists are licensed, background-checked, and drug-tested to ensure the best team is keeping a careful eye on your home.</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <SliderComponents/> 
                <section className="pricing-alm-blk">
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section_heading text-center">Choose the best <span>Monitoring Plan</span> for your home</h2>
                            </div>
                            <div className="pricing-column col-md-6" data-aos="fade-right">
                                <div className="card">
                                    <div className="card-body">
                                        <button className="btn btn-alm-top" type="button">Standard Plan</button>
                                        <h2>$33.95 <span>/month</span></h2>
                                        <hr />
                                        <ul className="list-content">
                                            <li>24/7 live professional monitoring</li>
                                            <li>Cellular communication</li>
                                            <li>32 user codes available</li>
                                            <li>View event history on keypad</li>
                                            <li>System can be expanded</li>
                                            <li>24 hour battery backup</li>
                                            <li>One year equipment warranty</li>
                                        </ul>
                                        <p className="note">Monitoring charges will be billed monthly and requires customer to be on auto pay. 5-Year Commitment Required; Charges billed month to month thereafter.</p> 
                                        <Link className="btn btn-alm-bottom" to={`/term-of-use`}>Terms and Conditions</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-column col-md-6" data-aos="fade-left">
                                <div className="card">
                                    <div className="card-body">
                                        <button className="btn btn-alm-top" type="button">Ultimate plan</button>
                                        <h2>$33.95 <span>/month</span></h2>
                                        <hr />
                                        <ul className="list-content">
                                            <li>Incl. Features of Standard Plan Plus </li>
                                            <li>Lifetime Service on Equipment </li>
                                            <li>Remote Access to system </li>
                                            <li>Mobile Alerts </li>
                                            <li>Control of Smart Home Devices </li>
                                            <li>Live Camera Viewing </li>
                                            <li>Motion Triggered Clips &amp; Recording</li>
                                        </ul>
                                        <p className="note">Monitoring charges will be billed monthly and requires customer to be on auto pay. 5-Year Commitment Required; Charges billed month to month thereafter.</p> 
                                        <Link className="btn btn-alm-bottom" to={`/term-of-use`}>Terms and Conditions</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <section className="home-slomins-support" data-aos="fade-up">
                    <div className="home-slomins-support-inr text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section_heading text-center">
                                        <h2>
                                            Slomin's Support is<span> Available 24/7</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row our-offer-items">
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-cutomer-service-icon.svg" alt="icon" />
                                        <h4>Customer Service</h4>
                                        <p>Connect with customer service for help. Visit the <Link to={`/contact-us`}>Support page</Link> for details.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-slomins-app-icon.svg" alt="icon" />
                                        <h4>mySlomin's App</h4>
                                        <p><Link to={`/home-security/home-security-cameras`}>Manage</Link> your system and account directly from your mobile device.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-trained-tech-icon.svg" alt="icon" />
                                        <h4>Trained Technicians</h4>
                                        <p>Slomin's technicians are available 24/7. <Link to={`/contact-us`}>Schedule</Link> a visit now.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <SubscribeCompoents /> 
            </main>
        </>
    );
};

export default ProfessionalMonitoring;
