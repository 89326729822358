import React, { useEffect } from 'react';
import AOS from 'aos';
import SeedProdPageForm  from "../../components/common/SeedProdPageForm";
import { constant } from '@app/utils/constant';
const IHeart = () => {
    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>            
            <main className="iheart_pg">
                <section className="hero_blk landing_pg_hero">
                    <div className="hero_inr container-xxl">
                        <div className="row align-items-center">
                            <div className="hero_content text-left col-lg-6 col-md-6 col-12 hero_box_outer">
                                <div className='hero_box'>
                                    <span className="note-sec">Limited Time Offer</span>
                                    <h1>Free Home Security System, Free Doorbell Camera, 24-Hour Monitoring</h1>
                                    <p>When Protection Matters</p>
                                    <a href="#Contact-Form" className="white_btn">Get Started Now</a>
                                </div>
                            </div>
                            <div className="hero_content_img text-left col-lg-6 col-md-6 col-12">
                                <div className='hr_img_blk'>
                                    <img src="../img/keypad-doorbell.png" alt="Products"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_safety_blk" data-aos="fade-up">
                    <div className="home_safety_inr text-center">
                        <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sec_title">
                                    <h2>Choose Slomin's to be your <span>Home Safety Provider</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row our-offer-items">
                            <div className="col-md-4 col-sm-6">
                                <div className="slm-item">
                                    <img src="../img/home-icon.svg" alt="icon"/>
                                    <h4>Security</h4>
                                    <p>24/7/365 monitoring of your home security system. We alert the authorities if your alarm is triggered.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="slm-item">
                                    <img src="../img/assistent.svg" alt="icon"/>
                                    <h4>Environment</h4>
                                    <p>Monitor the environment in your home. We offer options for fire / smoke / CO2 / temperature / flood.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="slm-item">
                                    <img src="../img/availablity.svg" alt="icon"/>
                                    <h4>Surveillance</h4>
                                    <p>Our video surveillance options offer the ability to remotely view and monitor your home from anywhere.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
                <section className="doorbell_cam_blk">
                    <div className="doorbell_cam_inr container">
                        <div className="row justify-content-center header_row">
                            <div className="col-12" data-aos="fade-up">
                                <div className="sec_title">
                                    <h2>Total Home <span>Security</span></h2>
                                    <p>Protect your home with the best in the business. Slomin's has been serving customers for nearly 100 years.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">                                
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="doorbell_cam_content">
                                    <h3>Free Doorbell Camera</h3>
                                    <p>Know when there is movement at your doorstep. Our motion activated doorbell camera shows you clear 1080 HD video and let's you communicate with two-way voice. </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="doorbell_cam_img">
                                    <img src="../img/doorbell-camera.png" alt="Doorbell Camera"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="our_services_plans" data-aos="fade-up">
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-12">
                                <div className='sec_title'>
                                    <h2>The Slomin's Shield® <span>Essential Components</span></h2>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/Product-Listing-Control-Panel-Keypad-Product-Image.jpg" alt="Control Panel Keypad"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>Control Panel</h4>
                                        <p>The control panel manages all parts of your home’s security and connects to our central monitoring station.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/Product-Listing-Door-Window-Contact-Product-Image.jpg" alt="Door Window Contact"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>Door & Window Contacts</h4>
                                        <p>Our contacts instantly notify you when someone enters through a window or door whether you’re at home or away.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/Product-Listing-Motion-Sensor-Product-Image.jpg" alt="Motion Sensor"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>Motion Sensor</h4>
                                        <p>Motion sensors add a layer of protection to your home’s vast areas, expansive rooms, hallways, and stairwells.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/Product-Listing-Slomin-Decal-Product-Image.jpg" alt="Slomin Decal"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>Lawn & Window Decals</h4>
                                        <p>Slomin’s lawn signs and window decals ward off any potential intruders.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 more_btn_blk">
                                <a className="red_btn" href='#Contact-Form'>Get A Quote</a>
                            </div>
                        </div>
                    </div>
                </section>                
                <section className="security_features_blk">
                    <div className="security_features_inr container">
                        <div className="row justify-content-center header_row">
                            <div className="col-12" data-aos="fade-up">
                                <div className="sec_title">
                                    <h2>Invest in the Best Home <span>Security System</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="security_features_content">
                                    <h3>Full Home Protection at Every Entry Point</h3>
                                    <p>Video surveillance, motions sensors, and door/window contacts secure you beyond the front door.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="security_features_img">
                                    <img src="../img/Free-Doorbell-Iheart-Module-1.jpg" alt="Surveilllance"/>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="security_features_img">
                                    <img src="../img/Remote-App-Hero-Image.jpg" alt="Audio Video"/>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="security_features_content">
                                    <h3>Mobile Compatibility</h3>
                                    <p>Connect and control your home devices from virtually anywhere with the MySlomins App.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="security_features_content">
                                    <h3>Professional Installation & Maintenance</h3>
                                    <p>Serviced by our in-house specialists, never subcontractors, so you can relax and let the pros do the work.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="security_features_img">
                                    <img src="../img/Why-Us-DIY-Install-Module.jpg" alt="Mobile Compatibility"/>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="security_features_img">
                                    <img src="../img/Heating-Service-Contract-24-7-Image-1.jpg" alt="Heating Service"/>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="security_features_content">
                                    <h3>24/7/365 Professional Home Monitoring Services</h3>
                                    <p>Direct connection, confirmed response. Our central station provides significant assistance at a moments notice in the event of an emergency.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 more_btn_blk">
                        <a className="red_btn" href='#Contact-Form'>Schedule Appointment</a>
                        </div>                            
                    </div>
                </section>
                <section className="claim_form_blk" id='Contact-Form'>
                    <div className="claim_form_inr container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-4" data-aos="fade-right">
                                <div className='claim_form_info'>
                                    <h2>Protection is on the way!</h2>
                                    <p>Get your FREE home security system & FREE doorbell camera today!</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-8" data-aos="fade-left">
                                <SeedProdPageForm />
                            </div>
                        </div>
                    </div>
                </section>   
            </main> 
        </>
    );
};

export default IHeart;
