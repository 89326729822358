import React from 'react';
function SocialMedia({ metaTags }) {
  return (
    <div>
      {/* Render meta tags */}
      {metaTags.map((tag, index) => (
        <meta key={index} {...tag} />
      ))}

    </div>
  );
}

export default SocialMedia;
