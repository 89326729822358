import React from "react";
import { Link } from 'react-router-dom';
import BlogJSONData from '../../components/blog/BlogData';
import { constant } from '@app/utils/constant';
const BlogYouMayAlsoLikeSection = (props: any) => {
    let BlogArr: any = [];
    let lem: any = 0;
    const ImageUrl = constant.SITEURL+'/../img/blog/'; 
    BlogJSONData.forEach((item, index) => {
        if (item.category == props.categoryName) {
            BlogArr[lem++] = {
                title: item.title,
                subtitle: item.subtitle,
                frontImageUrl: ImageUrl+item.frontImageUrl,
                category: item.category,
                description: item.description,
                slug: item.slug,
                date: item.date,
                content: item.content,
                blogBannerImage: ImageUrl+item.blogBannerImageUrl
            };
        }
    });
    const handleClick = () => {
        // Scroll the page to the top
        window.scrollTo(0, 0); 
      }
    return (
        <>
            {props.slug === 'blog-why-commercial-security-systems-are-essential-for-protecting-your-business' ? "" :
                <section className="related-blog">
                    <div className="related-blog-inr container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-12">
                                <div className="related-blog-heading">You may <span>also like</span></div>
                            </div> 
                            { 
                                BlogArr.length > 0 ? BlogArr.slice(0, 3).map((row, podidx) => (
                                    <div key={podidx} className="col-md-6 col-lg-4 col-12">
                                        {
                                            <div className="card">
                                                <div className="image-wrapper">
                                                    <Link to={`/` + row.slug}  onClick={handleClick}>
                                                        <img src={row.frontImageUrl} alt="slomins door/window sensor gray box" />
                                                    </Link>
                                                </div>
                                                <div className="card-content">
                                                    <div className="note">{row.category} | <span className="date">{row.date}</span></div>
                                                    <h4>{row.title}</h4>
                                                    <Link to={`/` + row.slug} className="button white_btn prd_learn_more"  onClick={handleClick}>
                                                        Learn More
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )) : ""

                            }

                        </div>
                    </div>
                </section>
            }
        </>
    );
};

export default BlogYouMayAlsoLikeSection;
