import React,{useEffect} from 'react'; 
import PageTitleComponent from '../../components/PageTitleComponent';
const PrivacyPolicy = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
      }, []);  
    return (
        <>
         <PageTitleComponent title={"Privacy Policy - Slomins"}/> 
           <main className="privacy-policy">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center">
                            <h1>Privacy Policy</h1>
                        </div>
                    </div>
                </section>
                <div className="terms_content_blk">
                    <div className="container">
                        <div className="terms_content">
                            <p>Slomin's substantially modified its Privacy Policy on October 2019. Please review the entire Policy carefully.</p>
                            <div className="section-privacy-blk">
                                <h5>OVERVIEW OF OUR PRIVACY POLICY</h5>
                                <p>Protecting your privacy is fundamental to the way our company provides you with products and services. Privacy regulations require companies to apprise customers and others of the gathering and use of personal information. However, our company consistently strives to ensure the confidentiality of your personal information. The Privacy Policy that follows details the process by which we may gather, utilize, and disclose your personal information and non-personal information.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Personal and Non-Personal Information</h5>
                                <p>In providing our products and services, we often receive sensitive information from and about our customers, including financial and business information, some of which constitutes personal information. Overall, <strong>personal information</strong> constitutes all information capable of being employed in some way to identify, contact someone, or determine someone's location. Names, physical addresses, mailing addresses, driver's license numbers, social security numbers, email addresses, phone numbers, bank account numbers, are considered types of personal information. Moreover, in some contexts, personal information may also include other information that can reasonably connect to a specific person, such as internet protocol (IP) addresses, unique device identification numbers, employment information, medical information and internet activity.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <p><strong>Non-personal information</strong> is any aggregated information or information that does not identify, or cannot be reasonably linked to, any individual. For example, data regarding the amount of times a particular link on a website is selected or regarding the number of visitors to a website are examples of non-personal information.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>How Our Company Collects Personal and Non-Personal Information</h5>
                                <p><strong>-</strong><strong>Overview</strong></p>
                                <p>We may collect information whenever customers use our websites, applications, products, or services. The information we gather can include email addresses, home addresses, phone numbers, Social Security Numbers, payment information such as credit or debit card information, dates of birth, in addition to other types of information.</p>
                                <p><strong>-</strong><strong>Information Collected from Websites and Applications</strong></p>
                                <p>Our websites and applications collect personal information, such as through the online transactions processed. That is, in processing a customer's payment for our services or products through our websites or applications, we may retain email and credit card information from the transaction. As another example, in preparing a price quote for a security system request by a customer, we may retain information provided by the customer in our online forms that we use in preparing the price quote. Most websites and applications, including ours, use a technology referred to as “cookies,” which involves information being stored on your computer or mobile device as minute bits of information upon visiting a website. Through the use of cookies, your browser can be identified, as can your device. Furthermore, cookies allow us to ascertain your browsing preferences and thereby tailor the delivery of our websites and applications to your liking.</p>
                                <p>Our websites and applications may collect your type of browser, Internet Protocol (IP) addresses, Internet Service Provider (ISP), operating system, and other pieces of information, and save this all automatically in what are known as log files. Through your use of our websites and applications, we may be able to gather information relating to data and event information from your device(s) through the use of cameras or other sensory monitors. Moreover, our websites and applications may employ technology known as web beacons to monitor your actions on our online platforms. In addition, if you use a mobile or other device to access or download our company's services, we may gather information regarding your location for dispatching our services or in order to provide such information to emergency services if need be. You can refuse these location-related services through your device prompts or settings. We will retain information pertaining to your device‘s type, operating system, and other information. The information we collect through your mobile or other device may pertain to the software contained therein. <strong>We do not label or connect the activity we record in this manner with any personal information you provide us through our applications.</strong> This recorded information may relate to the frequency with which you use our application, actions you take during the use of our application, and data relating to performance, among other things.</p>
                                <p>-Particular Information Collected by Your Use of Our Devices, Products, or Services</p>
                                <p>The following are some types of information that we may gather from you when you use certain of our devices, products, or services: pictures or images, audio-only, video-only, or audiovisual information that is recorded by monitoring devices; device information relating to motion detection and weather or climate data; and GPS information.</p>
                            </div>  
                            <div className="section-privacy-blk">
                                <h5>How Our Company Uses Personal and Non-Personal Information</h5>
                                <p>Overall, we use the personal and non-personal information we collect from you to better provide you with our services or products. Additionally, may use your personal and non-personal information to further our company's business purposes. This may involve our company using your personal or non-personal information to increase the efficiency of our company's services and learn from past transactions; to increase the efficiency and interface of our websites and applications; to enable you to create an online account on our company websites and applications; to ensure that you receive the services, products, or information you desired from us; to inform you of promotional events or happenings that may be of interest to you; to inform you of promotional offers, packages, or sales we are offering; to further develop and innovate our products and services; to combat fraudulent activities involving our services or products; and to survey customers regarding our services, products, websites, and applications, in addition to other uses.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Disclosure of Personal Information</h5>
                                <p>We may disclose personal information we collect from you: (1) to contractors, service providers, and other third parties we use to support our business; (2) to accomplish the reason or purpose for which you provide it; (3) for any other purpose disclosed by us when you provide the information; (4) to comply with any court order, law or legal process, including responding to any government or regulatory request; (5) to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections; (6) if we believe disclosure is necessary or appropriate to protect the rights, property or safety of us, our customers or others; or (7) with your consent. <strong>We do not sell or otherwise share personal information with third parties for direct marketing purposes.</strong></p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Data Security</h5>
                                <p>While our company does its best to ensure that the personal information you provide us, in any form, is guarded against unauthorized disclosure or access by unauthorized third parties, information shared over the internet can always potentially be compromised by malicious actors or software. We have protocols with regard to both digital and non-digital information in order to reduce the likelihood that your personal information will be compromised or suffer an unauthorized disclosure. Ultimately, any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on our websites and applications.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Third-Party Providers</h5>
                                <p>Some content or applications, including advertisements, on our websites and applications are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our websites or applications. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
                                <p>We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Non-Personal Information</h5>
                                <p>Nothing in this Privacy Policy circumscribes or constrains our collection, use or disclosure of any aggregated information or information that does not identify, or cannot be reasonably linked to, any individual. This applies to non-personal information we gather through any of our products, services, websites, applications, or any other source related to our business.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Consent</h5>
                                <p>By providing us with personal information you consent to our sharing, collecting, and disclosing of the provided information. Your express consent comes in the form of your direct communication of approval. An example of express consent could stem from a written communication from you to our company authorizing our sharing, collection, and disclosure of your provided information. Your implied consent comes in the form of your non-verbal conduct that indicates approval. An example of implied consent could stem from your decision to enter your personal information into one of our online forms in order to receive our services. Your lack of notice with regard to consent will be construed as an authorization for our company to share, collect, and disclose your provided information. You may revoke your consent, but this may cause difficulties for our company in providing you with our business. See our contact information at the end of this Privacy Policy to receive more information on this matter.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Minors and Information Collection</h5>
                                <p>While our company's products and services are intended for a general audience, our products and services are not intended for children. Our websites and applications do not knowingly collect personal information online from children below 13 years of age. If we have received personal information from a child below 13 years of age, we ask that a parent or guardian contact us and notify us of the matter so that we can delete the information.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Privacy Rights Under California Law</h5>
                                <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information.</p>
                                <ol>
                                    <li>CCPA Privacy Notice</li>
                                </ol>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>PRIVACY Policy-CALIFORNIA</h5>
                                <p>This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the information contained in the Privacy Policy of our company, (hereinafter, “we,” “us,” or “our”) and applies solely to customers, visitors, users, employees, and others who reside in the State of California (hereinafter, “consumer” or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (“CCPA”) and other California privacy laws. Any terms defined in the CCPA have the same meaning when used in this notice.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Information We Collect</h5>
                                <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”). In particular, we have collected the following categories of personal information from consumers within the last twelve (12) months:</p>
                                <p><strong>Category</strong></p>
                                <p><strong>Examples</strong></p>
                                <p><strong>Collected</strong></p>
                                <p>A. Identifiers.</p>
                                <p>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.</p>
                                <p> YES</p>
                                <p>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</p>
                                <p>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</p>
                                <p> YES</p>
                                <p>C. Protected classification characteristics under California or federal law.</p>
                                <p>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</p>
                                <p> YES</p>
                                <p>D. Commercial information.</p>
                                <p>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</p>
                                <p> YES</p>
                                <p>E. Biometric information.</p>
                                <p>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</p>
                                <p> YES</p>
                                <p>F. Internet or other similar network activity.</p>
                                <p>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</p>
                                <p> YES</p>
                                <p>G. Geolocation data.</p>
                                <p>Physical location or movements.</p>
                                <p> YES</p>
                                <p>H. Sensory data.</p>
                                <p>Audio, electronic, visual, thermal, olfactory, or similar information.</p>
                                <p> YES</p>
                                <p>I. Professional or employment-related information.</p>
                                <p>Current or past job history or performance evaluations.</p>
                                <p> YES</p>
                                <p>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</p>
                                <p>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</p>
                                <p> YES</p>
                                <p>K. Inferences drawn from other personal information.</p>
                                <p>Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
                                <p> YES</p>
                                <p>Personal information does not include:</p>
                                <ul> 
                                    <li> Publicly available information from government records.</li>
                                    <li>De-identified or aggregated consumer information.</li>
                                    <li>Information excluded from the CCPA's scope, like:</li>
                                    <li> Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
                                    <li>Personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</li>
                                </ul>
                                <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
                                <ul>
                                    <li>Directly from our customers or their agents. For example, from documents that our customers provide to us related to the services for which they engage us.</li>
                                    <li>Indirectly from our customers or their agents. For example, through information we collect from our customers in the course of providing services to them.</li>
                                    <li>Directly and indirectly from activity on our websites and applications. For example, from submissions through our websites' portals or website usage details collected automatically.</li>
                                    <li>From third-parties that interact with us in connection with the services we perform or products we provide. For example, from outside companies we may use to assist us in providing you with our services or products.</li>
                                </ul>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Use of Personal Information</h5>
                                <p>We may use or disclose the personal information we collect for one or more of the following business purposes:</p>
                                <ul>
                                    <li>To fulfill or meet the reason for which the information is provided. For example, if you provide us with personal information so that we can decide whether to recommend services or products such as customizable alerts or security cameras, we will retain and use this information to meet your request.</li>
                                    <li> To provide you with information, services, or products that you request from us.</li>
                                    <li> To provide you with email alerts, event registrations and other notices concerning our services, products, events, or news, that may be of interest to you.</li>
                                    <li> To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections.</li>
                                    <li> To improve our websites and applications and present their contents to you.</li>
                                    <li> For testing, research, analysis and development of our services or products.</li>
                                    <li> As necessary or appropriate to protect the rights, property or safety of us, our customers or others.</li>
                                    <li> To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                                    <li> As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
                                    <li> To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</li>
                                </ul>
                                <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Sharing Personal Information</h5>
                                <p>We may disclose your personal information to a third party for a business purpose, <strong>but we do not disclose or sell such information for direct marketing purposes</strong>. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</p>
                                <p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
                                <p>Category A: Identifiers.</p>
                                <p>Category B: California Customer Records personal information categories.</p>
                                <p>Category C: Protected classification characteristics under California or federal law.</p>
                                <p>Category D: Commercial information.</p>
                                <p>Category E: Biometric information.</p>
                                <p>Category F: Internet or other similar network activity.</p>
                                <p>Category G: Geolocation data.</p>
                                <p>Category H: Sensory data.</p>
                                <p>Category I: Professional or employment-related information.</p>
                                <p>Category K: Inferences drawn from other personal information.</p>
                                <p>We disclose your personal information for a business purpose to the following categories of third parties:</p>
                                <ul>
                                    <li>Our affiliates.</li>
                                    <li>Service providers.</li>
                                    <li>Third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you.</li>
                                </ul>
                                <p><strong>In the preceding twelve (12) months, we have not sold any personal information.</strong></p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Your Rights and Choices</h5>
                                <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
                                <p><em>Access to Specific Information and Data Portability Rights</em></p>
                                <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
                                <ul>
                                    <li>The categories of personal information we collected about you.</li>
                                    <li>The categories of sources for the personal information we collected about you.</li>
                                    <li>Our business or commercial purpose for collecting that personal information.</li>
                                    <li>The categories of third parties with whom we share that personal information.</li>
                                    <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
                                    <li>If we disclosed your personal information for a business purpose, a list indicating disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
                                </ul>
                                <p><em>Deletion Request Rights</em></p>
                                <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
                                <p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
                                <ol>
                                    <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
                                    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                                    <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                                    <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
                                    <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546<em>seq.</em>).</li>
                                    <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</li>
                                    <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
                                    <li>Comply with a legal obligation.</li>
                                    <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
                                </ol>
                                <p><em>Exercising Access, Data Portability, and Deletion Rights</em></p>
                                <p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by using the contact information contained at the end of this Privacy Policy.</p>
                                <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</p>
                                <p>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
                                <ul data-rte-list="default">
                                    <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
                                    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                                </ul>
                                <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
                                <p><em>Response Timing and Format</em></p>
                                <p>We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
                                <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
                                <h5>Non-Discrimination</h5>
                                <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
                                <ul>
                                    <li>Deny you our services or products.</li>
                                    <li>Charge you different prices or rates for our services or products, including through granting discounts or other benefits, or imposing penalties.</li>
                                    <li>Provide you a different level or quality of our services or products.</li>
                                    <li>Suggest that you may receive a different price or rate for our services or products or a different level or quality of our services or products.</li>
                                </ul>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Privacy Policy Alterations</h5>
                                <p>We will post any material changes we make to any part of this Privacy Policy (including the CCPA Privacy Notice portion) on our websites and applications. If we make material changes to how we treat personal information we collect from you, we will notify you by email or through notices on our websites' and applications' homepages. The date this Privacy Policy was last revised is identified above. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting this Privacy Policy on our websites and applications to check for any changes.</p>
                            </div>
                            <div className="section-privacy-blk">
                                <h5>Contact Information</h5>
                                <p>If you have any requests, questions, or concerns regarding this Privacy Policy (including the CCPA Privacy Notice portion), please do not hesitate to contact us at:</p>
                            </div>
                            <div className="section-privacy-blk">
                                <p><strong>Website:</strong> www.slomins.com</p>
                                <p><strong>Email:</strong> KHeck@Slomins.com</p>
                                <p><strong>Phone:</strong>(516) 932-8739</p>
                            </div>
                        </div>
                    </div>
                </div>
           </main>
        </>
    );
};

export default PrivacyPolicy;
