export default [  
      {
        title: 'Lawn Signs and Window Decals',
        image: 'Product-Listing-Slomin-Decal-Product-Image.jpg',
        description: 'Let everyone know your home is equipped with a professional alarm system.', 
        category: 'essentials', 
        slug: 'lawn-signs', 
        deatilsLargeImage   : 'Product-Listing-Slomin-Decal-Product-Image.jpg', 
        key_features:[
          {
            title:"Thin Design", 
            description:"Designed to be installed on doors, windows and many other objects that open and close.",  
          },
          {  
            title:"Wireless communication", 
            description:"The sensor transmits signals to the control panel when a monitored door/window is opened.", 
          },
          { 
            title:"Entry Detection", 
            description:"Programmed to notify you of someone entering/leaving even when your system is disarmed." 
          }
        ] 
      },
       {
        title: 'Door and Window Contacts',
        image: 'Product-Listing-Door-Window-Contact-Product-Image.jpg',
        description: "Slomin's door and window contacts notify you when an intruder breaks in through a protected door or window.", 
        category: 'essentials',
        slug: 'door-contact-3', 

        deatilsLargeImage   : 'Product-Listing-Door-Window-Contact-Product-Image.jpg', 
        deatilsTitle        : "Door and window contacts", 
        deatilsDescription  : "Secure your windows and doors against intruders.", 
        key_features:[
          {
            title:"Thin Design", 
            description:"Designed to be installed on doors, windows and many other objects that open and close.",  
          },
          {  
            title:"Wireless Communication", 
            description:"The sensor transmits signals to the control panel when a monitored door/window is opened.", 
          },
          { 
            title:"Entry Detection", 
            description:"Programmed to notify you of someone entering/leaving even when your system is disarmed." 
          }
        ] 
      },      
       {
        title: 'Motion Detector',
        image: 'Product-Listing-Motion-Sensor-Product-Image.jpg',
        description: "Our motion sensors add a layer of protection to your home's interior.", 
        category: 'essentials',
        slug: 'motion-detector-3', 
        deatilsLargeImage   : 'Product-Detail-Motion-Sensor-Product-Image.jpg', 
        deatilsTitle        : "Motion detector", 
        deatilsDescription  : "Our motion sensors add a layer of protection to your home’s interior.",
        disclaimerText      : "",            
        key_features:[
          {
            title:"Customizable Settings", 
            description:"Ensure your security system takes your pets into account.",  
          },
          {  
            title:"Automatic Alerts", 
            description:"Receive updates when motion is detected in your home.", 
          },
          { 
            title:"Wireless Communication", 
            description:"Communicates with the control panel via radio frequency." 
          },
          { 
            title:"Wide Coverage Area", 
            description:"One motion sensor can cover up to 30 feet." 
          }
        ] 
      },
       {
        title: 'Indoor Camera',
        image: 'Product-Listing-Indoor-Camera-Product-Image.jpg',
        description: "Slomin's HD livestream cameras accurately showcase your home’s activity in real-time. Ideal for checking in when you are away.", 
        category: 'essentials',
        slug: 'indoor-camera',  
        deatilsLargeImage   : 'Product-Listing-Indoor-Camera-Product-Image.jpg', 
        deatilsSamllTitle        : "Watch over your home and your loved ones with clear HD video.",
        disclaimerText      : "This product requires the ability to use Slomin's mobile apps in order to view the cameras which is available in the Ultimate Monitoring Plan at $49.95 per month.* Ambient lighting required for low-light vision.",           
        key_features:[
          {
            title:"Two-way Voice Communication", 
            description:"Connect with your family directly through your camera.",  
          },
          {  
            title:"Motion Activated", 
            description:"Motion triggered video recording.", 
          },
          { 
            title:"Automatic Alerts", 
            description:"Receive updates on your phone when the camera detects motion." 
          },
          { 
            title:"Low-light Vision", 
            description:"Get a view of what's happening day or night.*" 
          },
          { 
            title:"Wide angle lens", 
            description:"See what's going on in the entire room." 
          },
          { 
            title:"Footage on-demand", 
            description:"Access 15 second clips for up to 5 days with onboard storage and 15 days with cloud storage." 
          }
        ] 
      },
       {
        title: 'Control Panel and Keypad',
        image: 'Product-Listing-Control-Panel-Keypad-Product-Image.jpg',
        description: "The user-friendly interface lets you manage your entire home security system and directly connects to our central monitoring station.", 
        category: 'essentials',
        slug: 'panel-keypad',   
        deatilsLargeImage   : 'Product-Listing-Control-Panel-Keypad-Product-Image.jpg', 
        deatilsSamllTitle   : "Operate your entire system from the wireless, user friendly keypad.",   
        key_features:[
          {
            title:"Simple Interface", 
            description:"Control your system with a few intuitive touches.",  
          },
          {  
            title:"Wireless Mounting", 
            description:"Place the control panel anywhere for easy access.", 
          },
          { 
            title:"Backlit Color Display", 
            description:"See information clearly displayed on your panel day or night." 
          },
          { 
            title:"Built-in Z-Wave Technology", 
            description:"Z-Wave radio enables wireless communication with all automation devices in your home." 
          },
          { 
            title:"Weather Information Display", 
            description:"Scroll through the date, time, and real-time weather forecasts right on your panel." 
          },
          { 
            title:"Easy Emergency Access", 
            description:"One-touch button allows the quickest help possible in an emergency." 
          }
        ] 
      },
       {
        title: 'Keychain Remote',
        image: 'Product-Listing-Key-Fob-Product-Image.jpg',
        description: "Take a step out of your security routine. Slomin's keychain remotes arm or disarm your system with the touch of a button.", 
        category: 'essentials',
        slug: 'key-fob', 
        deatilsLargeImage   : 'Product-Listing-Key-Fob-Product-Image.jpg', 
        deatilsSamllTitle   : "Quick system control at your fingertips.",    
        key_features:[
          {
            title:"Panic button*", 
            description:"Press the emergency button to immediately alert central monitoring if you're in danger.",  
          },
          {  
            title:"Bypass codes", 
            description:"Arm or disarm your alarm system with one-touch, no security code necessary.", 
          },
          { 
            title:"Compact design", 
            description:"Add your key fob onto your keychain or in a pocket with ease." 
          }
        ] 
      },
       {
        title: 'Smoke Detector',
        image: 'Product-Listing-Smoke-Detector-Product-Image.jpg',
        description: "The detector sounds a loud alarm when excessive smoke or heat is detected. Protection is provided within a 35‐foot radius of the unit.", 
        category: 'essentials',
        slug: 'smoke-detector', 
        deatilsLargeImage   : 'Product-Listing-Smoke-Detector-Product-Image.jpg', 
        deatilsSamllTitle   : "When dangerous levels of smoke are detected, Slomin’s smoke detectors alert the monitoring team for 24/7 added protection.",      
        key_features:[
          {
            title:"Battery powered and wireless", 
            description:"Installed wherever you need them.",  
          },
          {  
            title:"Professionally monitored", 
            description:"The Slomin's monitoring team responds to smoke alerts and dispatches 1st responders, even when you are away.", 
          },
          { 
            title:"Self-contained sounder", 
            description:"The alarm sounds a loud local alarm to alert anyone in your home." 
          }
        ] 
      },

      /******************************************************** Cameras start********************************************************/
      {
        title: 'Indoor Camera',
        image: 'Product-Listing-Indoor-Camera-Product-Image.jpg',
        description: "Slomin's HD livestream cameras accurately showcase your home’s activity in real-time. Ideal for checking in when you are away.", 
        category: 'cameras',
        slug: 'indoor-camera',
        deatilsLargeImage   : 'Product-Listing-Indoor-Camera-Product-Image.jpg', 
        deatilsSamllTitle   : "Watch over your home and your loved ones with clear HD video.",
        disclaimerText      : "This product requires the ability to use Slomin's mobile apps in order to view the cameras which is available in the Ultimate Monitoring Plan at $49.95 per month.* Ambient lighting required for low-light vision.",               
        key_features:[
          {
            title:"Two-way voice communication", 
            description:"Connect with your family directly through your camera.",  
          },
          {  
            title:"Motion activated", 
            description:"Motion triggered video recording.", 
          },
          { 
            title:"Automatic alerts", 
            description:"Receive updates on your phone when the camera detects motion." 
          },
          { 
            title:"Low-light vision", 
            description:"Get a view of what's happening day or night.*" 
          },
          { 
            title:"Wide angle lens", 
            description:"See what's going on in the entire room." 
          },
          { 
            title:"Footage on-demand", 
            description:"Access 15 second clips for up to 5 days with onboard storage and 15 days with cloud storage." 
          }
        ] 
      },
      {
        title: 'Indoor Pan and Tilt Camera',
        image: 'Product-Listing-Pan-Tilt-Camera-Product-Image.jpg',
        description: "Adjust your camera to the exact angle you want to view.", 
        category: 'cameras',
        slug: 'pan-tilt-camera', 
        deatilsLargeImage   : 'Product-Listing-Pan-Tilt-Camera-Product-Image.jpg', 
        deatilsSamllTitle   : "See every corner of your home with 360° viewing capability.",
        disclaimerText      : "This product requires the ability to use Slomin's mobile apps in order to view the cameras which is available in the Ultimate Monitoring Plan at $49.95 per month.",              
        key_features:[
          {
            title:"Quality images", 
            description:"HD resolution so you can watch crisp and clear video.",  
          },
          {  
            title:"Motion activated", 
            description:"Cameras are activated once motion is detected.", 
          },
          { 
            title:"Two-way audio", 
            description:"Speak with any one, in any room where there is a camera." 
          },
          { 
            title:"Remote viewing", 
            description:"Download the MySlomins app to view live video feeds and saved clips anywhere, anytime." 
          }
        ] 
      },
      {
        title: 'Outdoor Dome Camera',
        image: 'Product-Listing-Camera-Product-Image.jpg',
        description: "Monitor your property with an outdoor dome camera. Each camera comes with 24 hour live video, 128gb of internal memory, an adjustable radius and motion sensors.", 
        category: 'cameras',
        slug: 'outdoor-dome-camera',
        deatilsLargeImage   : 'Product-Listing-Pan-Tilt-Camera-Product-Image.jpg', 
        deatilsSamllTitle   : "Keep an eye on your home exterior from your phone.",         
        key_features:[
          {
            title:"Weather proof", 
            description:"Designed to be mounted outdoors, regardless of climate.",  
          },
          {  
            title:"See in the dark", 
            description:"Supports night-vision up to 30 meters.", 
          },
          { 
            title:"On-demand video", 
            description:"Records and stores video to the internal memory card." 
          }
        ] 
      },
      {
        title: 'Doorbell Camera',
        image: 'Product-Listing-Doorbell-Camera-Product-Image.jpg',
        description: "Know when there is movement at your doorstep. Our motion activated doorbell camera shows you clear 1080 HD video and let's you communicate with two-way voice.", 
        category: 'cameras',
        slug: 'doorbell-camera',  
        deatilsLargeImage   : 'Product-Listing-Doorbell-Camera-Product-Image.jpg', 
        deatilsSamllTitle   : "Track when people or packages arrive at your door.",  
        key_features:[
          {
            title:"Motion Sensor", 
            description:"Get alerts when a visitor is in front of the door, even if they don't press the doorbell.",  
          },
          {  
            title:"Night vision", 
            description:"Feel safer knowing visitors can be seen even at night.", 
          },
          { 
            title:"Two-way audio", 
            description:"Have a conversation with visitors from a mobile device." 
          },
          { 
            title:"Mobile control", 
            description:"Unlock the door right from the app when you receive a doorbell notification." 
          },
          { 
            title:"On-demand video clips", 
            description:"Check live view video feed from the app, or record clips automatically when the doorbell is pressed or motion is detected." 
          }
        ] 
      },
      /******************************************************** Cameras end********************************************************/

      /******************************************************** Sensors Start********************************************************/

      {
        title: 'Door and Window Contacts',
        image: 'Product-Listing-Door-Window-Contact-Product-Image.jpg',
        description: "Slomin’s door and window contacts notify you when an intruder breaks in through a protected door or window.", 
        category: 'sensors',
        slug: 'door-contact-3', 
        deatilsLargeImage   : 'Product-Listing-Door-Window-Contact-Product-Image.jpg', 
        deatilsSamllTitle   : "Secure your windows and doors against intruders.",    
        key_features:[
          {
            title:"Thin Design", 
            description:"Designed to be installed on doors, windows and many other objects that open and close.",  
          },
          {  
            title:"Wireless communication", 
            description:"The sensor transmits signals to the control panel when a monitored door/window is opened.", 
          },
          { 
            title:"Entry detection", 
            description:"Programmed to notify you of someone entering/leaving even when your system is disarmed." 
          }
        ] 
      },
      {
        title: 'Motion Detector',
        image: 'Product-Listing-Motion-Sensor-Product-Image.jpg',
        description: "Our motion sensors add a layer of protection to your home's interior.", 
        category: 'sensors',
        slug: 'motion-detector-3',   
        deatilsLargeImage   : 'Product-Listing-Motion-Sensor-Product-Image.jpg', 
        deatilsSamllTitle   : "Detect unexpected movement or activate your home automation settings.", 
        disclaimerText      : "",          
        key_features:[
          {
            title:"Customizable settings", 
            description:"Ensure your security system takes your pets into account.",  
          },
          {  
            title:"Automatic alerts", 
            description:"Receive updates when motion is detected in your home.", 
          },
          { 
            title:"Wireless communication", 
            description:"Communicates with the control panel via radio frequency." 
          },
          { 
            title:"Wide coverage area", 
            description:"One motion sensor can cover up to 30 feet." 
          }
        ] 
      },
      {
        title: 'Personal Emergency Pendant',
        image: 'Product-Listing-Panic-Button-Product-Image.jpg',
        description: "Send an instant emergency signal to our monitoring station. The panic remote is small enough to wear and activated with the press of a button.", 
        category: 'sensors',
        slug: 'personal-emergency-transmitter',   
        deatilsLargeImage   : 'Product-Listing-Panic-Button-Product-Image.jpg', 
        deatilsSamllTitle   : "Detect unexpected movement or activate your home automation settings.",
        disclaimerText      : "Pendant must be within range of the control panel.",          
        key_features:[
          {
            title:"Compact design", 
            description:"The panic button can be worn or placed anywhere for easy connection to Slomin's.",  
          },
          {  
            title:"Quick connectivity", 
            description:"Easy access to help during any emergency with a button push.", 
          },
          { 
            title:"Wireless panic button", 
            description:"Transmit an emergency signal from anywhere in your home." 
          },
          { 
            title:"Always on", 
            description:"The signal is transmitted whether your security system is armed or disarmed." 
          }
        ] 
      },
      {
        title: 'Garage Door Tilt Sensor',
        image: 'Product-Listing-Garage-Tilt-Product-Image.jpg',
        description: "The tilt sensor detects the open or closed status of any garage door.", 
        category: 'sensors',
        slug: 'tilt-sensor-overhead-door',  
        deatilsLargeImage   : 'Product-Listing-Garage-Tilt-Product-Image.jpg', 
        deatilsSamllTitle   : "Monitor and control your garage door from your security system.",    
        key_features:[
          {
            title:"Thin Design", 
            description:"Designed to be installed on doors, windows and many other objects that open and close.",  
          },
          {  
            title:"Wireless communication", 
            description:"The sensor transmits signals to the control panel when a monitored door/window is opened.", 
          },
          { 
            title:"Entry detection", 
            description:"Programmed to notify you of someone entering/leaving even when your system is disarmed." 
          }
        ] 
      },
      {
        title: 'System Takeover Module',
        image: 'Product-Listing-Takeover-Module-Product-Image.jpg',
        description: "We install a takeover module to convert your wired system to a Slomin's wireless system.", 
        category: 'sensors',
        slug: 'existing-system-takeover-module',   
        deatilsLargeImage   : 'Product-Listing-Takeover-Module-Product-Image.jpg', 
        deatilsSamllTitle   : "Cut the chord. Convert your existing security system to Slomin’s.",    
        key_features:[
          {
            title:"Wireless conversion", 
            description:"Convert hardwire zones to wireless frequency.",  
          },
          {  
            title:"Multiple zone coverage", 
            description:"Converts 8 zones per module.", 
          } 
        ] 
      },
      {
        title: 'Audio Glass Break Detector',
        image: 'Product-Listing-Glass-Break-Sensor-Product-Image.jpg',
        description: "Protect any glass in your home. Tamper protected so if intruders try to disable the device, the security system is notified.", 
        category: 'sensors',
        slug: 'audio-glass-break-detector',   
        deatilsLargeImage   : 'Product-Listing-Glass-Break-Sensor-Product-Image.jpg', 
        deatilsSamllTitle   : "Protection at the speed of sound. Detect glass break and get help simultaneously.",   
        key_features:[
          {
            title:"Wide range", 
            description:"Provides 15 ft of coverage, 360° maximum horizontal sensing angle, and dual-stage glass break detection.",  
          },
          {  
            title:"Wireless communicationn", 
            description:"Communicates with the system control panel using a radio frequency.", 
          },
          { 
            title:"Battery powered", 
            description:"Can be mounted anywhere you choose, ceiling or wall." 
          },
          { 
            title:"Fully supervised", 
            description:"Monitoring professionals receive alerts 24/7, and can call for help immediately." 
          }
        ] 
      },
      {
        title: 'Keychain Remote',
        image: 'Product-Listing-Key-Fob-Product-Image.jpg',
        description: "Take a step out of your security routine. Slomin's keychain remotes arm or disarm your system with the touch of a button.", 
        category: 'sensors',
        slug: 'key-fob',   
        deatilsLargeImage   : 'Product-Listing-Key-Fob-Product-Image.jpg', 
        deatilsSamllTitle   : "Quick system control at your fingertips.",   
        key_features:[
          {
            title:"Panic button*", 
            description:"Press the emergency button to immediately alert central monitoring if you're in danger.",  
          },
          {  
            title:"Bypass codes", 
            description:"Arm or disarm your alarm system with one-touch, no security code necessary.", 
          },
          { 
            title:"Compact design", 
            description:"Add your key fob onto your keychain or in a pocket with ease." 
          }
        ] 
      },
      {
        title: 'Smoke Detector',
        image: 'Product-Listing-Smoke-Detector-Product-Image.jpg',
        description: "The detector sounds a loud alarm when excessive smoke or heat is detected. Protection is provided within a 35‐foot radius of the unit.", 
        category: 'sensors',
        slug: 'smoke-detector',  
        deatilsLargeImage   : 'Product-Listing-Smoke-Detector-Product-Image.jpg', 
        deatilsSamllTitle   : "When dangerous levels of smoke are detected, Slomin’s smoke detectors alert the monitoring team for 24/7 added protection.",    
        key_features:[
          {
            title:"Battery powered and wireless", 
            description:"Installed wherever you need them.",  
          },
          {  
            title:"Professionally monitored", 
            description:"The Slomin's monitoring team responds to smoke alerts and dispatches 1st responders, even when you are away.", 
          },
          { 
            title:"Self-contained sounder", 
            description:"The alarm sounds a loud local alarm to alert anyone in your home." 
          }
        ] 
      },
      {
        title: 'Carbon Monoxide Detector',
        image: 'Product-Listing-CO2-Dectector-Product-Image.jpg',
        description: "A carbon monoxide alarm is an excellent way to protect your family. It can detect the CO you can't see, smell or taste in the air.", 
        category: 'sensors',
        slug: 'carbon-monoxide-detector',  
        deatilsLargeImage   : 'Product-Listing-CO2-Dectector-Product-Image.jpg', 
        deatilsSamllTitle   : "Your system even detects invisible threats such as CO2.",
        disclaimerText      : "Additionally, Slomin's recommends the use of conventional carbon monoxide detectors throughout your home and bedrooms.",             
        key_features:[
          {
            title:"Smart communication", 
            description:"The detector communicates with the control panel to send alarm, tamper and battery condition messages.",  
          },
          {  
            title:"24/7 Monitoring", 
            description:"Slomin's professionals respond to CO2 alerts around the clock for your safety.", 
          },
          { 
            title:"Entry detection", 
            description:"Programmed to notify you of someone entering/leaving even when your system is disarmed." 
          },
          { 
            title:"Battery powered and wireless", 
            description:"Installed near devices that emit CO2." 
          }
        ] 
      },
       /******************************************************** Home Automation start********************************************************/
      {
        title: 'Home Automation on-the-go',
        image: 'Product-Listing-On-The-Go-App-Product-Image.jpg',
        description: "Manage your account from virtually anywhere. The mySlomin's App makes it easy to control home devices directly from a mobile phone or tablet.", 
        category: 'home-automation',
        slug: 'home-automation-on-the-go',   
        deatilsLargeImage   : 'Product-Listing-On-The-Go-App-Product-Image.jpg', 
        deatilsSamllTitle   : "Control of your home automation and security system in the palm of your hands.",
        disclaimerText      : "Requires an internet connection. Data rates may apply.",             
        key_features:[
          {
            title:"24/7 access", 
            description:"Control your security system and home automation devices.",  
          },
          {  
            title:"Remote arm/disarm", 
            description:"Turn your security system on and off from your phone.", 
          },
          { 
            title:"Live video", 
            description:"View live feeds from any of the cameras on your system." 
          }
        ] 
      },
      {
        title: 'Z-WAVE Thermostat',
        image: 'Product-Listing-Thermostat-Product-Image.jpg',
        description: "Our touchscreen thermostats let's you set and adjust energy-efficient settings at ease. Have full control of your home’s temperature whether it's from your bed, office, or the park!", 
        category: 'home-automation',
        slug: 'z-wave-thermostat',   
        deatilsLargeImage   : 'Product-Listing-Thermostat-Product-Image.jpg', 
        deatilsSamllTitle   : "Manage your home’s temperature from anywhere.", 
        disclaimerText      : "This product requires the ability to use Slomin's mobile apps in order to control this device which is available in the Ultimate Monitoring Plan at $49.95 per month.",          
        key_features:[
          {
            title:"Long battery life", 
            description:"Powered by 'AA' batteries, with 'low battery' notification.",  
          },
          {  
            title:"Readable", 
            description:"Easy-to-read LED display so you clearly see settings and room temperature.", 
          },
          { 
            title:"Custom settings", 
            description:"Save on your energy bill, program your thermostat to adjust the temperature throughout the day." 
          },
          { 
            title:"Smart control", 
            description:"Access and adjust your temperature remotely." 
          }
        ] 
      },
      {
        title: 'Z-WAVE Dimmer Module',
        image: 'Product-Listing-Dimmer-Product-Image.jpg',
        description: "Connecting your lights and small appliances to the Z-Wave plug-in module or dimmer lets you turn them on or off remotely.", 
        category: 'home-automation',
        slug: 'z-wave-dimmer-module',
        deatilsLargeImage   : 'Product-Listing-Dimmer-Product-Image.jpg', 
        deatilsSamllTitle   : "Turn any lamp or small appliance into a smart device.",
        disclaimerText      : "This product requires the ability to use Slomin's mobile apps in order to control this device which is available in the Ultimate Monitoring Plan at $49.95 per month.",              
        key_features:[
          {
            title:"Easy setup", 
            description:"Simply plug your module into the wall, then plug your lamp or small appliance into the module.",  
          },
          {  
            title:"Dimmer functionality", 
            description:"Dim any lamp or light source plugged into the module.", 
          },
          { 
            title:"Remote control", 
            description:"Turn your lights and appliances on or off using the MYSlomins app." 
          }
        ] 
      },
      {
        title: 'Z-WAVE Garage Door Opener',
        image: 'Product-Listing-Garage-Product-Image.jpg',
        description: "Open and close your garage door remotely.", 
        category: 'home-automation',
        slug: 'z-wave-remote-garage',   
        deatilsLargeImage   : 'Product-Listing-Garage-Product-Image.jpg', 
        deatilsSamllTitle   : "Opening your garage is as easy as downloading the mySlomins app.",
        disclaimerText      : "This product requires the ability to use Slomin's mobile apps in order to control this device which is available in the Ultimate Monitoring Plan at $49.95 per month.",              
        key_features:[
          {
            title:"Remote control", 
            description:"Use the MYSlomins app to open or close your garage door from anywhere.",  
          },
          {  
            title:"Convenient access", 
            description:"Open your garage remotely to allow deliveries.", 
          } 
        ] 
      },
      {
        title: 'Z-WAVE Door Lock',
        image: 'Product-Listing-Door-Lock-Product-Image.jpg',
        description: "Connecting your lights and small appliances to the Z-Wave plug-in module or dimmer lets you turn them on or off remotely.", 
        category: 'home-automation',
        slug: 'z-wave-deadbolt',   
        deatilsLargeImage   : 'Product-Listing-Door-Lock-Product-Image.jpg', 
        deatilsSamllTitle   : "Never get locked out again. Unlock your door with keys, the keypad or your phone.",
        disclaimerText      : "This product requires the ability to use Slomin's mobile apps in order to control this device which is available in the Ultimate Monitoring Plan at $49.95 per month.",           
        key_features:[
          {
            title:"Mobile access", 
            description:"No key, no problem. Unlock your door with the MySlomins app.",  
          },
          {  
            title:"Custom codes", 
            description:"Grant access codes to your home for up to 30 users.", 
          },
          { 
            title:"Visibility day or night", 
            description:"See the buttons in low lighting with the back-lit keypad." 
          },
          { 
            title:"Wireless communication", 
            description:"Smart alerts let you track whenever your door lock status changes ." 
          }
        ] 
      },
      {
        title: 'Z-WAVE Garage Door Opener',
        image: 'Product-Listing-Control-Panel-Keypad-Product-Image.jpg',
        description: "Open and close your garage door remotely.", 
        category: 'home-automation',
        slug: 'panel-keypad',  
        deatilsLargeImage   : 'Product-Listing-Control-Panel-Keypad-Product-Image.jpg', 
        deatilsSamllTitle   : "Operate your entire system from the wireless, user friendly keypad.",    
        key_features:[
          {
            title:"Simple interface", 
            description:"Control your system with a few intuitive touches.",  
          },
          {  
            title:"Wireless mounting", 
            description:"Place the control panel anywhere for easy access.", 
          },
          { 
            title:"Backlit color display", 
            description:"See information clearly displayed on your panel day or night." 
          },
          { 
            title:"Built-in Z-Wave technology", 
            description:"Z-Wave radio enables wireless communication with all automation devices in your home." 
          },
          { 
            title:"Weather information display", 
            description:"Scroll through the date, time, and real-time weather forecasts right on your panel." 
          },
          { 
            title:"Easy emergency access", 
            description:"One-touch button allows the quickest help possible in an emergency." 
          }
        ] 
      },
     
      /******************************************************** Home Automation end********************************************************/


      /******************************************************** More Start********************************************************/
      {
        title: 'Smart in-wall Switches',
        image: 'Product-Listing-Lighting-Control-Product-Image.jpg',
        description: "Smart light control switches let you flip the switch or dim the bulbs without getting up. Set your lights on timers to give the impression someone is always home.", 
        category: 'more',
        slug: 'smart-in-wall-switches',  
        deatilsLargeImage   : 'Product-Listing-Lighting-Control-Product-Image.jpg', 
        deatilsSamllTitle   : "More convenient lighting control for your home and busy life.",
        disclaimerText   : "Requires installation by a licensed electrician.This product requires the ability to use Slomin's mobile apps in order to control this device which is available in the Ultimate Monitoring Plan at $49.95 per month.",     
        key_features:[
          {
            title:"Easy installation", 
            description:"Simply wire in place of a standard wall switch and you're all set.",  
          },
          {  
            title:"Remote control", 
            description:"Control more of your lighting remotely using the MYSlomins app.", 
          },
          { 
            title:"Schedule events", 
            description:"Set your lights to automatically turn on at specific times or in response to triggers." 
          }
        ] 
      },
      {
        title: 'Professional Installation',
        image: 'Product-Listing-Professional-Install-Product-Image.jpg',
        description: "Every Slomin's system is professionally installed by one of our highly skilled technicians.", 
        category: 'more',
        slug: 'professional-installation',   
        deatilsLargeImage   : 'Product-Listing-Professional-Install-Product-Image.jpg', 
        deatilsSamllTitle   : "More convenient lighting control for your home and busy life.",   
        key_features:[
          // {
          //   title:"", 
          //   description:"",  
          // },
          // {  
          //   title:"", 
          //   description:"", 
          // },
          // { 
          //   title:"", 
          //   description:"" 
          // }
        ] 
      },
      {
        title: 'Outdoor Contact',
        image: 'Product-Listing-Outdoor-Contact-Product-Image.jpg',
        description: "Place an outdoor contact on a shed door or gate that you want secured. This contact is designed for the harsh conditions that exist outside of your home.", 
        category: 'more',
        slug: 'outdoor-contact',
        deatilsLargeImage   : 'Product-Listing-Outdoor-Contact-Product-Image.jpg', 
        deatilsSamllTitle   : "Protect your outdoor storage areas.", 
        disclaimerText      : "Works within range of your control panel.",         
        key_features:[
          {
            title:"Weather resistance", 
            description:"Sensors designed to withstand extreme climates.",  
          },
          {  
            title:"Wireless communication", 
            description:"Sends a notification to the alarm panel when a door, gate or window is opened or closed.", 
          },
          { 
            title:"Customizable", 
            description:"Can be painted to match its mounting surface." 
          }
        ] 
      },
      {
        title: 'Personal Emergency Pendant',
        image: 'Product-Listing-Panic-Button-Product-Image.jpg',
        description: "Send an instant emergency signal to our monitoring station. The panic remote is small enough to wear and activated with the press of a button.", 
        category: 'more',
        slug: 'personal-emergency-transmitter',
        deatilsLargeImage   : 'Product-Listing-Panic-Button-Product-Image.jpg', 
        deatilsSamllTitle   : "Alert Slomin’s you’re in danger with the push of a button.", 
        disclaimerText      : "Pendant must be within range of the control panel.",                
        key_features:[
          {
            title:"Compact design", 
            description:"The panic button can be worn or placed anywhere for easy connection to Slomin's.",  
          },
          {  
            title:"Quick connectivity", 
            description:"Easy access to help during any emergency with a button push.", 
          },
          { 
            title:"Always on", 
            description:"The signal is transmitted whether your security system is armed or disarmed." 
          }
        ] 
      },
      {
        title: 'Water Detector',
        image: 'Product-Listing-Water-Detector-Product-Image.jpg',
        description: "Monitor water levels under sinks, in the laundry room and anywhere water flows.", 
        category: 'more',
        slug: 'water-detector', 
        deatilsLargeImage   : 'Product-Listing-Water-Detector-Product-Image.jpg', 
        deatilsSamllTitle   : "Don’t let water leaks cause damage to your home.",   
        key_features:[
          {
            title:"System alerts", 
            description:"Receive notifications when a leak is detected.",  
          },
          {  
            title:"Easy installation", 
            description:"Simply place near the water source and the detector wirelessly communicates to the control panel..", 
          }
        ] 
      },
      {
        title: 'Temperature Sensor',
        image: 'Product-Listing-Temperature-Sensor-Product-Image.jpg',
        description: "The temperature sensor indicates when there is a problem with your heating system and helps prevent issues like frozen pipes.", 
        category: 'more',
        slug: 'temperature-sensor', 
        deatilsLargeImage   : 'Product-Listing-Temperature-Sensor-Product-Image.jpg', 
        deatilsSamllTitle   : "Detect abnormally high or low temperatures in your home.",   
       
        key_features:[
          {
            title:"Condition notification", 
            description:"Track when the temperature drastically changes and take action.",  
          } 
        ] 
      },
      {
        title: 'Garage Door Tilt Sensor',
        image: 'Product-Listing-Garage-Tilt-Product-Image.jpg',
        description: "The tilt sensor detects the open or closed status of any garage door.", 
        category: 'more',
        slug: 'tilt-sensor-overhead-door',   
        deatilsLargeImage   : 'Product-Listing-Garage-Tilt-Product-Image.jpg', 
        deatilsSamllTitle   : "Monitor and control your garage door from your security system.",   
        key_features:[
          {
            title:"Tamper proof", 
            description:"If the tilt sensor is removed for any reason, the radio transmitter will send a tamper alert.",  
          },
          {  
            title:"Secure mount", 
            description:"Attached to the interior of your garage door.", 
          },
          { 
            title:"Battery alert", 
            description:"Receive a low battery report indicating that the battery should be replaced." 
          }
        ] 
      },
      {
        title: 'Keychain Remote',
        image: 'Product-Listing-Key-Fob-Product-Image.jpg',
        description: "Take a step out of your security routine. Slomin's keychain remotes arm or disarm your system with the touch of a button.", 
        category: 'more',
        slug: 'key-fob',   
        deatilsLargeImage   : 'Product-Listing-Key-Fob-Product-Image.jpg', 
        deatilsSamllTitle   : "Quick system control at your fingertips.", 
       
        key_features:[
          {
            title:"Panic button*", 
            description:"Press the emergency button to immediately alert central monitoring if you're in danger.",  
          },
          {  
            title:"Bypass codes", 
            description:"Arm or disarm your alarm system with one-touch, no security code necessary.", 
          },
          { 
            title:"Compact design", 
            description:"Add your key fob onto your keychain or in a pocket with ease." 
          }
        ] 
      }
      /******************************************************** More end********************************************************/
  ];