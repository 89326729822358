import React, { useEffect } from 'react';
import AOS from 'aos';
import SeedProdPageForm  from "../../components/common/SeedProdPageForm";
import { constant } from '@app/utils/constant';
const PPCSocialFamilySecurity = () => {
    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>            
            <main className="ppc_social_family_security_pg">
                <section className="hero_blk landing_pg_hero">
                    <div className="hero_inr container-xxl">
                        <div className="row align-items-center">
                            <div className="hero_content text-left col-lg-6 col-md-6 col-12 hero_box_outer">
                                <div className='hero_box'>
                                    <span className="note-sec">Limited Time Offer</span>
                                    <h1>Protect your family with Slomin's Home Security</h1>                                    
                                    <a href='#Contact-Form' className="white_btn">Get Started Now</a>
                                </div>
                            </div>
                            <div className="hero_content_img text-left col-lg-6 col-md-6 col-12">
                                <div className='hr_img_blk'>
                                    <img src="../img/Cartoon-family.png" alt="Cartoon Family"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_safety_blk" data-aos="fade-up">
                    <div className="home_safety_inr text-center">
                        <div className="container">
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='top_tiles'>
                                        <ul>
                                            <li>Free Equipment</li>
                                            <li>Free Installation</li>
                                            <li>24/7 Monitoring</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sec_title">
                                        <h2>3 Easy Ways to get <span>Slomin's Home Security</span></h2>     
                                    </div>
                                </div>
                            </div>
                            <div className="row our-offer-items">
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/shld-icons.png" alt="icon"/>
                                        <h4>Order the free Slomin's Shield®</h4>
                                        <p><a href={`${constant.ALARAM_SITEURL}`}>Shop online</a> or <a href="tel:18334801010">call</a> to request your free home security system.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/customize-icon.png" alt="icon"/>
                                        <h4>Customize a Security System</h4>
                                        <p>Choose additional products to tailor your home security system.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="slm-item">
                                        <img src="../img/security-sys-icon.png" alt="icon"/>
                                        <h4>Convert an Old Security System</h4>
                                        <p>Slomin's can takeover an old system and replace your monitoring service.</p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 more_btn_blk">
                                    <a className="red_btn" href="#Contact-Form">Request a Quote</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="monitoring_starting_blk" data-aos="fade-up">
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-12">
                                <div className="slm-item">
                                    <h2>Invest in the Best Home Security</h2>
                                    <h4>Professional Home Monitoring Starting at $1/day</h4>
                                    <p>Rely on Slomin’s to guard against intruders and help keep your home safe.</p>
                                    <div className="more_btn_blk">
                                        <a className="red_btn" href="tel:18334801010"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                                        <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28" />
                                    </svg> 833-480-1010</a>
                                        <p className='sml_txt'>Call for your free security system</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="free_equipment_blk" data-aos="fade-up">
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-12">
                                <div className='sec_title'>
                                    <h2>Meet the Free Equipment in <span>The Slomin's Shield®</span></h2>
                                    <p>All Slomin's home security systems include free professional installation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center justify-content-center four_box_blk">
                            <div className="col-12 col-sm-12 col-lg-3">
                                <div className="slm-item">
                                    <h4>Control Panel</h4>
                                    <p>Manages your entire security system</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3">
                                <div className="slm-item">
                                    <h4>Door/Window Contacts</h4>
                                    <p>Secures monitored entryways</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3">
                                <div className="slm-item">
                                    <h4>Decals & Signs</h4>
                                    <p>Show you have professional security</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-3">
                                <div className="slm-item">
                                    <h4>Motion Sensor</h4>
                                    <p>Detects sudden movements</p>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center justify-content-center single_large_box">
                            <div className="col-12">
                                <div className="slm-item">
                                    <div className='bx_imgs'>
                                        <img src="../img/300_Starburst.png" alt="300 Starburst"/>
                                        <img src="../img/Doorbell-Camera-img.png" alt="Doorbell Camera"/>
                                    </div>
                                    <div className='bx_content'>
                                        <h4>Bonus: Free Doorbell Camera*</h4>
                                        <p>Monitors activity at your front door</p>
                                        <p className='sml_txt'>*$300 value - free for new customers only - use code <strong>"doorbell"</strong> at chec kout</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 more_btn_blk">
                                <a className="red_btn" href="#Contact-Form">Request a Quote</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="claim_form_blk" id='Contact-Form'>
                    <div className="claim_form_inr container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-4" data-aos="fade-right">
                                <div className='claim_form_info'>
                                    <h2>Claim your <a href={`${constant.ALARAM_SITEURL}`}>Free Security System</a> Today!</h2>
                                    <p>Want to skip the form? Call <a href="tel:18334801010">833-480-1010</a> to speak with a home security specialist.</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-8" data-aos="fade-left">
                                <SeedProdPageForm />
                            </div>
                        </div>
                    </div>
                </section>   
            </main> 
        </>
    );
};

export default PPCSocialFamilySecurity;
