import React, { useEffect } from 'react';
import { constant } from '@app/utils/constant';
const CompareOil = () => {
    useEffect(() => { 
        document.body.classList.add('no_header_footer');  
        return () => {
          document.body.classList.remove('no_header_footer');
        };
      }, []);
    return (
        <>
            <main>
                <section className='compare_oil_blk'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-8'>
                                <div className="news-page-header">
                                    <h2>How you can save hundreds this year on home heating oil</h2>
                                    <div className="author_name_row">
                                        <span className="post_author_name">Zachary Salzman</span>
                                        <span className="post_date">Updated on November 19, 2021</span>
                                    </div>
                                </div>
                                <div className="news_content_area">
                                    <p><strong>Our Summary:</strong></p>
                                    <ul>
                                        <li>Current market trends show significant <strong>increases</strong> in <strong>cost per-gallon</strong> making good deals harder to come by.</li>
                                        <li><strong>Fixed-price oil</strong> contracts from a reliable source keeps you locked in at the same low rate all season, <strong>protecting homeowners</strong> from <strong>continued increases</strong>.</li>
                                        <li>Homeowners should act now to <strong>save more</strong> on home <strong>heating oil</strong> this year.</li>
                                    </ul>
                                    <p>As the summer months come to an end, we decided to have a look at the heating market to see what’s in store for homeowners this upcoming winter:</p>
                                    <img src="../../img/oil-price-high.jpg" alt="oil price high"/>
                                    <h3>Heating oil prices are up big this year</h3>
                                    <p>with rising fuel costs and inflation further driving an upsurge in cost per-gallon. COVID-19 has also impacted the price climb due to higher demand as more people become vaccinated. With that being said, homeowners can expect to pay a range of <strong>[$.60–$1.00]</strong> more than they did last year. Despite the increase, there are still ways to save big this season.</p>
                                    <h3>How to keep heating prices from rising: Fixed Price Heating Contract</h3>
                                    <p>A fixed price contract secures a price per gallon for the entire season, protecting a homeowner from seeing any increases during the length of the agreement. This is especially useful with a rising market, like we’ve seen for the past few months. Fixed prices also protect against surge charges during cold winter months, where homeowners could potentially get caught paying even more due to high demand. Because of this, the fixed prices companies usually offer tend to have the best deals before the heating season begins, as it helps the company forecast how much heating oil is required for the season.</p>
                                    <h3>Our recommendation: Slomin’s Oil</h3>
                                    <p>For nearly 100 years, Slomin’s has been saving customers money on heating oil, all while providing top tier customer service. Their fixed price offers are extremely competitive, all the while they make it incredibly easy to sign up through their online site.</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-4'>
                                <aside id="marketing-sidebar">
                                    <div className="inner-box">
                                        <div className="content-area">
                                            <figure className="sidebar_top_pic"><img src="../../img/oil_site_img.png" alt="Home image"/></figure>
                                            <div className="side_header">
                                                <h5>Get your fixed price today!</h5>
                                            </div> 
                                                <ul className="news_sidebar_list">
                                                <li>Quick and instant sign up</li>
                                                <li>24/7 Service Plan</li>
                                                <li>Family owned and operated since 1923</li>
                                                <li>10 cent discount for signing up online</li>
                                            </ul>
                                            <span className="btn-sidebar"><a href={`${constant.OIL_SITEURL}/shop`} className="red_btn">Lock In Now</a></span>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default CompareOil;
