import React, { useState } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import SliderComponents from "../../components/common/SliderComponents";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import { useEffect } from "react";
import PageTitleComponent from '../../components/PageTitleComponent';
const WhyChooseSlominsShield = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>
            <PageTitleComponent title={"Why Choose The Slomin's Shield to protect your family?"} />
            <main className="why-choose-the-slomins-shield">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center hero_box_outer">
                            <div className='hero_box'>
                                <h1>Choose Slomin's when Protection Matters</h1>
                                <p>Slomin's operates around-the-clock to guarantee your home safety.</p>
                                <div className="button_hero-blk"> 
                                    <Link className="red_btn" to={`/get-a-quote?aoi=11`}>Request a quote</Link> 
                                    <a href="tel:18667566467" className="white_btn"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                                        <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28" />
                                    </svg> 1-866-756-6467</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="trust-high-security_blk">
                    <div className="trust-high-security_inr container">
                        <div className="row">
                            <div className="col-12 col-md-12" data-aos="fade-up">
                                <div className="trust-high-security_heading">
                                    <h2>Trust <span>High Security</span> Standards</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="trust-high-security_img">
                                    <img src="../img/Why-Us-DIY-Install-Module.jpg" alt="got covered" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="trust_high_security_content">
                                    <h3>Shield Your Home, Your Way</h3>
                                    <p>Our state-of-the-art security system is tailored to your home, installed, and serviced by professional technicians to ensure it works when you need it the most.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="trust-high-security_blk img_blk_grey">
                    <div className="trust-high-security_inr container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="trust_high_security_content">
                                    <h3>Not just a Doorbell Camera</h3>
                                    <p>Your Slomin's shield system is professionally monitored by trained experts even when you are distracted or your phone is off. You can feel free to disconnect.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="trust-high-security_img">
                                    <img src="../img/Why-Us-Not-Just-A-Doorbell-Module.jpg" alt="got covered" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="trust-high-security_blk">
                    <div className="trust-high-security_inr container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="trust-high-security_img">
                                    <img src="../img/nosub-contrator-img.jpg" alt="Got Covered" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="trust_high_security_content">
                                    <h3>No Middle-men, No Sub-contractors, All Slomin's</h3>
                                    <p>Our commitment to providing the highest quality security starts with employing and training the best. Every employee that engages with a Slomin’s customer is a trusted team member.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SliderComponents />
                <section className="get_slomin_shield_blk">
                    <div className="get_slomin_shield_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-4">
                                <div className="get_slomin_shield_img">
                                    <img src="../img/get-slomin-shield.png" alt="Service Repair" />
                                </div>
                            </div>
                            <div className="col-12 col-md-8">
                                <div className="get_slomin_shield_content">
                                    <h2>Get your Free Slomin's <span>Shield System</span></h2>
                                    <p>Everything you need plus free professional installation included! $1,145 in total savings .</p>
                                    <Link className="white_btn" to={`/get-a-quote`}>Get a Free quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-slomins-support">
                    <div className="home-slomins-support-inr text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section_heading text-center">
                                        <h2>
                                            Slomin's Support is<span> Available 24/7</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row our-offer-items">
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-cutomer-service-icon.svg" alt="icon" />
                                        <h4>Customer Service</h4>
                                        <p>
                                            Connect with customer service for help. Visit the <Link to={`/contact-us`}>Support page</Link> for details.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-slomins-app-icon.svg" alt="icon" />
                                        <h4>mySlomin's App</h4>
                                        <p>
                                            <Link to={`/home-security/home-security-cameras`}>Manage</Link> your system and account directly from your mobile device.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-trained-tech-icon.svg" alt="icon" />
                                        <h4>Trained Technicians</h4>
                                        <p>Slomin's technicians are available 24/7. <Link to={`/contact-us`}>Schedule</Link> a visit now.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SubscribeCompoents />
            </main>

        </>
    );
};

export default WhyChooseSlominsShield;
