import { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { useLocation } from 'react-router-dom';
import { fetchTrustPilotReviews } from '@app/services/trust_pilot';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import { constant } from '@app/utils/constant';
const SliderComponents = (props : any) => {
    let tagValue = 'Alarm';
   if(props.tagValue == "ReferallPage"){
        tagValue = ""
   } 
    const [trustPilotReviews, setTrustPilotReviewsData] = useState<any>([]);
    const [isLoading, setLoading] = useState(false);
    const getTrustPilotReviewsData = () => {
        setLoading(true);
        fetchTrustPilotReviews(tagValue).then(function (res: any) {
            const resp: any = res.reviews;
            if (resp.length > 0) {
                setTrustPilotReviewsData(resp);
            } else {
                setLoading(false);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        getTrustPilotReviewsData();
    }, []);

    const location = useLocation();
    const currentPage = location.pathname;
    const getPageName = currentPage.replace(/^\//, ''); 
    const pageName = getPageName.endsWith('/') ? getPageName.slice(0, -1) : getPageName;  
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 667,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);

    return (
        <>
            <section className="customer_reviews_blk" data-aos="fade-up">
                <div className="customer_reviews_inr container-xxl">
                    <div className="sec_title">
                        <h2>Slomin's Customer <span>Reviews</span></h2>
                    </div>
                    <div className="customer_reviews">
                        <Slider {...settings}>
                            {trustPilotReviews.length > 0 && trustPilotReviews.map((review: any) => {
                                const words = review.text.split(' ');
                                const preview = words.slice(0, 20).join(' ');
                                return <div className="reviews_item" key={review.id}>
                                    <span className="review_stars">
                                        <ul>
                                            {Array.from({ length: review.stars }, (_, i) => <li key={i}><img src="../img/shield_star_icon.svg" alt="star" /></li>)}
                                        </ul>
                                    </span>
                                    <p className="testimonial_author"><strong>{review.consumer.displayName}</strong></p>
                                    <p className="testimonial_text">{preview}</p>
                                </div>;
                            })}
                        </Slider>
                    </div>
                    <div className='cust_review_btn'>
                        <a className='red_btn' href={`${constant.TRUSTPILOT_REVIEW_API_URL}`} target='_blank'>Read More Customer Reviews</a>   
                    </div>
                </div>
            </section>

            {pageName == "iheartradio" || pageName === "next-day-installation" || pageName === "audacy" || pageName === "free-doorbell-camera" || pageName === "referral-program" || pageName === "professional-monitoring" || pageName === "why-choose-the-slomins-shield" ? "" :
                (pageName !== "about-us" ?
                    <section className="home-slomins-support" data-aos="fade-up">
                        <div className="home-slomins-support-inr text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="section_heading text-center">
                                            {pageName == "customer-reviews" ?
                                                <h2>Become a Satisfied <span>Slomin's Customer</span></h2>
                                                :
                                                <h2>Schedule Slomin's <span>Air Conditioning</span></h2>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row our-offer-items justify-content-center">
                                    <div className="col-md-4 col-sm-6 equal-height">
                                        <div className="_slm-item">
                                            <img src="../img/phone-icon-2.svg" alt="icon" max-width={80} />
                                            <h4>Make an Appointment</h4>
                                            <p>Set up a <a href="tel:18667566467" title='Tel:18667566467'>free, a no-obligation home visit</a> with one of our experts.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 equal-height">
                                        <div className="_slm-item">
                                            <img src="../img/alm-cutomer-service-icon.svg" alt="icon" />
                                            <h4>Customer Service</h4>
                                            <p>Connect with customer service for help. Visit the&nbsp; <Link to={`/contact-us`}>Support page</Link> &nbsp;for details.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="join_family_blk" data-aos="fade-up">
                        <div className="join_family_inr container-xxl">
                            <div className="join_family_content">
                                <h2>Join the Family</h2>
                                <p>We are committed to providing a positive work environment<br /> where employees have fun, work as a team, and thrive professionally.</p>
                                <a href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?ccId=1728567304_3745&cid=9f690535-b097-49a1-ac9d-0e3071b27ea3" target="_blank" rel="noopener noreferrer" className="red_btn">Explore Opportunities</a>
                            </div>
                        </div>
                    </section>
                )
            }
        </>
    );
};
export default SliderComponents;