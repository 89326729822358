import React, { useState,useEffect } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { constant } from '@app/utils/constant';
import ProductsSection from '../../components/products/ProductsSection';
import { Link } from 'react-router-dom'; 
import AOS from 'aos'; 
import PageTitleComponent from '../../components/PageTitleComponent';
const HomeSecurityProducts = () => {
    const [displayText, setDisplayText] = useState('Essentials');
    const [displayDisText, setDisplayDisText] = useState('Home security + state of the art technology = powerful piece of mind.');

    const handleClick = (value: React.SetStateAction<string>) => { 
        if(value == 'Essentials'){
            setDisplayDisText('Home security + state of the art technology = powerful piece of mind.');
        }else if(value == 'Cameras'){
            setDisplayDisText('Built to record HD video and audio in your home 24/7.');
        }else if(value == 'Sensors'){
            setDisplayDisText('Any disturbance alerts the professional monitoring team to respond and call authorities.');
        }else if(value == 'Home Automation'){
            setDisplayDisText('Automation');
        }else if(value == 'See More'){
            setDisplayDisText('Additional products available to customize your home security system.');
        }
        setDisplayText(value);
      };

      useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
          disable: "phone",
          duration: 700,
          easing: "ease-out-cubic",
        });
      }, []);

    return (
        <>
         <PageTitleComponent title={"Home Security Products powered by The Slomin's Shield"}/> 
            <main className="home_security_products_page">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center">
                            <h1>{ displayText }</h1> 
                            <p>{ displayDisText }</p>
                        </div>
                    </div>
                </section>
                <section className="home_security_products-tabs-blk" data-aos="fade-up">
                    <div className=" home_security-inr-sec container-xxl">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={(e) => handleClick('Essentials')} className="nav-link active" id="v-pills-essentials-tab" data-bs-toggle="pill" data-bs-target="#v-pills-essentials" type="button" role="tab" aria-controls="v-pills-essentials" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <path d="M12 1L20.217 2.826C20.674 2.928 21 3.333 21 3.802V13.789C21 15.795 19.997 17.669 18.328 18.781L12 23L5.672 18.781C4.002 17.668 3 15.795 3 13.79V3.802C3 3.333 3.326 2.928 3.783 2.826L12 1ZM16.452 8.222L11.502 13.171L8.674 10.343L7.26 11.757L11.503 16L17.867 9.636L16.452 8.222Z" fill="black" />
                                </svg><span className="p-2">Essentials</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={(e) => handleClick('Cameras')} className="nav-link" id="v-pills-cameras-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cameras" type="button" role="tab" aria-controls="v-pills-cameras" aria-selected="false"><svg className="svg-icon-tab" xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
                                    <path d="M9.31641 3.5H15.3164L17.3164 5.5H21.3164C21.5816 5.5 21.836 5.60536 22.0235 5.79289C22.2111 5.98043 22.3164 6.23478 22.3164 6.5V20.5C22.3164 20.7652 22.2111 21.0196 22.0235 21.2071C21.836 21.3946 21.5816 21.5 21.3164 21.5H3.31641C3.05119 21.5 2.79684 21.3946 2.6093 21.2071C2.42176 21.0196 2.31641 20.7652 2.31641 20.5V6.5C2.31641 6.23478 2.42176 5.98043 2.6093 5.79289C2.79684 5.60536 3.05119 5.5 3.31641 5.5H7.31641L9.31641 3.5ZM12.3164 19.5C13.9077 19.5 15.4338 18.8679 16.559 17.7426C17.6843 16.6174 18.3164 15.0913 18.3164 13.5C18.3164 11.9087 17.6843 10.3826 16.559 9.25736C15.4338 8.13214 13.9077 7.5 12.3164 7.5C10.7251 7.5 9.19898 8.13214 8.07377 9.25736C6.94855 10.3826 6.31641 11.9087 6.31641 13.5C6.31641 15.0913 6.94855 16.6174 8.07377 17.7426C9.19898 18.8679 10.7251 19.5 12.3164 19.5ZM12.3164 17.5C11.2555 17.5 10.2381 17.0786 9.48798 16.3284C8.73783 15.5783 8.31641 14.5609 8.31641 13.5C8.31641 12.4391 8.73783 11.4217 9.48798 10.6716C10.2381 9.92143 11.2555 9.5 12.3164 9.5C13.3773 9.5 14.3947 9.92143 15.1448 10.6716C15.895 11.4217 16.3164 12.4391 16.3164 13.5C16.3164 14.5609 15.895 15.5783 15.1448 16.3284C14.3947 17.0786 13.3773 17.5 12.3164 17.5Z" fill="#1E1E1E" />
                                </svg><span className="p-2">Cameras</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={(e) => handleClick('Sensors')} className="nav-link" id="v-pills-sensors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-sensors" type="button" role="tab" aria-controls="v-pills-sensors" aria-selected="false"><svg className="svg-icon-tab" xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
                                    <path d="M6.31641 8.50012V10.5001H18.3164V8.50012H15.3164V2.50012H17.3164V6.50012H22.3164V8.50012H20.3164V20.5001C20.3164 20.7653 20.2111 21.0197 20.0235 21.2072C19.836 21.3948 19.5816 21.5001 19.3164 21.5001H5.31641C5.05119 21.5001 4.79684 21.3948 4.6093 21.2072C4.42176 21.0197 4.31641 20.7653 4.31641 20.5001V8.50012H2.31641V6.50012H7.31641V2.50012H9.31641V8.50012H6.31641ZM13.3164 2.50012V8.50012H11.3164V2.50012H13.3164Z" fill="#1E1E1E" />
                                </svg><span className="p-2">Sensors</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button  onClick={(e) => handleClick('Home Automation')} className="nav-link" id="v-pills-home-automation-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-automation" type="button" role="tab" aria-controls="v-pills-home-automation" aria-selected="false"><svg className="svg-icon-tab" xmlns="http://www.w3.org/2000/svg" width={24} height={25} viewBox="0 0 24 25" fill="none">
                                    <path d="M20 20.4999C20 20.7651 19.8946 21.0195 19.7071 21.207C19.5196 21.3946 19.2652 21.4999 19 21.4999H5C4.73478 21.4999 4.48043 21.3946 4.29289 21.207C4.10536 21.0195 4 20.7651 4 20.4999V11.4999H1L11.327 2.11192C11.5111 1.9444 11.7511 1.85156 12 1.85156C12.2489 1.85156 12.4889 1.9444 12.673 2.11192L23 11.4999H20V20.4999ZM7 11.4999V13.4999C7.65661 13.4999 8.30679 13.6292 8.91342 13.8805C9.52004 14.1318 10.0712 14.5001 10.5355 14.9644C10.9998 15.4287 11.3681 15.9799 11.6194 16.5865C11.8707 17.1931 12 17.8433 12 18.4999H14C14 16.6434 13.2625 14.8629 11.9497 13.5502C10.637 12.2374 8.85651 11.4999 7 11.4999ZM7 15.4999V18.4999H10C10 17.7043 9.68393 16.9412 9.12132 16.3786C8.55871 15.816 7.79565 15.4999 7 15.4999Z" fill="#1E1E1E" />
                                </svg><span className="p-2">Home Automation</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={(e) => handleClick('See More')} className="nav-link" id="v-pills-more-tab" data-bs-toggle="pill" data-bs-target="#v-pills-more" type="button" role="tab" aria-controls="v-pills-more" aria-selected="false"><svg className="svg-icon-tab" xmlns="http://www.w3.org/2000/svg" width={24} height={25} viewBox="0 0 24 25" fill="none">
                                    <path d="M12 22.5001C6.477 22.5001 2 18.0231 2 12.5001C2 6.97712 6.477 2.50012 12 2.50012C17.523 2.50012 22 6.97712 22 12.5001C22 18.0231 17.523 22.5001 12 22.5001ZM11 11.5001H7V13.5001H11V17.5001H13V13.5001H17V11.5001H13V7.50012H11V11.5001Z" fill="#1E1E1E" />
                                </svg><span className="p-2">See More</span></button>
                            </li>
                        </ul>
                    

                    <div className="tab-content" id="v-pills-tabContent">
                        <ProductsSection categoryName={'essentials'} defaultclassName={'active'} />
                        <ProductsSection categoryName={'cameras'} defaultclassName={''} />
                        <ProductsSection categoryName={'sensors'} defaultclassName={''} />
                        <ProductsSection categoryName={'home-automation'} defaultclassName={''} />
                        <ProductsSection categoryName={'more'} defaultclassName={''} />
                    </div>
                    </div>
                </section>
                <section className="get_slomin_shield_blk">
                    <div className="get_slomin_shield_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-4" data-aos="fade-right">
                                <div className="get_slomin_shield_img">
                                    <img src="../img/get-slomin-shield.png" alt="service repair" />
                                </div>
                            </div>
                            <div className="col-12 col-md-8" data-aos="fade-left">
                                <div className="get_slomin_shield_content">
                                    <h2>Get your Free Slomin's <span>Shield System</span></h2>
                                    <p>Get everything you need while saving big with a <a href={`${constant.ALARAM_SITEURL}`} target="_blank">free</a> Slomin's Shield system; installation included!$1145 in total savings.</p>
                                    <div className='quote_btn_wrap'> 
                                        <Link className="white_btn" to={`/get-a-quote?aoi=04`}>Get a Free quote</Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <SubscribeCompoents />
            </main>


        </>
    );
};

export default HomeSecurityProducts;
