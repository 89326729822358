import { useEffect, useState } from 'react';
import { useSearchParams, useLocation,useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { submitYourRequestForm } from '../../services/get_a_quote';
import InputMask from 'react-input-mask';
import { GetAppointDate } from '../../services/get_a_quote';
import Select from 'react-select';
import Cookies from 'js-cookie'; 
const SeedProdPageForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPageName = location.pathname;
    let ftype = "";
    // show form field according to the type of page spotify = 1 , 
    if (currentPageName == '/spotify') {
        ftype = 1;
    } 
    if (currentPageName == '/next-day-installation' || currentPageName == '/audacy' || currentPageName == '/free-doorbell-camera' || currentPageName == '/ppc-gm-affordablesecurity' || currentPageName == '/iheartradio' || currentPageName == '/aragon' || currentPageName == '/pandora' || currentPageName == '/ppc-freesecuritysystem' || currentPageName == '/ppc-social-familysecurity' || currentPageName == '/ppc-wirelesssecurity' || currentPageName == '/iheart' || currentPageName == '/ppc-social-propertysecurity' || currentPageName == '/ppc-social-heirloomsecurity' || currentPageName == '/ppc-gm-freedoorbell' || currentPageName == '/ppc-freedoorbell' || currentPageName == '/ppc-order-afforablesecurity' || currentPageName == '/ppc-freeinstall') {
        ftype = 2;
    } 
     
    useEffect(() => { 
        window.scrollTo(0, 0);
    }, []);

    const [appointDate, setAppointDate] = useState([]);
    const GetAppointDateList = () => {
        GetAppointDate().then(function (res) {
            const resp = res;
            if (resp.Acknowledge == 1) {
                let AppointDateData: any = [];
                AppointDateData[0] = {
                    value: '',
                    label: 'Select Preferred'
                };
                resp.Dates.map((val: any, i: any) => {
                    AppointDateData[i + 1] = {
                        value: val.Id,
                        label: val.Name
                    };
                });
                setAppointDate(AppointDateData);
            }
        }).catch(err => {
            toast.error('Internal Server Error on getSalesPersonDropDown!');
        });
    };

    useEffect(() => {
        GetAppointDateList();
        window.scrollTo(0, 0);
    }, []);

    const [isLoading, setLoading] = useState(false);
    const [preferredDateOptionPreD, setPreferredDateOptionPreD] = useState('');
    const [preferredTimeOptionPreD, setPreferredTimeOptionPreD] = useState('');
    const [initialValuesBefor, setInitialValuesBefor] = useState({ full_name: '', address: '', zip: '', email: '', phone_number: '', aptdate: '', apttime: '', howdidyouhear: '' });
    const [preferredTimeOption, setPreferredTimeOption] = useState([{ value: '', label: 'Select Preferred Time' }, { value: '09:00', label: '09:00 AM' }, { value: '09:30', label: '09:30 AM' }, { value: '10:00', label: '10:30 AM' }, {
        value: '11:00', label: '11:00 AM'
    }, { value: '11:30', label: '11:30 AM' }, { value: '12:00', label: '12:00 PM' }, { value: '12:30', label: '12:30 PM' }, { value: '13:00', label: '01:00 PM' }, { value: '13:30', label: '01:30 PM' }, {
        value: '14:00', label: '02:00 PM'
    },
    {
        value: '14:30',
        label: '02:30 PM'
    },
    {
        value: '15:00',
        label: '03:00 PM'
    },
    {
        value: '15:30',
        label: '03:30 PM'
    },
    {
        value: '16:00',
        label: '04:00 PM'
    },
    {
        value: '16:30',
        label: '04:30 PM'
    },
    {
        value: '17:00',
        label: '05:00 PM'
    },
    {
        value: '17:30',
        label: '05:30 PM'
    },
    {
        value: '18:00',
        label: '06:00 PM'
    },
    {
        value: '18:30',
        label: '06:30 PM'
    },
    {
        value: '19:00',
        label: '07:00 PM'
    },
    {
        value: '19:30',
        label: '07:30 PM'
    },
    {
        value: '20:00',
        label: '08:00 PM'
    },
    {
        value: '20:30',
        label: '08:30 PM'
    },
    {
        value: '21:00',
        label: '09:00 PM'
    }
    ]);

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: initialValuesBefor,
        validationSchema: Yup.object().shape({
            full_name: Yup.string().required('Full name is required'),
            address: ftype == 2 ? Yup.string().required('Address is required') : Yup.string(),
            zip: Yup.string().required('Zip code is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phone_number: Yup.string().matches(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/, 'Invalid phone number format').required('Phone number is required'),
            aptdate: ftype == 2 ? Yup.string().required('Appointment date is required') : Yup.string(),
            apttime: ftype == 2 ? Yup.string().required('Appointment time is required') : Yup.string(),
            howdidyouhear: Yup.string().required('How did you hear about us required')
        }),
        onSubmit: (values, { resetForm }) => { 
            const selectedOptionElement = document.getElementById('mySelect');
            const selectedIndex = selectedOptionElement.selectedIndex;
            const selectedOption = selectedOptionElement.options[selectedIndex];
            const createNewOBJ = {
                ...values,
                subsource: selectedOption.getAttribute('data-subsource')
            }; 
            setLoading(true);
            const objValue = {
                "AppNamespace": null,
                "AppSid": "",
                "AppVersion": "",
                "CheckVersion": null,
                "IpAddress": null,
                "Latitude": null,
                "Longitude": null,
                "AccountNumber": null,
                "Address1": values.address,
                "Address2": null,
                "ApptDate": values.aptdate,
                "ApptTime": values.apttime,
                "AreaOfInterest": null,
                "Caller": "landingsite",
                "City": null,
                "Comment": null,
                "Email": values.email,
                "EntryUser": null,
                "FirstName": values.full_name,
                "Keyword": Cookies.get('kw') ? Cookies.get('kw') : null,
                "LastName": null,
                "LeadSource": values.howdidyouhear,
                "LedgerType": null,
                "Phone1": values.phone_number,
                "Phone2": null,
                "Pid": Cookies.get('pid') ? Cookies.get('pid') : null,
                "PromoCode": null,
                "ReferSource": null,
                "ResCom": "",
                "SalesRep": null,
                "SecurityIssue": null,
                "State": null,
                "SubSource": createNewOBJ.subsource,//ftype == 1 ? null : createNewOBJ.subsource,
                "WebTrack": null,
                "Xid": null,
                "Zip": values.zip,
            };
            submitYourRequestForm(objValue).then(function (res) {
                const resp = res.data;
                setLoading(false);
                if (resp.Acknowledge >= 0) {
                    navigate('/thank-you');
                } else {
                    toast.error("Internal Server Error");
                }
            }).catch(err => {
                toast.error('Internal Server Error!' + err);
            });

            setPreferredDateOptionPreD(false);
            setPreferredTimeOptionPreD(false);
            resetForm();
        }
    });
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="claim_form seedprod_form">
                    <div className='row'>
                        <div className="col-12 col-md-12 col-lg-6">
                            <Form.Control
                                id="full_name"
                                name="full_name"
                                type="text"
                                placeholder="Full Name *"
                                onChange={handleChange}
                                value={values.full_name}
                                isValid={touched.full_name && !errors.full_name}
                                isInvalid={touched.full_name && !!errors.full_name}
                            />
                            {touched.full_name && errors.full_name ? (
                                <Form.Control.Feedback type="invalid">
                                    {errors.full_name}
                                </Form.Control.Feedback>
                            ) : ''}
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <Form.Control
                                id="email"
                                name="email"
                                type="text"
                                placeholder="Email *"
                                onChange={handleChange}
                                value={values.email}
                                isValid={touched.email && !errors.email}
                                isInvalid={touched.email && !!errors.email}
                            />
                            {touched.email && errors.email ? (
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            ) : ''}
                        </div>
                        
                            {ftype !== 1 ?
                                <div className="col-12 col-md-12 col-lg-6">
                                <Form.Control
                                    id="address"
                                    name="address"
                                    type="text"
                                    placeholder="Address *"
                                    onChange={handleChange}
                                    value={values.address}
                                    isValid={touched.address && !errors.address}
                                    isInvalid={touched.address && !!errors.address}
                                />
                                {touched.address && errors.address ? (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address}
                                    </Form.Control.Feedback>
                                ) : ''}
                                </div>
                                : ""
                            }
                        
                        <div className="col-12 col-md-12 col-lg-6">
                            <Form.Group>
                                <InputMask
                                    mask="(999) 999-9999"
                                    placeholder="Enter phone number"
                                    id="phone_number"
                                    name="phone_number"
                                    onChange={handleChange}
                                    value={values.phone_number}
                                >
                                    {inputProps => <Form.Control {...inputProps} isValid={touched.phone_number && !errors.phone_number} isInvalid={touched.phone_number && !!errors.phone_number} />}
                                </InputMask>
                                <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <Form.Control
                                id="zip"
                                name="zip"
                                type="text"
                                placeholder="ZIP *"
                                onChange={handleChange}
                                value={values.zip}
                                isValid={touched.zip && !errors.zip}
                                isInvalid={touched.zip && !!errors.zip}
                            />
                            {touched.zip && errors.zip ? (
                                <Form.Control.Feedback type="invalid">
                                    {errors.zip}
                                </Form.Control.Feedback>
                            ) : ''}
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <select id="mySelect" className="form-control" name="howdidyouhear" onChange={handleChange} value={values.howdidyouhear}>
                                <option value="">How did you hear about us? *</option>
                                <option value="034" data-subsource="002">Internet</option>
                                <option value="004" data-subsource="886">TV</option>
                                <option value="001" data-subsource="886">Newspaper</option>
                                <option value="003" data-subsource="886">Radio</option>
                                <option value="008" data-subsource="886">Friend</option>
                                <option value="034" data-subsource="013">Other</option>
                            </select>
                            {errors.howdidyouhear ? (
                                <div className='validation-error'>
                                    {errors.howdidyouhear}
                                </div>
                            ) : ''}
                        </div>
                        {ftype !== 1 ?
                            <div className="col-12 col-md-12 col-lg-6">
                                <Form.Group className={`${errors.aptdate ? 'select-validation-error' : ''}`} controlId="aptdate">
                                    <Select
                                        onChange={
                                            selectedOption => {
                                                handleChange({ target: { name: 'aptdate', value: selectedOption.value } });
                                                setPreferredDateOptionPreD(selectedOption.value);
                                            }}
                                        placeholder="Select preferred date *"
                                        value={preferredDateOptionPreD ? appointDate[preferredDateOptionPreD] : ''}
                                        name="aptdate"
                                        options={appointDate}
                                    />
                                    {errors.aptdate ? (
                                        <div className='validation-error'>
                                            {errors.aptdate}
                                        </div>
                                    ) : ''}
                                </Form.Group>
                            </div>
                            : ""
                        }
                        {ftype !== 1 ?
                            <div className="col-12 col-md-12 col-lg-6">
                                <Form.Group className={`${errors.apttime ? 'select-validation-error' : ''}`} controlId="apttime">
                                    <Select
                                        onChange={
                                            selectedOption => {
                                                handleChange({ target: { name: 'apttime', value: selectedOption.value } });
                                                setPreferredTimeOptionPreD(selectedOption.value);
                                            }}
                                        placeholder="Select preferred time *"
                                        value={preferredTimeOptionPreD ? preferredTimeOption[preferredTimeOptionPreD] : ''}
                                        name="apttime"
                                        options={preferredTimeOption}
                                    />
                                    {errors.apttime ? (
                                        <div className='validation-error is-invalid'>
                                            {errors.apttime}
                                        </div>
                                    ) : ''}
                                </Form.Group>
                            </div>
                            : ""
                        }
                        
                        <div className="col-12">
                            <div className="privacy_note_txt">
                                <p>By clicking the "Submit Your Request" button below, you expressly agree to receive informational, emergency, marketing, and promotional text messages by or on behalf of SLOMIN'S, including via automated technology, at the telephone number provided above. Consent not required to make a purchase and you may revoke this consent at any time by texting STOP. Msg & data rates apply. You also agree to our <a href="/privacy-policy" target='_blank'>Privacy Policy</a> and <a href="/term-of-use" target='_blank'>Terms of Use</a>.</p>
                            </div>
                            <button type="submit" className="red_btn" disabled={isLoading ? "disabled" : ""}> {isLoading ? "Submitting..." : "Submit Your Request"}</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};
export default SeedProdPageForm;