import React, { useState, useEffect } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import SliderComponents from "../../components/common/SliderComponents"; 
import { Link } from 'react-router-dom';

import AOS from 'aos';

const Overview = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
          disable: "phone",
          duration: 700,
          easing: "ease-out-cubic",
        });
      }, []);

    return (
        <>
            <main className='for_business_overview'>
                <section className="hero_blk business_overview_hero">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <h1>From one Business to another — We’ve got you Covered</h1>
                                <p>Slomin’s offers a full range of commercial services to ensure your business security and comfort.</p> 
                                <Link className="white_btn" to={`/get-a-quote/?aoi=17&resbus=B`}>Schedule free consultation</Link> 
                            </div>
                        </div>
                    </div>
                </section>
                <section className="replace_system_blk">
                    <div className="replace_system_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="replace_system_content">
                                    <h3>Protect and Automate your Business</h3>
                                    <p>Secure your workplace and employees with a customized Slomin’s security system. Slomin’s can tailor a solution to meet your business’ needs.</p>      
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="replace_system_img">
                                    <img src="../img/slomins-home-lp-employee-working-in-office-at-desk.jpg" alt="employee working" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="replace_system_blk img_txt_blk_grey">
                    <div className="replace_system_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="replace_system_img">
                                    <img src="../img/Heating-Install-Oil-Conversations-Module.jpg" alt="Heating Install Oil Conversations" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="replace_system_content">
                                    <h3>Commercial Heating &amp; Oil Delivery</h3>
                                    <p>We understand the importance of providing clients and employees with comfortable warmth during the colder months. Slomin’s heating oil system &amp; fuel delivery guarantees reliable, cost-efficient service your business can depend on.</p>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="replace_system_blk">
                    <div className="replace_system_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="replace_system_content">
                                    <h3>Commercial Cooling</h3>
                                    <p>Our commercial cooling systems ensure that your business operates with maximized cooling and minimized costs throughout the hot season.</p> 
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="replace_system_img">
                                    <img src="../img/Cooling-Installation-Module-1.jpg" alt="employee working" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <SliderComponents/> 
                <SubscribeCompoents/>
            </main>
        </>
    );
};

export default Overview;
