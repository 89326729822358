import React from "react";
import { constant } from '@app/utils/constant';
import { Link } from 'react-router-dom';
import ProductJSONData from './ProductData';
const ProductsSection = (props: { defaultclassName: string; categoryName: string; }) => { 
    const capitalizeFirstLetter = (str: any) => {
        if (str == 'more') {
            str = 'See More';
        } else if (str == 'home-automation') {
            str = 'Home Automation';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return (
        <>
            <div className={`products-list-home tab-pane fade show ${props.defaultclassName == 'active' ? 'active' : ''}`} id={`v-pills-` + props.categoryName} role="tabpanel" aria-labelledby={`v-pills-` + props.categoryName + `-tab`}>
                <div className="row text-center">
                    <div className="col-md-12 d-none">
                        <h2>{capitalizeFirstLetter(props.categoryName)}</h2>
                    </div>
                    {
                        ProductJSONData.length > 0 ? ProductJSONData.map((row, podidx) => (
                            <>
                                {row.category === props.categoryName ? (
                                    <div className="col-12 col-md-4" key={podidx}>
                                        <div className="card">
                                            <div className="image-wrapper">
                                                <Link to={`/product/` + row.slug}>
                                                    <img src={`../img/` + row.image} alt="slomins door/window sensor gray box" />
                                                </Link>
                                            </div>
                                            <div className="card-content">
                                                <h4>{row.title}</h4>
                                                <p>{row.description}</p>
                                                <Link to={`/product/` + row.slug} className="button white_btn prd_learn_more">
                                                    Learn More
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""
                                }
                            </>
                        )) : ""}
                </div>
            </div>

        </>
    );
};

export default ProductsSection;
