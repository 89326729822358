import React,{ useEffect } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import PageTitleComponent from '../../components/PageTitleComponent';
const Faq = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);
    return (
        <>
        <PageTitleComponent title={"Frequently Asked Questions - Slomins"}/> 
            <main className='faq_page'>                
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center">
                            <h1>Frequently Asked Questions</h1>
                        </div>
                    </div>
                </section>
                <section className="faq_blk">
                    <div className="container-xxl">
                    <div className="row">
                        <div className="col-12 col-md-8">
                        <div className="faq_box">
                            <div className="faq_sec_title">
                            <h3>Billing</h3>
                            </div>
                            <div className="accordion" id="accordionBilling">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOneBilling">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneBilling" aria-expanded="false" aria-controls="collapseOneBilling">Can customer referral credits be transferred to a different account?</button>
                                </h2>
                                <div id="collapseOneBilling" className="accordion-collapse collapse" aria-labelledby="headingOneBilling" data-bs-parent="#accordionBilling">
                                <div className="accordion-body">
                                    <p>Credits cannot be transferred to a separate bill or customer, and can only be used under the account who generated the referral.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwoBilling">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoBilling" aria-expanded="false" aria-controls="collapseTwoBilling">What happens when I submit a payment that’s more than the monthly amount to My Account?</button>
                                </h2>
                                <div id="collapseTwoBilling" className="accordion-collapse collapse" aria-labelledby="headingTwoBilling" data-bs-parent="#accordionBilling">
                                <div className="accordion-body">
                                    <p>For account payments exceeding the charged amount, you can either:</p>
                                    <ul>
                                    <li>Apply the remaining credit to your next invoice.</li>
                                    <li>Request the credit be refunded to you.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThreeBilling">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeBilling" aria-expanded="false" aria-controls="collapseThreeBilling">Can I change my billing date?</button>
                                </h2>
                                <div id="collapseThreeBilling" className="accordion-collapse collapse" aria-labelledby="headingThreeBilling" data-bs-parent="#accordionBilling">
                                <div className="accordion-body">
                                    <p>You can update your account, billing details, and more by logging into <a href="https://mya.slomins.com/Account/Index" target="_blank">MYA.slomins.com</a> and selecting "Account Overview".&nbsp; For further assistance, please <strong>1-866-756-6467</strong>.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFourBilling">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourBilling" aria-expanded="false" aria-controls="collapseFourBilling">How do I pay my bill? What forms of payment do you accept?</button>
                                </h2>
                                <div id="collapseFourBilling" className="accordion-collapse collapse" aria-labelledby="headingFourBilling" data-bs-parent="#accordionBilling">
                                <div className="accordion-body">
                                    <p>The easiest way to pay and manage your bill is via your <a href="https://mya.slomins.com/Account/Index">MySlomin's Account Portal</a>.&nbsp; You can ensure your billing information is correct, set up automatic payments, add or change a credit card, and more. <strong>To make a payment over the phone</strong>, call 1-800-252-7663. <strong>To mail your payment</strong>, please address to: Slomin’s Inc. 125 Lauman Lane Hicksville, NY 11801</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="faq_box">
                            <div className="faq_sec_title">
                            <h3>Cooling</h3>
                            </div>
                            <div className="accordion" id="accordionCooling">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOneCooling">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCooling" aria-expanded="false" aria-controls="collapseOneCooling">How long does a cooling installation take?</button>
                                </h2>
                                <div id="collapseOneCooling" className="accordion-collapse collapse" aria-labelledby="headingOneCooling" data-bs-parent="#accordionCooling">
                                <div className="accordion-body">
                                    <p>AC Cooling installations typically take anywhere from 8-20 hours, or 2 days on average. It's also important to note factors like the size of your home, equipment type, and purchase specifics that'll determine exactly how long the install will take. Most cooling installations are complete within 2 days.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="faq_box">
                            <div className="faq_sec_title">
                            <h3>Heating</h3>
                            </div>
                            <div className="accordion" id="accordionHeating">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOneHeating">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneHeating" aria-expanded="false" aria-controls="collapseOneHeating">How long does a heating installation take?</button>
                                </h2>
                                <div id="collapseOneHeating" className="accordion-collapse collapse" aria-labelledby="headingOneHeating" data-bs-parent="#accordionHeating">
                                <div className="accordion-body">
                                    <p>Heating installations typically take 4-8 hours. It's also important to note factors like the size of your home, equipment type, and purchase specifics that'll determine exactly how long the install will take. Most heating installations are complete within 1 day.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="faq_box">
                            <div className="faq_sec_title">
                            <h3>Home Security</h3>
                            </div>
                            <div className="accordion" id="accordionHomeSecurity">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOneHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneHomeSecurity" aria-expanded="false" aria-controls="collapseOneHomeSecurity">How much will I be charged for a service call?</button>
                                </h2>
                                <div id="collapseOneHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingOneHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>With most calls accounted for under our service plans, it's important to reference your own in order to see exactly what is and isn't covered. If you choose to opt out of a Slomin's service plan, you'll be billed for the time and materials used during the call.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwoHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoHomeSecurity" aria-expanded="false" aria-controls="collapseTwoHomeSecurity">Where can I find a copy of my alarm system owner’s manual?</button>
                                </h2>
                                <div id="collapseTwoHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwoHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Your system-specific manual can be found via your Account Portal.  Just follow these steps:</p>
                                    <ol>
                                    <li>Visit <a href="http://mya.slomins.com/account/Index" target="_blank">MYA.slomins.com</a> and log into your account.</li>
                                    <li>Select the "Account Overview" tab.</li>
                                    <li>Tap "Security Account" <strong>&gt;</strong> "System Troubleshooting".</li>
                                    <li>At the top of the page, select the red icon next to "View your system's Owner's Manual for a complete guide to your equipment".</li>
                                    <li>View/download a PDF copy of your manual.</li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThreeHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeHomeSecurity" aria-expanded="false" aria-controls="collapseThreeHomeSecurity">How can I test my alarm?</button>
                                </h2>
                                <div id="collapseThreeHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingThreeHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Please visit your <a href="http://mya.slomins.com/account/Index" target="_blank">MySlomin's Account Portal</a> for specific instructions to test your alarm.&nbsp; For further assistance, please call <strong>1-866-756-6467</strong>.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFourHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourHomeSecurity" aria-expanded="false" aria-controls="collapseFourHomeSecurity">I have a pet, can I use the motion detector?</button>
                                </h2>
                                <div id="collapseFourHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingFourHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Yes. Our motion detectors contain several options to avoid and control sensor sensitivity so that your pets can wander freely throughout the home--all of which you can discuss in further detail with any Slomin's technician. Detectors typically allow for pets up to 30 lbs. and for larger animals, pet alleys can also be set up.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFiveHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiveHomeSecurity" aria-expanded="false" aria-controls="collapseFiveHomeSecurity">I have a dog. Why do I need an alarm system?</button>
                                </h2>
                                <div id="collapseFiveHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingFiveHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>While a dog can protect you when you're home, it takes a Slomin’s Shield home security system to safeguard your pup for when you aren’t. Unfortunately, burglars are ruthless and often willing to harm whatever's standing in their way of gaining access to your home. In the event of a house fire, Slomin’s fire protection offers immediate response to your signal in order to help protect your pets at home. Like our burglary protection, Slomin's fire safety and other protection services are all connected to our central monitoring station, allowing us to contact you immediately and respond effectively if something goes wrong.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSixHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixHomeSecurity" aria-expanded="false" aria-controls="collapseSixHomeSecurity">Why is my smoke detector beeping/chirping?</button>
                                </h2>
                                <div id="collapseSixHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingSixHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Only Slomin's hardwired smoke detectors will beep/chirp, indicating that your device's battery is low. When it does, simply twist the bottom to open your device and replace the batteries with new ones. Any other smoke detectors DO NOT "beep or chirp". Perhaps you have an old battery powered smoke detector that is not part of your Slomin's alarm system and its battery is running low. If you have a wireless Slomin's smoke detector and the battery is running low, your touchscreen keypad will display a "low battery" light.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSevenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSevenHomeSecurity" aria-expanded="false" aria-controls="collapseSevenHomeSecurity">What if I move?</button>
                                </h2>
                                <div id="collapseSevenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingSevenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Slomin's helps ensure you enjoy the same trusted protection at your new home.  If you're planning to relocate, here's what you can do:</p>
                                    <ul>
                                    <li><b>Bring your system equipment into your new home and continue the same service.&nbsp;&nbsp;</b>If you are not capable of installing the system in your new home, we'll send over a trained technician to do it for you at a minimal fee.</li>
                                    <li><b>Leave your system behind for the new homeowners.</b>&nbsp; Slomin’s will install a brand new system and continue the agreement to monitor the system.</li>
                                    <li><b>Transfer your current agreement over to the new homeowner, then install a brand new system and new agreement to your next place for free.</b>&nbsp; *Please be advised that additional required fees may vary depending on location.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingEightHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEightHomeSecurity" aria-expanded="false" aria-controls="collapseEightHomeSecurity">I didn’t know I signed up for a 5-year contract. Now what?</button>
                                </h2>
                                <div id="collapseEightHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingEightHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Whether its with Slomin’s or any other company/individual, it's extremely important to make sure you fully read and understand the agreement of which you're signing for. Slomin’s rewards a $1,145.00 home security system - free of charge - to customers who sign up for five years of central monitoring. If you break your end of the contract, Slomin’s has no way of compensating for the loss on equipment. In essence, if you sign a contract then decide that you no longer wish to protect your home with central monitoring, you remain accountable for the outstanding balance listed on your contract.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingNineHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineHomeSecurity" aria-expanded="false" aria-controls="collapseNineHomeSecurity">How long does an installation take?</button>
                                </h2>
                                <div id="collapseNineHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingNineHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Our basic alarm system installations typically take 3-4 hours. It's also important to note additional factors such as the size of your home, equipment type, and purchase specifics will determine exactly how long the install will take. Most of our alarm system installations are complete within 1 day.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTenHomeSecurity" aria-expanded="false" aria-controls="collapseTenHomeSecurity">Who do I call if I need assistance with my alarm system?</button>
                                </h2>
                                <div id="collapseTenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Please call our office at 1-800-ALARM-ME for all alarm system-related assistance, inquiries, or if you're concerned with a technician's status. We are always happy to help you.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingElevenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseElevenHomeSecurity" aria-expanded="false" aria-controls="collapseElevenHomeSecurity">My keypad display says “low battery”. What should I do?</button>
                                </h2>
                                <div id="collapseElevenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingElevenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>If "low battery" is displayed on your keypad, here are some common causes and how to fix it:</p>
                                    <ul>
                                    <li><strong>Your house does not have power, or recently lost power. </strong>In cases like this, your system will continue to operate on battery back up until it runs out. Once power is restored in the home, the battery will recharge automatically and restore your system once finished.&nbsp; *Please allow a 24-hour period for the battery to fully recharge.</li>
                                    <li><strong>The system transformer is unplugged.&nbsp;</strong> Check to see that your alarm panel transformer is securely plugged in.&nbsp; If not, plug it back in.</li>
                                    <li><strong>Faulty power source.&nbsp;</strong> If the alarm panel transformer is plugged in, check to see if there's any power coming from the outlet itself by plugging in another appliance and see if it works.&nbsp; If not, check the appropriate circuit breaker that the outlet is connected to.&nbsp; If your breaker is functioning - schedule a service call from a Slomin’s technician.&nbsp; If your breaker if not functioning - <span>you’ll need to call an electrician.</span></li>
                                    <li><strong>Your battery needs to be replaced.</strong> Replacement batteries can be purchased from Slomin's for $25 and mailed to you with installation instructions. You can also request a service call and have one of our technicians replace it. (If you choose this option, you'll be charged for time and materials.) Finally, you can call for the battery’s specifications and purchase a replacement from a retail store.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwelveHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelveHomeSecurity" aria-expanded="false" aria-controls="collapseTwelveHomeSecurity">If someone in my home is deaf, how will they know if the alarm is going off? How can they communicate with central station?</button>
                                </h2>
                                <div id="collapseTwelveHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwelveHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Slomin's home security systems contain sensory alerts, strobe lights, and other customizable settings for deaf and hard of hearing households.  Our central monitoring station also uses Telecommunications Relay Services (TTY/TRS) to facilitate calls between persons who are deaf, hard of hearing, or have speech disabilities and other individuals.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThirteenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteenHomeSecurity" aria-expanded="false" aria-controls="collapseThirteenHomeSecurity">Is there a penalty for false alarms?</button>
                                </h2>
                                <div id="collapseThirteenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingThirteenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Slomin's will not penalize you for false alarms and will always call and ask your verified passcode before dispatching any authorities.  However, it is the homeowners responsibility to check with their local officials, businesses, etc. regarding false alarm fines for their area.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFourteenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteenHomeSecurity" aria-expanded="false" aria-controls="collapseFourteenHomeSecurity">How long is the warranty?</button>
                                </h2>
                                <div id="collapseFourteenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingFourteenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>In the event that any part of your security equipment is defective, our services include a 1-year manufacturers warranty with opportunity to extend. Effective on the date of installation, Slomin's agrees to make all repairs and replacement of parts during this time without cost to the subscriber.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFifteenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteenHomeSecurity" aria-expanded="false" aria-controls="collapseFifteenHomeSecurity">Can I view the cameras off site?</button>
                                </h2>
                                <div id="collapseFifteenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingFifteenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Yes. You can access and manage your system devices from anywhere with internet connection via The MySlomin's App.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSixteenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteenHomeSecurity" aria-expanded="false" aria-controls="collapseSixteenHomeSecurity">Do the cameras see in the dark?</button>
                                </h2>
                                <div id="collapseSixteenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingSixteenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Yes - all of our cameras use ambient light functionality to deliver a clear image, day and night.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingSeventeenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeenHomeSecurity" aria-expanded="false" aria-controls="collapseSeventeenHomeSecurity">Do the cameras record?</button>
                                </h2>
                                <div id="collapseSeventeenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingSeventeenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Yes. All of our indoor and outdoor HD cameras feature 24/7 video recording functionality; with up to 5 days on-board video storage and 15 days cloud storage. Events are typically captured in 15-30 second video clips depending on motion, all of which can be downloaded.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingEighteenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteenHomeSecurity" aria-expanded="false" aria-controls="collapseEighteenHomeSecurity">Can you takeover my existing alarm?</button>
                                </h2>
                                <div id="collapseEighteenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingEighteenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Our home security specialists will fully examine your existing alarm system during your free at-home consultation. In most cases, we can have your current system adapt to ours.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingNineteenHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteenHomeSecurity" aria-expanded="false" aria-controls="collapseNineteenHomeSecurity">Can I operate the alarm system from my phone and can I know when someone turns the system on or off?</button>
                                </h2>
                                <div id="collapseNineteenHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingNineteenHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Yes. With the MySlomin's App for Apple and Android, you can operate and manage your system devices from virtually anywhere with internet connection directly from your mobile device or tablet.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwentyHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyHomeSecurity" aria-expanded="false" aria-controls="collapseTwentyHomeSecurity">Do you need to drill holes in my house to install the alarm system?</button>
                                </h2>
                                <div id="collapseTwentyHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwentyHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>In order to properly install your system, Slomin's is authorized to make the necessary preparations such as drilling holes, driving nails, and making attachments.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwentyoneHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyoneHomeSecurity" aria-expanded="false" aria-controls="collapseTwentyOneHomeSecurity">Do you monitor all the windows?</button>
                                </h2>
                                <div id="collapseTwentyoneHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwentyoneHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Our basic system arms up to 3 doors, providing the most comprehensive protection for your home. You can always speak to a Slomin's home security representative about additional features you may be interested in.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwentytwoHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentytwoHomeSecurity" aria-expanded="false" aria-controls="collapseTwentytwoHomeSecurity">What equipment do I get in the free system?</button>
                                </h2>
                                <div id="collapseTwentytwoHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwentytwoHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>The Slomin's Shield® home security system includes: Panel and Keypad, 3 Door Motion Detectors, Battery Back-up, Yard Signs and Window Decals; all provided with a free in-home security review and professional installation.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwentythreeHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentythreeHomeSecurity" aria-expanded="false" aria-controls="collapseTwentythreeHomeSecurity">Do I need a phone line in the house?</button>
                                </h2>
                                <div id="collapseTwentythreeHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwentythreeHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>No. Slomin's uses wireless cellular connection to commuicate with our central monitoring station, so you don't need a landline phone to use your system.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwentyfourHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyfourHomeSecurity" aria-expanded="false" aria-controls="collapseTwentyfourHomeSecurity">What is the rate for monitoring?</button>
                                </h2>
                                <div id="collapseTwentyfourHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwentyfourHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Slomin's monitoring rates start at $33.95* per-month, with upgraded options always available.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwentyfiveHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyfiveHomeSecurity" aria-expanded="false" aria-controls="collapseTwentyfiveHomeSecurity">What does it cost to cancel?</button>
                                </h2>
                                <div id="collapseTwentyfiveHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwentyfiveHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>If you wish to cancel your account, please call <strong>1-866-756-6467</strong> to speak with an in-house specialist and review your options.</p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwentysixHomeSecurity">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentysixHomeSecurity" aria-expanded="false" aria-controls="collapseTwentysixHomeSecurity">How long is the obligation?</button>
                                </h2>
                                <div id="collapseTwentysixHomeSecurity" className="accordion-collapse collapse" aria-labelledby="headingTwentysixHomeSecurity" data-bs-parent="#accordionHomeSecurity">
                                <div className="accordion-body">
                                    <p>Obligations run 5 years for homeowners and 3 years for tenant renters. Agreements are open-ended and can be customized to fit your needs.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-12 col-md-4">
                        <div className="need_help_box">
                            <h5>Still need help?</h5>
                            <div className="subtext">
                            <p>If you can't find an answer to your question, please contact us and we'll be happy to help you.</p>
                            <div className="subtext-phone">
                                <a className="phone" href="tel:18667566467"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.0557 11.8149C15.9537 11.8149 14.8716 11.6426 13.8462 11.3037C13.3438 11.1323 12.7261 11.2896 12.4194 11.6045L10.3955 13.1324C8.04833 11.8794 6.60252 10.4341 5.3667 8.10455L6.8496 6.13336C7.23486 5.74861 7.37305 5.18658 7.20749 4.65923C6.86717 3.62845 6.6943 2.54691 6.6943 1.44441C6.69435 0.647953 6.04639 0 5.24999 0H1.94436C1.14795 0 0.5 0.647953 0.5 1.44436C0.5 10.5733 7.92678 18 17.0557 18C17.8521 18 18.5001 17.352 18.5001 16.5556V13.2593C18.5 12.4629 17.8521 11.8149 17.0557 11.8149Z" fill="#EE3B33"></path></svg>1-866-756-6467</a>
                                <span>Monday-Friday, 8:00 AM to 4:00 PM EST</span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>

                {/* subscribe block section starts here */}
                <SubscribeCompoents />
                {/* subscribe block section ends here */}
            </main>
        </>
    );
};

export default Faq;
