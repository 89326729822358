export interface LocationData {
    title: string;
    heading: string;
    image: string;
    address: string;
    phone: string;
    'html-data': string;
  }
  
const locationData: Record<string, LocationData | LocationData[]> = {
    "california" : {
        "title" : "Slomins Los Angeles, CA",
        "heading" : "Slomin’s business and residential services available in the below California counties:",
        "image" : "california-location.jpg",
        "address" : "",
        "phone" : "",
        "html-data" : `<div class="location-available-service-inr row">
                    <div class="col-md-6 col-lg-4 col-12">
                        <div class="location-list-content">   
                        <h3>Business security</h3>
                        <ul>
                            <li>Los Angeles</li>
                            <li>Orange</li>
                            <li>Riverside</li>
                            <li>San Bernandino</li>
                            <li>Ventura</li>
                    </ul>
                    </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-12">
                        <div class="location-list-content">  
                            <h3>Home security</h3>
                        <ul>
                            <li>Los Angeles</li>
                            <li>Orange</li>
                            <li>Riverside</li>
                            <li>San Bernandino</li>
                            <li>Ventura</li>
                    </ul>
                    </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-12">
                        <div class="location-list-content">  
                            
                        <h3>Home automation</h3>
                        <ul>
                            <li>Los Angeles</li>
                            <li>Orange</li>
                            <li>Riverside</li>
                            <li>San Bernandino</li>
                            <li>Ventura</li>
                    </ul>
                    </div>
                    </div>
            </div>`,
    },
    "connecticut" : {
        "title" : "Slomin's Connecticut",
        "heading" : "Slomin's business and residential services available in the below Connecticut counties:",
        "image" : "connecticut-location.jpg",
        "address" : "",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>Business security</h3>
                                <ul>
                                    <li>Fairfield</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home security</h3>
                                <ul>
                                    <li>Fairfield</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Home automation</h3>
                                <ul>
                                    <li>Fairfield</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
    "delaware" : {
        "title" : "Slomin's Delaware",
        "heading" : "Slomin's business and residential services available in the below Delaware counties:",
        "image" : "delaware-location.jpg",
        "address" : "",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>Business security</h3>
                                <ul>
                                    <li>Kent</li>
                                    <li>New castle</li>
 	                                <li>Sussex</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home security</h3>
                                <ul>
                                    <li>Kent</li>
                                    <li>New castle</li>
                                    <li>Sussex</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Home automation</h3>
                                <ul>
                                    <li>Kent</li>
                                    <li>New castle</li>
                                    <li>Sussex</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
    "florida" : {
        "title" : "Slomin's Fort Lauderdale",
        "heading" : "Slomin's business and residential services available in the below Florida counties:",
        "image" : "location-florida.jpg",
        "address" : "3201 W COMMERCIAL BLVD, FORT LAUDERDALE, FL 33309",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>Business security</h3>
                                <ul>
                                    <li>Brevard</li>
                                    <li>Broward</li>
                                    <li>Charlotte</li>
                                    <li>Citrus</li>
                                    <li>Collier</li>
                                    <li>De Soto</li>
                                    <li>Flagler</li>
                                    <li>Glades</li>
                                    <li>Hardee</li>
                                    <li>Hendry</li>
                                    <li>Hernando</li>
                                    <li>Highlands</li>
                                    <li>Hillsborough</li>
                                    <li>Indian River</li>
                                    <li>Lake</li>
                                    <li>Lee</li>
                                    <li>Manatee</li>
                                    <li>Marion</li>
                                    <li>Martin</li>
                                    <li>Miami-Dade</li>
                                    <li>Okeechobee</li>
                                    <li>Orange</li>
                                    <li>Osceola</li>
                                    <li>Palm Beach</li>
                                    <li>Pasco</li>
                                    <li>Pinellas</li>
                                    <li>Polk</li>
                                    <li>Saint Lucie</li>
                                    <li>Sarasota</li>
                                    <li>Seminole</li>
                                    <li>Sumter</li>
                                    <li>Volusia</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home security</h3>
                                <ul>
                                    <li>Brevard</li>
                                    <li>Broward</li>
                                    <li>Charlotte</li>
                                    <li>Citrus</li>
                                    <li>Collier</li>
                                    <li>De Soto</li>
                                    <li>Flagler</li>
                                    <li>Glades</li>
                                    <li>Hardee</li>
                                    <li>Hendry</li>
                                    <li>Hernando</li>
                                    <li>Highlands</li>
                                    <li>Hillsborough</li>
                                    <li>Indian River</li>
                                    <li>Lake</li>
                                    <li>Lee</li>
                                    <li>Manatee</li>
                                    <li>Marion</li>
                                    <li>Martin</li>
                                    <li>Miami-Dade</li>
                                    <li>Okeechobee</li>
                                    <li>Orange</li>
                                    <li>Osceola</li>
                                    <li>Palm Beach</li>
                                    <li>Pasco</li>
                                    <li>Pinellas</li>
                                    <li>Polk</li>
                                    <li>Saint Lucie</li>
                                    <li>Sarasota</li>
                                    <li>Seminole</li>
                                    <li>Sumter</li>
                                    <li>Volusia</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Home automation</h3>
                                <ul>
                                    <li>Brevard</li>
                                    <li>Broward</li>
                                    <li>Charlotte</li>
                                    <li>Citrus</li>
                                    <li>Collier</li>
                                    <li>De Soto</li>
                                    <li>Flagler</li>
                                    <li>Glades</li>
                                    <li>Hardee</li>
                                    <li>Hendry</li>
                                    <li>Hernando</li>
                                    <li>Highlands</li>
                                    <li>Hillsborough</li>
                                    <li>Indian River</li>
                                    <li>Lake</li>
                                    <li>Lee</li>
                                    <li>Manatee</li>
                                    <li>Marion</li>
                                    <li>Martin</li>
                                    <li>Miami-Dade</li>
                                    <li>Okeechobee</li>
                                    <li>Orange</li>
                                    <li>Osceola</li>
                                    <li>Palm Beach</li>
                                    <li>Pasco</li>
                                    <li>Pinellas</li>
                                    <li>Polk</li>
                                    <li>Saint Lucie</li>
                                    <li>Sarasota</li>
                                    <li>Seminole</li>
                                    <li>Sumter</li>
                                    <li>Volusia</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
    "georgia" : {
        "title" : "Slomin's Georgia",
        "heading" : "Slomin's business and residential services available in the below Georgia counties:",
        "image" : "location-georgia.jpg",
        "address" : "",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>Business security</h3>
                                <ul>
                                    <li>Barrow</li>
                                    <li>Bartow</li>
                                    <li>Butts</li>
                                    <li>Carroll</li>
                                    <li>Cherokee</li>
                                    <li>Clayton</li>
                                    <li>Cobb</li>
                                    <li>Dekalb</li>
                                    <li>Douglas</li>
                                    <li>Fayette</li>
                                    <li>Floyd</li>
                                    <li>Forsyth</li>
                                    <li>Fulton</li>
                                    <li>Gwinnett</li>
                                    <li>Haralson</li>
                                    <li>Heard</li>
                                    <li>Henry</li>
                                    <li>Jackson</li>
                                    <li>Jasper</li>
                                    <li>Lamar</li>
                                    <li>Monroe</li>
                                    <li>Morgan</li>
                                    <li>Oconee</li>
                                    <li>Paulding</li>
                                    <li>Polk</li>
                                    <li>Rockdale</li>
                                    <li>Spalding</li>
                                    <li>Walton</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home security</h3>
                                <ul>
                                    <li>Barrow</li>
                                    <li>Bartow</li>
                                    <li>Butts</li>
                                    <li>Carroll</li>
                                    <li>Cherokee</li>
                                    <li>Clayton</li>
                                    <li>Cobb</li>
                                    <li>Dekalb</li>
                                    <li>Douglas</li>
                                    <li>Fayette</li>
                                    <li>Floyd</li>
                                    <li>Forsyth</li>
                                    <li>Fulton</li>
                                    <li>Gwinnett</li>
                                    <li>Haralson</li>
                                    <li>Heard</li>
                                    <li>Henry</li>
                                    <li>Jackson</li>
                                    <li>Jasper</li>
                                    <li>Lamar</li>
                                    <li>Monroe</li>
                                    <li>Morgan</li>
                                    <li>Oconee</li>
                                    <li>Paulding</li>
                                    <li>Polk</li>
                                    <li>Rockdale</li>
                                    <li>Spalding</li>
                                    <li>Walton</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Home automation</h3>
                                <ul>
                                    <li>Barrow</li>
                                    <li>Bartow</li>
                                    <li>Butts</li>
                                    <li>Carroll</li>
                                    <li>Cherokee</li>
                                    <li>Clayton</li>
                                    <li>Cobb</li>
                                    <li>Dekalb</li>
                                    <li>Douglas</li>
                                    <li>Fayette</li>
                                    <li>Floyd</li>
                                    <li>Forsyth</li>
                                    <li>Fulton</li>
                                    <li>Gwinnett</li>
                                    <li>Haralson</li>
                                    <li>Heard</li>
                                    <li>Henry</li>
                                    <li>Jackson</li>
                                    <li>Jasper</li>
                                    <li>Lamar</li>
                                    <li>Monroe</li>
                                    <li>Morgan</li>
                                    <li>Oconee</li>
                                    <li>Paulding</li>
                                    <li>Polk</li>
                                    <li>Rockdale</li>
                                    <li>Spalding</li>
                                    <li>Walton</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
    "maryland" : {
        "title" : "Slomin's Baltimore",
        "heading" : "Slomin's business and residential services available in the below Maryland and Baltimore counties:",
        "image" : "location-maryland.jpg",
        "address" : "7585 WASHINGTON BLVD, ELKRIDGE, MD 21075",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>Business security</h3>
                                <ul>
                                    <li>Ann Arundel</li>
                                    <li>Baltimore</li>
                                    <li>Baltimore City</li>
                                    <li>Calvert</li>
                                    <li>Caroline</li>
                                    <li>Carroll</li>
                                    <li>Cecil</li>
                                    <li>Charles</li>
                                    <li>Frederick</li>
                                    <li>Harford</li>
                                    <li>Howard</li>
                                    <li>Montgomery</li>
                                    <li>Prince George's</li>
                                    <li>Queen Anne's</li>
                                    <li>St. Mary's</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home security</h3>
                                <ul>
                                    <li>Ann Arundel</li>
                                    <li>Baltimore</li>
                                    <li>Baltimore City</li>
                                    <li>Calvert</li>
                                    <li>Caroline</li>
                                    <li>Carroll</li>
                                    <li>Cecil</li>
                                    <li>Charles</li>
                                    <li>Frederick</li>
                                    <li>Harford</li>
                                    <li>Howard</li>
                                    <li>Montgomery</li>
                                    <li>Prince George's</li>
                                    <li>Queen Anne's</li>
                                    <li>St. Mary's</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Home automation</h3>
                                <ul>
                                    <li>Ann Arundel</li>
                                    <li>Baltimore</li>
                                    <li>Baltimore City</li>
                                    <li>Calvert</li>
                                    <li>Caroline</li>
                                    <li>Carroll</li>
                                    <li>Cecil</li>
                                    <li>Charles</li>
                                    <li>Frederick</li>
                                    <li>Harford</li>
                                    <li>Howard</li>
                                    <li>Montgomery</li>
                                    <li>Prince George's</li>
                                    <li>Queen Anne's</li>
                                    <li>St. Mary's</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
    "new-jersey" : {
        "title" : "Slomin's New Jersey",
        "heading" : "Slomin's business and residential services available in the below Pennsylvania counties:",
        "image" : "location-new-jersey.jpg",
        "address" : "28 Kennedy Blvd, East Brunswick, NJ 08816",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>Business and home security</h3>
                                <ul>
                                    <li>Atlantic</li>
                                    <li>Bergen</li>
                                    <li>Burlington</li>
                                    <li>Camden</li>
                                    <li>Cape May</li>
                                    <li>Cumberland</li>
                                    <li>Essex</li>
                                    <li>Gloucester</li>
                                    <li>Hudson</li>
                                    <li>Hunterdon</li>
                                    <li>Mercer</li>
                                    <li>Middlesex</li>
                                    <li>Monmouth</li>
                                    <li>Morris</li>
                                    <li>Ocean</li>
                                    <li>Passaic</li>
                                    <li>Salem</li>
                                    <li>Somerset</li>
                                    <li>Sussex</li>
                                    <li>Union</li>
                                    <li>Warren</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home automation</h3>
                                <ul>
                                    <li>Atlantic</li>
                                    <li>Bergen</li>
                                    <li>Burlington</li>
                                    <li>Camden</li>
                                    <li>Cape May</li>
                                    <li>Cumberland</li>
                                    <li>Essex</li>
                                    <li>Gloucester</li>
                                    <li>Hudson</li>
                                    <li>Hunterdon</li>
                                    <li>Mercer</li>
                                    <li>Middlesex</li>
                                    <li>Monmouth</li>
                                    <li>Morris</li>
                                    <li>Ocean</li>
                                    <li>Passaic</li>
                                    <li>Salem</li>
                                    <li>Somerset</li>
                                    <li>Sussex</li>
                                    <li>Union</li>
                                    <li>Warren</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Home oil delivery</h3>
                                <ul>
                                    <li>Bergen</li>
                                    <li>Essex</li>
                                    <li>Hudson</li>
                                    <li>Hunterdon</li>
                                    <li>Mercer</li>
                                    <li>Middlesex</li>
                                    <li>Morris</li>
                                    <li>Passaic</li>
                                    <li>Somerset</li>
                                    <li>Sussex</li>
                                    <li>Union</li>
                                    <li>Warren</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
    "new-york" : [
        {
            "title" : "Slomin's Holtsville",
            "heading" : "",
            "image" : "location-new-york-1.jpg",
            "address" : "21 Long Island Ave, Holtsville, NY 11742",
            "phone" : "",
            "html-data" : `<div class="location-available-service-inr row">
                            <div class="col-md-6 col-lg-4 col-12">
                                <div class="location-list-content">   
                                    <h3>21 Long Island Ave, Holtsville, NY 11742</h3>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 col-12">
                                <div class="location-list-content">  
                                    <h3>Services Locations by County</h3>
                                </div>
                            </div>
                        </div>`,
        },
        {
            "title" : "Slomin's Hicksville",
            "heading" : "Slomin's business and residential services available in the below New York and Long Island counties:",
            "image" : "location-new-york-2.jpg",
            "address" : "125 Lauman Ln. Hicksville, NY 11801",
            "phone" : "(800) 252-7663",
            "html-data" : `<div class="location-available-service-inr row">
                            <div class="col-md-6 col-lg-4 col-12">
                                <div class="location-list-content">   
                                    <h3>Business & home security</h3>
                                    <ul>
                                        <li>Bronx</li>
                                        <li>Dutchess</li>
                                        <li>Kings</li>
                                        <li>Manhattan</li>
                                        <li>Nassau</li>
                                        <li>Orange</li>
                                        <li>Putnam</li>
                                        <li>Queens</li>
                                        <li>Rockland</li>
                                        <li>Staten Island</li>
                                        <li>Suffolk</li>
                                        <li>Sullivan</li>
                                        <li>Ulster</li>
                                        <li>Westchester</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 col-12">
                                <div class="location-list-content">  
                                    <h3>Home automation</h3>
                                    <ul>
                                        <li>Bronx</li>
                                        <li>Dutchess</li>
                                        <li>Kings</li>
                                        <li>Manhattan</li>
                                        <li>Nassau</li>
                                        <li>Orange</li>
                                        <li>Putnam</li>
                                        <li>Queens</li>
                                        <li>Rockland</li>
                                        <li>Staten Island</li>
                                        <li>Suffolk</li>
                                        <li>Sullivan</li>
                                        <li>Ulster</li>
                                        <li>Westchester</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 col-12">
                                <div class="location-list-content">                            
                                    <h3>Home heating oil</h3>
                                    <ul>
                                        <li>Bronx</li>
                                        <li>Dutchess</li>
                                        <li>Nassau</li>
                                        <li>Orange</li>
                                        <li>Putnam</li>
                                        <li>Queens</li>
                                        <li>Rockland</li>
                                        <li>Suffolk</li>
                                        <li>Westchester</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 col-12">
                                <div class="location-list-content">                            
                                    <h3>Natural gas services</h3>
                                    <ul>
                                        <li>Nassau</li>
                                        <li>Queens</li>
                                        <li>Suffolk</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 col-12">
                                <div class="location-list-content">                            
                                    <h3>Air conditioning services</h3>
                                    <ul>
                                        <li>Nassau</li>
                                        <li>Queens</li>
                                        <li>Suffolk</li>
                                    </ul>
                                </div>
                            </div>
                        </div>`,
        },
    ],
    "pennsylvania" : {
        "title" : "Slomin's Pennsylvania",
        "heading" : "Slomin's business and residential services available in the below Pennsylvania counties:",
        "image" : "locaiton-pennsylvania.jpg",
        "address" : "200 RITTENHOUSE CIR W, BRISTOL, PA 19007",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>>Business and home security<</h3>
                                <ul>
                                    <li>Berks</li>
                                    <li>Bucks</li>
                                    <li>Carbon</li>
                                    <li>Chester</li>
                                    <li>Delaware</li>
                                    <li>Lackawanna</li>
                                    <li>Lancaster</li>
                                    <li>Lebanon</li>
                                    <li>Lehigh</li>
                                    <li>Luzerne</li>
                                    <li>Monroe</li>
                                    <li>Montgomery</li>
                                    <li>Northampton</li>
                                    <li>Philadelphia</li>
                                    <li>Pike</li>
                                    <li>Schuylkill</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home automation</h3>
                                <ul>
                                <li>Berks</li>
                                <li>Bucks</li>
                                <li>Carbon</li>
                                <li>Chester</li>
                                <li>Delaware</li>
                                <li>Lackawanna</li>
                                <li>Lancaster</li>
                                <li>Lebanon</li>
                                <li>Lehigh</li>
                                <li>Luzerne</li>
                                <li>Monroe</li>
                                <li>Montgomery</li>
                                <li>Northampton</li>
                                <li>Philadelphia</li>
                                <li>Pike</li>
                                <li>Schuylkill</li>
                            </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Heating oil</h3>
                                <ul>
                                    <li>Bucks</li>
                                    <li>Chester</li>
                                    <li>Delaware</li>
                                    <li>Montgomery</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
    "virginia" : {
        "title" : "Slomin's Virginiaa",
        "heading" : "Slomin's business and residential services available in the below Virginia counties:",
        "image" : "location-virginia.jpg",
        "address" : "",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>Business security</h3>
                                <ul>
                                    <li>Alexandra</li>
                                    <li>Arlington</li>
                                    <li>Fairfax</li>
                                    <li>Fairfax City</li>
                                    <li>Manassas</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home security</h3>
                                <ul>
                                    <li>Alexandra</li>
                                    <li>Arlington</li>
                                    <li>Fairfax</li>
                                    <li>Fairfax City</li>
                                    <li>Manassas</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Home automation</h3>
                                <ul>
                                    <li>Alexandra</li>
                                    <li>Arlington</li>
                                    <li>Fairfax</li>
                                    <li>Fairfax City</li>
                                    <li>Manassas</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
    "washington-dc" : {
        "title" : "Slomin's Washington DC",
        "heading" : "Slomin's business and residential services available in the below Washington D.C. counties:",
        "image" : "location-washington-dc.jpg",
        "address" : "",
        "phone" : "(800) 252-7663",
        "html-data" : `<div class="location-available-service-inr row">
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">   
                                <h3>Business security</h3>
                                <ul>
                                    <li>District of Columbia</li>   
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">  
                                <h3>Home security</h3>
                                <ul>
                                    <li>District of Columbia</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-12">
                            <div class="location-list-content">                            
                                <h3>Home automation</h3>
                                <ul>
                                    <li>District of Columbia</li>
                                </ul>
                            </div>
                        </div>
                    </div>`,
    },
};
export default locationData;
