import React, { useEffect } from 'react';
import { constant } from '@app/utils/constant';
const AlarmSystemSunset = () => {
    useEffect(() => { 
        document.body.classList.add('no_header_footer');  
        return () => {
          document.body.classList.remove('no_header_footer');
        };
      }, []);
    return (
        <>
            <main>
                <section className='compare_oil_blk'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-8'>
                                <div className="news-page-header">
                                    <h2>Is your Alarm System about to Stop Working?</h2>
                                    <div className="author_name_row">
                                        <span className="post_author_name">Nicholas Levy</span>
                                        <span className="post_date">Updated on February 15, 2022</span>
                                    </div>
                                </div>
                                <div className="news_content_area">
                                    <img src="../../img/3G-Alarm-Article-Header.jpg" alt="Keypad"/>
                                    <p><strong>Summary:</strong></p>
                                    <ul>
                                        <li>As the ever-changing world of technology progresses, network providers have now decided to <b> discontinue 3G service.</b></li>
                                        <li><b>5G network buildouts</b> will be coming into effect as early as next week, leading to connectivity loss for 3G devices.</li>
                                        <li>Alarm companies have recently been charged <b> several hundreds of dollars</b> upgrading to 5G networks.</li>
                                        <li>Despite looming network shutdowns, <b>Slomin’s Security</b> will upgrade your system for FREE.</li>
                                    </ul>
                                    <p>Starting next week, 3G network alarm systems will no longer be supported. &nbsp;Here’s what you need to know:</p>
                                    <p>While all systems work on the same basic principle of securing your home and property, a primary benefit for consumers is the alarm system’s direct connection to central station monitoring.&nbsp; Central alarm stations operate around-the-clock to monitor the property and respond quickly to alert proper authorities and proceed accordingly in the event of any emergency taking place at the home.</p>
                                    <p>With that, a home alarm unit’s direct connection signal needed to notify these central stations relies specifically on wireless communication between the home and the monitoring station. Without this wireless communication, there is nothing to alert dispatch centers in the short amount of time required to respond to an emergency.</p>
                                    <p>As 3G networks phase out, a legacy alarm system only compatible with 5G networks will therefore NOT be able to communicate emergency conditions as needed. In fact, some alarm companies have already been charging their customers several hundred dollars to upgrade their systems as the looming shutdown of 3G networks gets closer.</p>
                                    <p><b>What can you do to ensure your system is connected?</b></p>
                                    <p>In light of approaching network shutdowns, Slomin’s Security—a leading home security provider in the U.S.—will replace your 3G alarm system for FREE. By offering competitive monitoring rates and top-tier customer service, Slomin’s has become the leading choice for those who value quality.</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-4'>
                                <aside id="marketing-sidebar">
                                    <div className="inner-box">
                                        <div className="content-area">
                                            <figure className="sidebar_top_pic"><img src="../../img/Alarm-Truck.png" alt="Home Security"/></figure>
                                            <div className="side_header">
                                                <h5>Get your Alarm System Upgraded today</h5>
                                            </div> 
                                                <ul className="news_sidebar_list">
                                                <li>Free 5G Radio Upgrade</li>
                                                <li>Free Video Doorbell Camera</li>
                                                <li>Free Equipment</li>
                                                <li>24/7 Professional Central Station Monitoring</li>
                                            </ul>
                                            <span className="btn-sidebar"><a href={`${constant.ALARAM_SITEURL}/shop?pid=103&utm_source=bingnews`} className="red_btn">Upgrade your System Today</a></span>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default AlarmSystemSunset;
