import React, { useState, useEffect, useCallback } from 'react';
import { Outlet,useSearchParams } from 'react-router-dom';
import Header from '@app/modules/StaticMain/header/Header';
import Footer from '@app/modules/StaticMain/footer/Footer';
import { PfImage } from '@profabric/react-components'; 
import Cookies from 'js-cookie';
const StaticMain = () => {
const [searchParams, setSearchParams] = useSearchParams();
const pid = (searchParams.size > 0) ? searchParams.get('pid') : "";  
const kw = (searchParams.size > 0) ? searchParams.get('kw') : "";  
if(pid !== ''){
  Cookies.set('pid', pid,{ expires: 1 }); 
} 
if(kw !== ''){ 
  Cookies.set('kw', kw,{ expires: 1 });
 } 

  const [isAppLoaded, setIsAppLoaded] = useState(false);
  useEffect(() => {
    setIsAppLoaded(true);
  }, []);

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <div className="preloader flex-column justify-content-center align-items-center preloader_sales">
          <PfImage
            className="animation__shake"
            src="/img/shield_logo.png"
            alt="Slomins Logo"
            height={100}
            width={100}
          />
        </div>
      );
    }
    return (
      <>
        <Header />
        <Outlet />
        <Footer />

      </>
    );
  }, [isAppLoaded]);

  return getAppTemplate();
};

export default StaticMain;
