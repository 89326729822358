import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Residential from '../../components/get_aquote/Residential'
import BusinessOwner from '../../components/get_aquote/BusinessOwner';
import { GetAppointDate } from '../../services/get_a_quote';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import PageTitleComponent from '../../components/PageTitleComponent';
const GetAQuote = () => {
    const [appointDate, setAppointDate] = useState([]);
    const GetAppointDateList = () => {
        GetAppointDate().then(function (res) {
            const resp = res;
            if (resp.Acknowledge == 1) {
                let AppointDateData: any = [];
                AppointDateData[0] = {
                    value: '',
                    label: 'Select Preferred'
                };
                resp.Dates.map((val: any, i: any) => {
                    AppointDateData[i + 1] = {
                        value: val.Id,
                        label: val.Name
                    };
                });
                setAppointDate(AppointDateData);
            }
        }).catch(err => {
            toast.error('Internal Server Error on getSalesPersonDropDown!');
        });
    };
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        GetAppointDateList(); 
    }, []);
    return (
        <>
            <PageTitleComponent title={"Get a Quote - Slomins"} />
            <main className='get_quote_pg'>
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <h1>Request a Free Quote</h1>
                                <p>Fill in your information to receive a free quote and exclusive offers.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="request_quote_blk">
                    <div className="container-xxl">
                        <div className="quote_form">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active red_btn" id="pills-residential-tab" data-bs-toggle="pill" data-bs-target="#pills-residential" type="button" role="tab" aria-controls="pills-residential" aria-selected="true">Residential</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link red_btn" id="pills-business-tab" data-bs-toggle="pill" data-bs-target="#pills-business" type="button" role="tab" aria-controls="pills-business" aria-selected="false">Business Owner</button>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="pills-residential" role="tabpanel" aria-labelledby="pills-residential-tab" >
                                    <Residential appointDate={appointDate} />
                                </div>
                                <div className="tab-pane fade" id="pills-business" role="tabpanel" aria-labelledby="pills-business-tab">
                                    <BusinessOwner appointDate={appointDate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SubscribeCompoents />
            </main>
        </>
    );
};

export default GetAQuote;
