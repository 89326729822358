import React from "react";
import SliderComponents from "../../components/common/SliderComponents";
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { constant } from '@app/utils/constant';
const CoolingServiceRepair = () => {
    return (
        <>
            <main>
                <section className="hero_blk repair_hero">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content">
                            <h1>Keep your HVAC system<br /> running efficiently</h1>
                            <p>Extend the life of your central air conditioning system with Slomin’s<br /> maintenance and repair plans.</p>
                            <a className="red_btn" href={`${constant.OILSITE_BASEPATH}/get-a-quote/?aoi=04`}>Schedule free consultation</a>
                        </div>
                    </div>
                </section>
                <section className="replace_system_blk">
                    <div className="replace_system_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                                <div className="replace_system_content">
                                    <h2>Air conditioning maintenance is important</h2>
                                    <p>Regular maintenance boosts the efficiency of your central air conditioner, prevents extensive repairs, and lowers your energy costs. Slomin’s affordable AC service plans cover tune-ups and basic repairs. Choose from 3 service levels to suit your budget.</p>
                                    <a className="red_btn" href="https://s3.amazonaws.com/slodocs/Cool-Pak-AC-Service-REVISED-D0396C-rev03-2019.pdf" target="_blank">View central AC service plan</a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="replace_system_img">
                                    <img src="img/Cooling-Service-Repair-Maintenance-Module.jpg" alt="service repair" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="installation_reason_blk reason_blk_bgimg">
                    <div className="installation_reason_inr container-xxl">
                        <div className="row">
                            <div className="col-12 installation_reason_title">
                                <h2>5 key reasons to keep a<br /> maintenance schedule</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 col-xxl-4">
                                <div className="inst_reason_box">
                                    <div className="icon">1</div>
                                    <p>Reduce energy consumption. Even simple forms of maintenance like replacing a dirty AC filter can boost efficiency by 5-15%.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xxl-4">
                                <div className="inst_reason_box">
                                    <div className="icon">2</div>
                                    <p>Stop humidity problems. Over time, your condensate line can get clogged and stop your air conditioner’s natural humidity reduction. This can lead to excess moisture in your home and damage to walls, carpet, and more.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xxl-4">
                                <div className="inst_reason_box">
                                    <div className="icon">3</div>
                                    <p>Extend your air conditioner’s life. While your air conditioner’s model affects its life expectancy, the biggest indicator of lifespan is how well the system is maintained. Regular maintenance catches minor problems before they can get worse and lead to critical failure like compressor damage.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xxl-6">
                                <div className="inst_reason_box">
                                    <div className="icon">4</div>
                                    <p>Improve reliability. An air conditioner that’s well maintained is an air conditioner that performs as expected without unexpected failure or issues like thermostat problems and short cycling. Schedule Air Conditioning Service.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xxl-6">
                                <div className="inst_reason_box">
                                    <div className="icon">5</div>
                                    <p>Prevent repairs. Regular inspections and maintenance catches air conditioner problems before they get worse — and expensive. Our technicians check every component of your air conditioner to look for wear and tear, dirt accumulation, poor connections, and more.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                <SliderComponents/>

                <SubscribeCompoents/>
            </main>



        </>
    );
};

export default CoolingServiceRepair;
