const Page404 = () => {
    return (
        <>
            <section className="content">
                <div className="sales-error-box">
                    <div className="card card-primary ">
                        <div className="card-body">
                            <div className="card-title">
                                <h1 className="text-primary">404</h1>
                                <h3>Oops! Page Not Found</h3>
                                <p>Sorry, the page you are looking for doesn't exist.</p>
                            </div>
                            {/* <div className="row">
                                <div className="col-12 text-center">
                                    <a href="/" className="btn btn-primary home_btn">Back to Home</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Page404;
