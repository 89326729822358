import { useEffect } from 'react';
import AOS from 'aos';
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import { constant } from '@app/utils/constant';
const SubscribeCompoents = () => { 
    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []); 
    const url = `${constant.EMAIL_SUBSCRIBE_URL}` + '?u=' + constant.SUBSCRIBE_U_KEY + '&amp;id=' + constant.SUBSCRIBE_ID; 
    const { loading,error,success,message,handleSubmit} = useMailChimpForm(url); 
    const { fields, handleFieldChange } = useFormFields({EMAIL: ""}); 
    return (
        <>
            <section className="subscribe_blk" data-aos="fade-up">
                <div className="subscribe_inr">
                    <h2>Get the latest news &amp; offers from Slomin's delivered straight to your inbox.</h2>
                    <form onSubmit={event => { event.preventDefault(); handleSubmit(fields); }}>
                        <div className="row justify-content-center subscribe_input_blk">
                            <div className="col-auto">
                                <label htmlFor="inputEmail" className="visually-hidden">Enter your email</label>
                                <input
                                    id="EMAIL"
                                    autoFocus
                                    type="email"
                                    autoComplete='off'
                                    required
                                    placeholder='Enter your email'
                                    value={fields.EMAIL}
                                    onChange={handleFieldChange}
                                />
                                <button className="btn btn-primary subscribe_btn">{loading ? "Submitting..." : "Subscribe"}</button> 
                                {/* {loading && "submitting"} */}                                
                            </div>
                        </div>
                        <div className='error_msg'>
                            <p className='SubscribeError'>{error && "This email cannot be added to this list. Please enter a different email address."}</p>
                            <p className='SubscribeSuccess'>{success && message}</p>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};
export default SubscribeCompoents;