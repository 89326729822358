import React, { useEffect, useState, useRef } from 'react';
import { getIncidentsData } from '@app/services/get_a_quote';
const RingNotificationBar = (props: any) => {
    const [incident, setIncident] = useState(null);
    const [incidentId, setIncidentId] = useState('');
    const [visible, setVisible] = useState(false);
    const divRef = useRef(0);
    useEffect(() => {
        checkIncidents();
    }, []);
    const checkIncidents = () => {
        getIncidentsData().then(function (res: any) {
            const data = res;
            if (data.incidents && data.incidents.length > 0 && data.incidents[0].incident_updates[0] && localStorage.getItem('io_dismissed') !== data.incidents[0].id) {
                setIncidentId(data.incidents[0].id);
                setIncident(data.incidents[0]);
                setVisible(true);
            }
            setTimeout(() => {
                console.log(props.setDivHeight);
                props.setDivHeight(divRef.current.offsetHeight);
            }, 50);
        }).catch(err => {
            console.log(err);
        });
    };
    const handleClose = () => {
        if (incidentId) {
            localStorage.setItem('io_dismissed', incidentId);
        }
        setVisible(false);
        props.setRemoveDivHeight(false);
    };
    if (!visible || !incident) {
        return null;
    }

    return (
        <>
            <div className='error_msg_blk' id='error_msg_blk' ref={divRef}>
                <div className='error_msg_inr'>
                    <div className='close_btn_wrap' onClick={handleClose}>X</div>
                    <p>
                        <span style={{ color: 'white', fontWeight: 700, textTransform: 'capitalize' }}>
                            {incident.name}
                        </span>: {incident.incident_updates[0].body}
                    </p>
                </div>
            </div>
            {/*
        <div className="ringnotification-bar">
            <div className="ringnotification-bar-message">
                <p>
                    <span style={{ color: 'red', fontWeight: 700, textTransform: 'capitalize' }}>
                        {incident.name}
                    </span>
                    : {incident.incident_updates[0].body}
                </p>
            </div>
            <button className="ringnotification-bar-close" onClick={handleClose}>
                Close
            </button>
        </div>
        */}
        </>
    );
};

export default RingNotificationBar;
