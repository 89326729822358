import React, { useState, useEffect } from "react";
import { getBlogList } from "@app/services/blog";
import { useParams, useNavigate } from 'react-router-dom'
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import BlogSection from '../../components/blog/BlogSection';
import PaginatedItems from "../../components/PaginatedItems";
import PageTitleComponent from '../../components/PageTitleComponent';
const Blogs = () => {
    const [categoryName, setCategoryName] = useState("all");
    const [filterData, setFilterData] = useState({ 'categoryName': '' });
    const [blogData, setBlogData] = useState([]);
    const [totalLen, setTotalLen] = useState("");
    const params = useParams();
    const pageId = params.id ? params.id : 1;
    let offset = pageId;
    const navigate = useNavigate();
    const handleClick = (value: React.SetStateAction<string>) => {
        fetchBlogData(value, offset);
        setCategoryName(value);
        if (value == 'all') {
            navigate('/blog');
        } else {
            navigate('/category/' + value);
        }
    };
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        fetchBlogData(categoryName, offset);
    }, [offset]);
    const fetchBlogData = (filterData: any = "", offset: any) => {
        const blogDataArr = getBlogList(filterData, offset);
        blogDataArr.then(data => {
            setTotalLen(data.total);
            setBlogData(data.data);
        })
    };
    var newStrCategory = categoryName.replace(/-/g, " ");
    return (
        <>
            <PageTitleComponent title={categoryName == 'all' ? "Blog - Slomins" : "Helpful resources about " + categoryName.charAt(0).toUpperCase() + categoryName.slice(1) + "- Slomins"} />
            <main className="blog_page">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center">
                            <h1>{newStrCategory == 'all' ? "Blog" : newStrCategory.charAt(0).toUpperCase() + newStrCategory.slice(1)}</h1>
                            <p>{categoryName == 'all' ? "Helpful resources for home owners and business owners." : "Helpful resources about " + newStrCategory.charAt(0).toUpperCase() + newStrCategory.slice(1) + ""}</p>
                        </div>
                    </div>
                </section>
                <section className="blog_tab-blk container">
                    <div className="blog-tab-inr-sec">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" onClick={(e) => handleClick('all')} id="v-pills-all-tab" data-bs-toggle="pill" data-bs-target="#v-pills-all" type="button" role="tab" aria-controls="v-pills-all" aria-selected="true"><span className="p-2">All</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" onClick={(e) => handleClick('business-security')} id="v-pills-business-security-tab" data-bs-toggle="pill" data-bs-target="#v-pills-business-security" type="button" role="tab" aria-controls="v-pills-business-security" aria-selected="false"><span className="p-2">Business Security</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" onClick={(e) => handleClick('general')} id="v-pills-general-tab" data-bs-toggle="pill" data-bs-target="#v-pills-general" type="button" role="tab" aria-controls="v-pills-general" aria-selected="false"><span className="p-2">General</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" onClick={(e) => handleClick('home-automation')} id="v-pills-home-automation-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-automation" type="button" role="tab" aria-controls="v-pills-home-automation" aria-selected="false"><span className="p-2">Home Automation</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" onClick={(e) => handleClick('home-cooling')} id="v-pills-home-cooling-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-cooling" type="button" role="tab" aria-controls="v-pills-home-cooling" aria-selected="true"><span className="p-2">Home Cooling</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" onClick={(e) => handleClick('home-heating')} id="v-pills-home-heating-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-heating" type="button" role="tab" aria-controls="v-pills-home-heating" aria-selected="false"><span className="p-2">Home Heating</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" onClick={(e) => handleClick('home-security')} id="v-pills-home-security-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-security" type="button" role="tab" aria-controls="v-pills-home-security" aria-selected="false"><span className="p-2">Home Security</span></button>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="v-pills-tabContent">
                        <BlogSection categoryName={categoryName} defaultclassName={'active'} blogData={blogData} />
                    </div>
                    <PaginatedItems itemsPerPage={10} totalItem={totalLen} />
                </section>
                <SubscribeCompoents />
            </main>
        </>
    );
};

export default Blogs;
