import React, { useEffect } from 'react';

function PageTitleComponent({ title }) { 
  useEffect(() => {
    document.title = title; // Set the document title when the component mounts or when the title prop changes
    return () => {
      // Optionally, you can reset the title when the component unmounts
      document.title = "Slomin's";
    };
  }, [title]); // Re-run effect only if title changes
  
  // return (
  //   <>
  //     {/* Your component content */}
  //   </>
  // );
}

export default PageTitleComponent;
