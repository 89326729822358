import React from "react";
import SliderComponents from "../../components/common/SliderComponents";
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { constant } from '@app/utils/constant';
const CoolingInstallation = () => { 
    return (
        <> 
            <section className="hero_blk">
                <div className="hero_inr container-xxl">
                    <div className="hero_content">
                        <h1>Superior HVAC systems <br />for top performance</h1>
                        <p>Slomin’s Air Conditioning can help you upgrade to central air conditioning<br /> or a ductless system instead of a window unit or replace an old HVAC system.</p>
                        <a className="red_btn" href={`${constant.OILSITE_BASEPATH}/get-a-quote/?aoi=04`}>Schedule free consultation</a>
                    </div>
                </div>
            </section>
            <section className="installation_reason_blk">
                <div className="installation_reason_inr container-xxl">
                    <div className="row">
                        <div className="col-12 installation_reason_title">
                            <h2>7 reasons to install<br /> central air conditioning</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-xxl-4">
                            <div className="inst_reason_box">
                                <div className="icon">1</div>
                                <p>An air conditioning system can cool your entire home quickly and effectively while improving your indoor air quality.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xxl-4">
                            <div className="inst_reason_box">
                                <div className="icon">2</div>
                                <p>Noise reduction. A central AC or ductless mini-split system runs significantly quieter than traditional air conditioners with the unit located outside your home.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xxl-4">
                            <div className="inst_reason_box">
                                <div className="icon">3</div>
                                <p>Take control of your cooling system. With a central system, you control the temperature of your home with a smart thermostat. This can also help you reduce energy costs.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xxl-4">
                            <div className="inst_reason_box">
                                <div className="icon">4</div>
                                <p>Improved aesthetics. A central air conditioner’s working parts are located outside of your home and in the basement, not in a window or wall. This can improve the view from inside of your home and boost your curb appeal.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xxl-4">
                            <div className="inst_reason_box">
                                <div className="icon">5</div>
                                <p>Ductless mini-split systems which are ideal for retro-fitting an older home with air conditioning without ductwork.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xxl-4">
                            <div className="inst_reason_box">
                                <div className="icon">6</div>
                                <p>Better comfort. Central air conditioning evenly and efficiently cools all the rooms in your home.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xxl-4">
                            <div className="inst_reason_box">
                                <div className="icon">7</div>
                                <p>Better indoor air quality. Central air conditioning works with filters that remove contaminants from your indoor air like pollen, dust, mold spores, and pet dander.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="replace_system_blk">
                <div className="replace_system_inr container-xxl">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className="replace_system_content">
                                <h2>Replace your old HVAC system</h2>
                                <p>A new air conditioner delivers better performance, reliability and cost savings. According to the Department of Energy, replacing a 10-year-old air conditioner can give you an energy savings of 20-40% if you choose a high-efficiency model.</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="replace_system_img">
                                <img src="img/Cooling-Service-Repair-Module1.jpg" alt="service repair" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> 

             <SliderComponents />

             <SubscribeCompoents />
        </>
    );
};

export default CoolingInstallation;
