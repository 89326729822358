import React, { useState } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import { useEffect } from "react";
import PageTitleComponent from '../../components/PageTitleComponent';
const HomeAutomation = () => {
    const [mHAutomation, setMHAutomation] = useState("p-remote-arming");
    const [isVisible, setIsVisible] = useState({
        "p-remote-arming": true,
        "p-doorbell-camera": false,
        "p-light-control": false,
        "p-thermostat": false,
        "p-door-lock": false,
        "p-alarm-trigger": false,
        "p-system-notifications": false
    });
    const toggleVisibility = (type) => {
        let typeCheck = {
            "p-remote-arming": false,
            "p-doorbell-camera": false,
            "p-light-control": false,
            "p-thermostat": false,
            "p-door-lock": false,
            "p-alarm-trigger": false,
            "p-system-notifications": false
        }
        typeCheck[type] = true;
        setIsVisible(typeCheck);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });

    }, []);
    return (
        <>
            <PageTitleComponent title={"Home Automation Improve your busy life with Slomin's home automation"} />
            <main className="home_automation_page">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center hero_box_outer">
                            <div className='hero_box'>
                                <h1>Improve your Busy Life with Home Automation</h1>
                                <p>Home automation makes your life safer, smarter, and easier.</p>
                                <Link className="white_btn" to={`/get-a-quote?aoi=11`}>Request a Quote</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_automation_security-camera">
                    <div className="home_automation_security-camera_inr container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="home_automation_security-camera_content">
                                    <h2>Manage your home with Slomin's</h2>
                                    <p>Automate your home with the same Slomin's security system used to protect your family.</p>
                                    <Link className="slm-btn-primary" to={`/home-security-products#home-automation`}>Browse Smart Products</Link>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="home_automation_security-camera_img">
                                    <img src="../img/Home-Automation-Animated-Product-Carousel.gif" alt="home automation animated product carousel" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_automation-tabs-blk" data-aos="fade-up">
                    <div className="home_security-inr-sec container">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="v-pills-adjust-temperature-tab" data-bs-toggle="pill" data-bs-target="#v-pills-adjust-temperature" type="button" role="tab" aria-controls="v-pills-adjust-temperature" aria-selected="true"><span className="p-2">Adjust your Temperature</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="v-pills-lock-doors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-lock-doors" type="button" role="tab" aria-controls="v-pills-lock-doors" aria-selected="false"><span className="p-2">Lock your Doors</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="v-pills-control-lights-tab" data-bs-toggle="pill" data-bs-target="#v-pills-control-lights" type="button" role="tab" aria-controls="v-pills-control-lights" aria-selected="false"><span className="p-2">Control your Lights</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="v-pills-access-your-garage-doors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-access-your-garage-doors" type="button" role="tab" aria-controls="v-pills-access-your-garage-doors" aria-selected="false"><span className="p-2">Access your Garage Doors</span></button>
                            </li>
                        </ul>
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-adjust-temperature" role="tabpanel" aria-labelledby="v-pills-adjust-temperature-tab">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="home_automation_security-camera_content">
                                            <h2>Adjust your Temperature</h2>
                                            <p>Set energy-efficient settings. Have full control over your home's temperature from anywhere.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="home_automation_security-camera_img">
                                            <img src="../img/Home-Automation-Features-Thermostat-Slide.jpg" alt="home automation features thermostat slide" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-lock-doors" role="tabpanel" aria-labelledby="v-pills-lock-doors-tab">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="home_automation_security-camera_content">
                                            <h2>Lock your Doors</h2>
                                            <p>Create custom access codes and control door access from your phone. Great for kids or out-of-town guests, smart door locks tie into the security system and notify you when someone opens the door.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="home_automation_security-camera_img">
                                            <img src="../img/Home-Automation-Features-Door-Lock-Slide-1.jpg" alt="home automation animated product carousel" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-control-lights" role="tabpanel" aria-labelledby="v-pills-control-lights-tab">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="home_automation_security-camera_content">
                                            <h2>Control your Lights</h2>
                                            <p>Flip the switch or dim the bulbs without getting up. You can also program your lights to turn on automatically at specific times for added security and convenience.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="home_automation_security-camera_img">
                                            <img src="../img/Home-Automation-Features-Light-Control-Slide.jpg" alt="home automation animated product carousel" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-access-your-garage-doors" role="tabpanel" aria-labelledby="v-pills-access-your-garage-doors-tab">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="home_automation_security-camera_content">
                                            <h2>Access your Garage Door</h2>
                                            <p>Ever leave your garage door open? Your Slomin's Shield system can send a reminder to close the garage door or close it automatically.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="home_automation_security-camera_img">
                                            <img src="../img/Home-Automation-Features-Garage-Door-Slide-2.jpg" alt="home automation animated product carousel" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_automation-cards">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="home_automation-cards-img1" data-aos="fade-right">
                                    <div className='home_automation_cards_cont'>
                                        <h4>Hassle-Free Setup with your Existing System</h4>
                                        <p>Our wireless home automation devices integrate into your existing Slomin's security system and require no additional equipment to operate.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="home_automation-cards-img2" data-aos="fade-left">
                                    <div className='home_automation_cards_cont'>
                                        <h4>Customized home Ambiance</h4>
                                        <p>Program scenes to define smart device settings and create the perfect atmosphere in your home.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_mob-automation-tabs-blk" data-aos="fade-up">
                    <div className="home_automation-tab-inr container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="home_mob-automation-heading">
                                    <h2>Mobile Home <span>Automation</span></h2>
                                    <p>Control your smart home devices from any location with The mySlomin's App for iOS and Android.</p>
                                    <a href="https://play.google.com/store/apps/collection/cluster?clp=igNJChkKEzUxNzMwMjcyOTE2NjUyNDQzNzEQCBgDEioKJGNvbS5zZWN1cmVuZXR0ZWNoLnNsb21pbnM2cHJvZHVjdGlvbhABGAMYAQ%3D%3D:S:ANO1ljJf4Iw&gsr=CkyKA0kKGQoTNTE3MzAyNzI5MTY2NTI0NDM3MRAIGAMSKgokY29tLnNlY3VyZW5ldHRlY2guc2xvbWluczZwcm9kdWN0aW9uEAEYAxgB:S:ANO1ljLGHzU&hl=en_US&gl=US">
                                        <img src="../img/app-store-badge.webp" alt="" /></a>
                                    <a href="https://apps.apple.com/us/developer/slomins-inc/id468595151"><img src="../img/google-play-badge.webp" alt="" /></a>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="d-flex align-items-start home_mob-tab">
                                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button className={mHAutomation == 'p-remote-arming' ? 'nav-link active' : 'nav-link'} type="button" role="tab" aria-controls="v-pills-remote-arming" aria-selected="true"
                                            onClick={(e) => { toggleVisibility('p-remote-arming'); setMHAutomation('p-remote-arming') }}>Remote Arming/Disarming <p id="p-remote-arming" className="paragraph-show" style={{ display: isVisible['p-remote-arming'] ? 'block' : 'none' }}>Conveniently arm and disarm your systems from virtually anywhere.</p></button>

                                        <button className={mHAutomation == 'p-doorbell-camera' ? 'nav-link active' : 'nav-link'} id="v-pills-doorbell-camera-tab" data-bs-toggle="pill" data-bs-target="#v-pills-doorbell-camera" type="button" role="tab" aria-controls="v-pills-doorbell-camera" aria-selected="false" onClick={(e) => { toggleVisibility('p-doorbell-camera'); setMHAutomation('p-doorbell-camera') }}>Doorbell Camera <p id="p-doorbell-camera" className="paragraph-show" style={{ display: isVisible['p-doorbell-camera'] ? 'block' : 'none' }}>Know who or what is at the door with the motion activated doorbell camera.</p></button>

                                        <button className={mHAutomation == 'p-light-control' ? 'nav-link active' : 'nav-link'} id="v-pills-light-control-tab" data-bs-toggle="pill" data-bs-target="#v-pills-light-control" type="button" role="tab" aria-controls="v-pills-light-control" aria-selected="false" onClick={(e) => { toggleVisibility('p-light-control'); setMHAutomation('p-light-control') }}>Light Control <p id="p-light-control" className="paragraph-show" style={{ display: isVisible['p-light-control'] ? 'block' : 'none' }}>Adjust the lights in any room from the palm of your hand.</p></button>

                                        <button className={mHAutomation == 'p-thermostat' ? 'nav-link active' : 'nav-link'} id="v-pills-thermostat-tab" data-bs-toggle="pill" data-bs-target="#v-pills-thermostat" type="button" role="tab" aria-controls="v-pills-thermostat" aria-selected="false" onClick={(e) => { toggleVisibility('p-thermostat'); setMHAutomation('p-thermostat') }}>Thermostat <p id="p-thermostat" style={{ display: isVisible['p-thermostat'] ? 'block' : 'none' }} className="paragraph-show">Set your home's temperature exactly how you like it.</p></button>

                                        <button className={mHAutomation == 'p-door-lock' ? 'nav-link active' : 'nav-link'} id="v-pills-door-lock-tab" data-bs-toggle="pill" data-bs-target="#v-pills-door-lock" type="button" role="tab" aria-controls="v-pills-door-lock" aria-selected="false" onClick={(e) => { toggleVisibility('p-door-lock'); setMHAutomation('p-door-lock') }}>Door Lock <p id="p-door-lock" style={{ display: isVisible['p-door-lock'] ? 'block' : 'none' }} className="paragraph-show">Create custom access codes and lock or unlock the door from your phone.</p></button>

                                        <button className={mHAutomation == 'p-alarm-trigger' ? 'nav-link active' : 'nav-link'} id="v-pills-alarm-trigger-tab" data-bs-toggle="pill" data-bs-target="#v-pills-alarm-trigger" type="button" role="tab" aria-controls="v-pills-alarm-trigger" aria-selected="false" onClick={(e) => { toggleVisibility('p-alarm-trigger'); setMHAutomation('p-alarm-trigger') }}>Alarm Trigger<p id="p-alarm-trigger" style={{ display: isVisible['p-alarm-trigger'] ? 'block' : 'none' }} className="paragraph-show">Rest assured with a guaranteed response to any triggered alarm.</p></button>

                                        <button className={mHAutomation == 'p-system-notifications' ? 'nav-link active' : 'nav-link'} id="v-pills-system-notifications-tab" data-bs-toggle="pill" data-bs-target="#v-pills-system-notifications" type="button" role="tab" aria-controls="v-pills-system-notifications" aria-selected="false" onClick={(e) => { toggleVisibility('p-system-notifications'); setMHAutomation('p-system-notifications') }}>System Notifications <p id="p-system-notifications" style={{ display: isVisible['p-system-notifications'] ? 'block' : 'none' }} className="paragraph-show">Stay aware of changes in your home or account with automated text or email alerts.</p></button>
                                    </div>
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className={mHAutomation == 'p-remote-arming' ? 'tab-pane fade show active' : 'tab-pane fade'} id="v-pills-remote-arming" role="tabpanel" aria-labelledby="v-pills-remote-arming-tab"><img src="../img/slomins-app-arm-disarm-screenshot.webp" alt="" /></div>
                                        <div className={mHAutomation == 'p-doorbell-camera' ? 'tab-pane fade show active' : 'tab-pane fade'} id="v-pills-doorbell-camera" role="tabpanel" aria-labelledby="v-pills-doorbell-camera-tab"><img src="../img/doorbell-img.png" alt="" /></div>
                                        <div className={mHAutomation == 'p-light-control' ? 'tab-pane fade show active' : 'tab-pane fade'} id="v-pills-light-control" role="tabpanel" aria-labelledby="v-pills-light-control-tab"><img src="../img/light-control-img.webp" alt="" /></div>
                                        <div className={mHAutomation == 'p-thermostat' ? 'tab-pane fade show active' : 'tab-pane fade'} id="v-pills-thermostat" role="tabpanel" aria-labelledby="v-pills-thermostat-tab"><img src="../img/thermostat-img.webp" alt="" /></div>
                                        <div className={mHAutomation == 'p-door-lock' ? 'tab-pane fade show active' : 'tab-pane fade'} id="v-pills-door-lock" role="tabpanel" aria-labelledby="v-pills-door-lock-tab"><img src="../img/door-lock-img.webp" alt="" /></div>
                                        <div className={mHAutomation == 'p-alarm-trigger' ? 'tab-pane fade show active' : 'tab-pane fade'} id="v-pills-alarm-trigger" role="tabpanel" aria-labelledby="v-pills-alarm-trigger-tab"><img src="../img/alarm-mobile-img.webp" alt="" /></div>
                                        <div className={mHAutomation == 'p-system-notifications' ? 'tab-pane fade show active' : 'tab-pane fade'} id="v-pills-system-notifications" role="tabpanel" aria-labelledby="v-pills-system-notifications-tab"><img src="../img/security.webp" alt="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 text-center learn_more_btn_blk">
                                <Link to={`/home-security/home-security-cameras`} className="slm-btn-primary">Learn More</Link>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="home-slomins-support" data-aos="fade-up">
                    <div className="home-slomins-support-inr text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section_heading text-center">
                                        <h2>Slomin's Support is <span>Available 24/7</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row our-offer-items">
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-cutomer-service-icon.svg" alt="icon" />
                                        <h4>Customer Service</h4>
                                        <p>Connect with customer service for help. Visit the <Link to={`/contact-us`}>Support page</Link> for details.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-slomins-app-icon.svg" alt="icon" />
                                        <h4>mySlomin's App</h4>
                                        <p><Link to={`/home-security/home-security-cameras`}>Manage</Link> your system and account directly from your mobile device.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-trained-tech-icon.svg" alt="icon" />
                                        <h4>Trained Technicians</h4>
                                        <p>Slomin's technicians are available 24/7. <Link to={`/contact-us`}>Schedule</Link> a visit now.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SubscribeCompoents />
            </main>
        </>
    );
};

export default HomeAutomation;
