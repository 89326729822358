import React, { useEffect } from 'react';
import { constant } from '@app/utils/constant';
import AOS from 'aos';
const PPCShopSpecials = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>            
            <main className="ppc_special_pg">
                <section className="hero_blk landing_pg_hero">
                    <div className="hero_inr container-xxl">
                        <div className="row align-items-center">
                            <div className="hero_content text-left col-lg-6 col-md-6 col-12 hero_box_outer">
                                <div className='hero_box'>
                                    <span className="note-sec">Limited Time Offer</span>
                                    <h1>Free Equipment, Free Installation and Free Doorbell Camera</h1>
                                    <a href={`${constant.ALARAM_SITEURL}`} className="white_btn">Order Now</a>
                                    <p>Save <strong>$1,445</strong> on a new home security system.</p>
                                </div>
                            </div>
                            <div className="hero_content_img text-left col-lg-6 col-md-6 col-12">
                                <div className='hr_img_blk'>
                                    <img src="../img/Affordable-Alarm-Security-Landing-Page-Image.png" alt="Products"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Service plans section*/}
                <section className="our_services_plans">
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-12" data-aos="fade-up">
                                <div className='sec_title'>
                                    <h2>Slomin's Home Security - <span>Special Offers and Savings</span></h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12" data-aos="fade-right">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/Why-Us-Not-Just-A-Doorbell-Module.jpg" alt="Special Offers"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>24/7 Professional Home Monitoring Starting at <span>$35.95/mo</span></h4>
                                        <ul>
                                            <li>24/7 Central Station Monitoring</li>
                                            <li>Over 95 years of experience</li>
                                            <li>A fully dedicated and inhouse Slomin's staff</li>
                                            <li>Life safety monitoring available</li>
                                        </ul>
                                        <a className="white_btn" href={`${constant.ALARAM_SITEURL}`}>Order Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12" data-aos="fade-up">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/LP-Module.jpg" alt="Special Offers"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>Free Equipment and Free Expert Installation <span>(Worth $1,145)</span></h4>
                                        <p>The free security system includes:</p>
                                        <ul>
                                            <li>Control Panel</li>
                                            <li>Motion Sensor</li>
                                            <li>3 Door/Window Contacts</li>
                                            <li>Lawn Signs and Window Decals</li>
                                        </ul>
                                        <a className="white_btn" href={`${constant.ALARAM_SITEURL}`}>Order Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12" data-aos="fade-left">
                                <div className="card">
                                    <div className="image-wrapper">
                                        <img src="../img/Offer-Free-Doorbell-Module-1.jpg" alt="Special Offers"/>
                                    </div>
                                    <div className="card-content">
                                        <h4>Save even more with a Free Doorbell Camera <span>(Worth $290)</span></h4>
                                        <ul>
                                            <li>HD Video Recording</li>
                                            <li>Motion Activated</li>
                                            <li>Access video from your phone</li>
                                            <li>Mobile alerts</li>
                                            <li>2-way audio</li>
                                        </ul>
                                        <a className="white_btn" href={`${constant.ALARAM_SITEURL}`}>Order Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="claim_form_blk">
                    <div className="claim_form_inr container">
                        <div className="row">
                            <div className="col-12" data-aos="fade-up">
                                <div className='claim_form_info'>
                                    <h2>Ready to Claim your Free Home Security System?</h2>
                                    <p>Order now or call <a href="tel:18334801010">833-480-1010</a> to schedule your free installation.</p>
                                    <a className="white_btn" href={`${constant.ALARAM_SITEURL}`}>Order Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main> 
        </>
    );
};

export default PPCShopSpecials;
