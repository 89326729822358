import React,{useEffect} from 'react'; 
import PageTitleComponent from '../../components/PageTitleComponent';
const License = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
      }, []);  
    return (
        <>
         <PageTitleComponent title={"License - Slomins"}/> 
            <main className="license">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center">
                            <h1>License</h1>
                        </div>
                    </div>
                </section>
                <div className="terms_content_blk">
                    <div className="container">
                        <div className="terms_content">
                            <h5>Home Security</h5>
                            <ul>
                                <li>California Alarm Co. Operator #AC07663 Qualifier Kevin Hecker ACQ7619</li>
                                <li>State of Connecticut Dept of Consumer Protection ELC. 0106209-L5</li>
                                <li>State of Delaware Delaware State Police 95-107</li>
                                <li>District of Columbia: # 39936122</li>
                                <li>Dept of Bus &amp; Professional Regulation ST of FL LIC#: EF20000397</li>
                                <li>State of Georgia LVA205715</li>
                                <li>State of Maryland Security System Agency License 107-1842</li>
                                <li>New Jersey Electrical Contractor Bus. Permit # 34BF00014900</li>
                                <li>Licensed by the NYS Dept. of State License # 12000021686</li>
                                <li>Pennsylvania: # PA004462</li>
                                <li>Licensed by the Commonwealth of VA. DCJS License # 11-3014</li>
                            </ul>
                            <h5>Home Heating/Cooling</h5>
                            <ul>
                                <li>Nassau County: # H2401960000</li>
                                <li>Suffolk County:&nbsp; # 21-5041HI</li>
                                <li>New York City:&nbsp; # 783079</li>
                                <li>New Jersey:&nbsp; # 130VH01245500</li>
                                <li>Pennsylvania # PA004462</li>
                                <li>Putnam County # PC5819</li>
                                <li>Rockland # P-1784PH</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default License;
