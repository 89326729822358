import BlogJSONData from '../components/blog/BlogData';
export const getBlogList = async (category: any, offset: any) => {  
  try {
    let lem: any = 0;
    let BlogArr: any = [];
    BlogJSONData.forEach((item, index) => {
      if (item.category === category) {  
        BlogArr[lem] = {
          title: item.title,
          subtitle: item.subtitle,
          frontImageUrl: item.frontImageUrl,
          category: item.category,
          description: item.description,
          slug: item.slug,
          date: item.date,
          content: item.content,
        };
        lem++;
      }
    });
    return {
      'total': lem,
      'data': BlogArr.slice((offset - 1) * 10, offset * 10),
    };
  } catch (err) {
    return { 'data': err };
  }
};





