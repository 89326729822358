import React, { useEffect } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import SliderComponents from "../../components/common/SliderComponents";
import { Link } from 'react-router-dom';
import PageTitleComponent from '../../components/PageTitleComponent';
import AOS from 'aos';
const CustomerReviews = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        AOS.init({
          disable: "phone",
          duration: 700,
          easing: "ease-out-cubic",
        });
      }, []); 
    return (
        <>
        <PageTitleComponent title={"Customer Reviews and Testimonials from Slomin's customers"} />
            <main className='customer_review_pg'>
                <section className="hero_blk customer_review_hero">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <h1>Slomin's Customers are our top Priority</h1>
                                <p>Every Slomin’s employee is dedicated to customer satisfaction.</p> 
                                {/* <Link className="white_btn" to={`/get-a-quote`}>Schedule free consultation</Link> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="customer_review_blk">
                    <div className="customer_review_inr container-xxl">
                        <div className="sec_title">
                            <h2>Read Customer Testimonials<br /> <span>Praising our Service</span></h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="customer_img">
                                    <img src="../img/Referral-Roseann-Module.jpg" alt="Referral Roseann" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="customer_review_content">
                                    <img src="../img/quote_icon.png" alt="Icon" />
                                    <p>Good Morning,</p>
                                    <p>I would like to take this opportunity to compliment one of your service technicians. We had an issue with our hot water and called to have someone take a look at it.</p>
                                    <p>Our technician, Craig, (service tech: 66) called us before he came to the house and was here at the time he specified.</p>
                                    <p>He was polite, friendly and professional. He fixed the issue in a timely matter and took the time to explain everything to us.</p>
                                    <p>I know you probably get a lot of complaints in your business so I just wanted you to know that we appreciated everything Craig did.</p>
                                    <div className="review_btm">
                                        <p>Best Regards</p>
                                        <h6>Roseann</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="customer_review_blk customer_review_black">
                    <div className="customer_review_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="customer_review_content">
                                    <img src="../img/quote_icon.png" alt="Icon" />
                                    <p>Hi…I had a service call today from your serviceman #627. It is too often that people like to complain about things and forget the good events. My experience with him (627) was everything you could expect and more. He was very professional fixing the problem and replacing the battery. He then spent time with me walking me through the keypad and changing my code. Slomins should be very happy to have this person working for them.</p>
                                    <div className="review_btm">
                                        <p>Thank you</p>
                                        <h6>Steve</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="customer_img">
                                    <img src="../img/Referral-Steve-Module.jpg" alt="Steve" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="customer_review_blk">
                    <div className="customer_review_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="customer_img">
                                    <img src="../img/Referral-3-Module.jpg" alt="Referral" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="customer_review_content">
                                    <img src="../img/quote_icon.png" alt="Icon" />
                                    <p>Thank you very much for the prompt & wonderful service given to me today. Just love being a customer of yours. Also the young ladies who answer your phone are most courteous & helpful & are to be commended. Again thanks.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SliderComponents />
                <SubscribeCompoents />
            </main>
        </>
    );
};

export default CustomerReviews;
