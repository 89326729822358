import React, { useState } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import { useEffect } from "react";
import PageTitleComponent from '../../components/PageTitleComponent';
const HomeSecurityCameras = () => {
    const [isVisible, setIsVisible] = useState({
        "p-remote-arming-disarm": true,
        "p-live-stream": false,
        "p-security-system": false
    });
    const toggleVisibility = (type) => {
        let typeCheck = {
            "p-remote-arming-disarm": false,
            "p-live-stream": false,
            "p-light-control": false
        }
        typeCheck[type] = true;
        setIsVisible(typeCheck);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>
            <PageTitleComponent title={"Home Security Cameras and Home Security System"} />
            <main className="home_security-camera_page">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content text-center hero_box_outer">
                            <div className='hero_box'>
                                <h1>Interact with your Home Security System Virtually</h1>
                                <p>The mySlomin's app elevates security to a whole new level of convenience.</p>
                                <Link className="white_btn" to={`/get-a-quote?aoi=11`}>Request a Quote</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_security-camera_manage">
                    <div className="home_security-camera_manage_inr container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="home_security-camera_manage_content">
                                    <h2>Watch your Security Camera Video Feed from anywhere.</h2>
                                    <p>Keep an eye on your kids and pets or just check on your home while you're away.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="home_security-camera_manage_img">
                                    <img src="../img/Remote-App-Animated-Screen-Carousel.gif" alt="Security Camera Video" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_automation-tabs-blk" data-aos="fade-up">
                    <div className="home_security-inr-sec container">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="v-pills-indoor-camera-tab" data-bs-toggle="pill" data-bs-target="#v-pills-indoor-camera" type="button" role="tab" aria-controls="v-pills-indoor-camera" aria-selected="true"><span className="p-2">Indoor Camera</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="v-pills-outdoor-camera-tab" data-bs-toggle="pill" data-bs-target="#v-pills-outdoor-camera" type="button" role="tab" aria-controls="v-pills-outdoor-camera" aria-selected="false"><span className="p-2">Outdoor Camera</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="v-pills-doorbell-camera-tab" data-bs-toggle="pill" data-bs-target="#v-pills-doorbell-camera" type="button" role="tab" aria-controls="v-pills-doorbell-camera" aria-selected="false"><span className="p-2">Doorbell Camera</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="v-pills-pan-and-tilt-camera-tab" data-bs-toggle="pill" data-bs-target="#v-pills-pan-and-tilt-camera" type="button" role="tab" aria-controls="v-pills-pan-and-tilt-camera" aria-selected="false"><span className="p-2">Pan and Tilt Camera</span></button>
                            </li>
                        </ul>
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-indoor-camera" role="tabpanel" aria-labelledby="v-pills-indoor-camera-tab">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="home_security-camera_manage_content">
                                            <h2>Indoor Camera</h2>
                                            <p>Observe activity in your home with live-stream video in high definition.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="home_security-camera_manage_img">
                                            <img src="../img/indoor-cam-img.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-outdoor-camera" role="tabpanel" aria-labelledby="v-pills-outdoor-camera-tab">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="home_security-camera_manage_content">
                                            <h2>Outdoor Camera</h2>
                                            <p>View your property with surveillance video from a weather-resistant outdoor cameras.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="home_security-camera_manage_img">
                                            <img src="../img/outdoor-cam-img.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-doorbell-camera" role="tabpanel" aria-labelledby="v-pills-doorbell-camera-tab">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="home_security-camera_manage_content">
                                            <h2>Doorbell Camera</h2>
                                            <p>Track visitors, packages, and activities at your door.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="home_security-camera_manage_img">
                                            <img src="../img/doorbell-camera-img.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-pan-and-tilt-camera" role="tabpanel" aria-labelledby="v-pills-pan-and-tilt-camera-tab">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="home_security-camera_manage_content">
                                            <h2>Pan and Tilt Camera</h2>
                                            <p>Scan a perimeter, tilt to different angles and zoom for a more advanced picture.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="home_security-camera_manage_img">
                                            <img src="../img/pan-tilt-img.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_security-camera_manage home_security_blk">
                    <div className="home_security-camera_manage_inr container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="home_security-camera_manage_content">
                                    <h2>Arm or Disarm your Home Security System.</h2>
                                    <p>If for any reason you need to remotely activate or deactivate your security alarm, the mySlomin's app provides easy access to your security system. All you need is an internet connection.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="home_security-camera_manage_img">
                                    <img src="../img/arm-disarm-img.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_mob-automation-tabs-blk" data-aos="fade-up">
                    <div className="home_automation-tab-inr container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="home_mob-automation-heading">
                                    <h2>Home Security at <span>your Fingers.</span></h2>
                                    <p>The mySlomin's app for iOS &amp; Android provides mobile control of your security system.</p>
                                    <a href="https://play.google.com/store/apps/collection/cluster?clp=igNJChkKEzUxNzMwMjcyOTE2NjUyNDQzNzEQCBgDEioKJGNvbS5zZWN1cmVuZXR0ZWNoLnNsb21pbnM2cHJvZHVjdGlvbhABGAMYAQ%3D%3D:S:ANO1ljJf4Iw&gsr=CkyKA0kKGQoTNTE3MzAyNzI5MTY2NTI0NDM3MRAIGAMSKgokY29tLnNlY3VyZW5ldHRlY2guc2xvbWluczZwcm9kdWN0aW9uEAEYAxgB:S:ANO1ljLGHzU&hl=en_US&gl=US"><img src="../img/app-store-badge.webp" alt="" /></a>
                                    <a href="https://apps.apple.com/us/developer/slomins-inc/id468595151"><img src="../img/google-play-badge.webp" alt="" /></a>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="d-flex align-items-start home_mob-tab">
                                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link active" id="v-pills-remote-arming-disarm-tab" data-bs-toggle="pill" data-bs-target="#v-pills-remote-arming-disarm" type="button" role="tab" aria-controls="v-pills-remote-arming-disarm" aria-selected="true" onClick={(e) => toggleVisibility('p-remote-arming-disarm')}>Remotely arm/disarm your security system</button>

                                        <button className="nav-link" id="v-pills-live-stream-tab" data-bs-toggle="pill" data-bs-target="#v-pills-live-stream" type="button" role="tab" aria-controls="v-pills-live-stream" aria-selected="false" onClick={(e) => toggleVisibility('p-live-stream')}>Live stream from all your cameras</button>

                                        <button className="nav-link" id="v-pills-security-system-tab" data-bs-toggle="pill" data-bs-target="#v-pills-security-system" type="button" role="tab" aria-controls="v-pills-security-system" aria-selected="false" onClick={(e) => toggleVisibility('p-security-system')} >Manage every aspect of your security system</button>
                                    </div>
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade show active" id="v-pills-remote-arming-disarm" role="tabpanel" aria-labelledby="v-pills-remote-arming-disarm-tab"><img src="../img/remote-arm-disarm-img.webp" alt="" /></div>
                                        <div className="tab-pane fade" id="v-pills-live-stream" role="tabpanel" aria-labelledby="v-pills-live-stream-tab"><img src="../img/live-stream-img.png" alt="" /></div>
                                        <div className="tab-pane fade" id="v-pills-security-system" role="tabpanel" aria-labelledby="v-pills-security-system-tab"><img src="../img/security-system-img.png" alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-slomins-support" data-aos="fade-up">
                    <div className="home-slomins-support-inr text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section_heading text-center">
                                        <h2>
                                            Slomin's Support is <span>Available 24/7</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row our-offer-items">
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-cutomer-service-icon.svg" alt="" />
                                        <h4>Customer Service</h4>
                                        <p>Connect with customer service for help. Visit the <Link to={`/contact-us`}>Support page</Link> for details.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-slomins-app-icon.svg" alt="" />
                                        <h4>mySlomin's App</h4>
                                        <p><Link to={`/home-security/home-security-cameras`}>Manage</Link> your system and account directly from your mobile device.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <img src="../img/alm-trained-tech-icon.svg" alt="" />
                                        <h4>Trained Technicians</h4>
                                        <p>Slomin's technicians are available 24/7. <Link to={`/contact-us`}>Schedule</Link> a visit now.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SubscribeCompoents />
            </main>


        </>
    );
};

export default HomeSecurityCameras;
