import React, { useState, useEffect, useCallback } from 'react';
import $ from 'jquery'; // Import jQuery
import { Link } from 'react-router-dom'
import { constant } from '@app/utils/constant';
const Footer = () => {
  const todayDate = new Date();
  const [currentYear, setCurrentYear] = useState(todayDate.getFullYear());

  useEffect(() => {
    const copyrightElement = document.querySelector('.copyright_blk p');
    if (copyrightElement) {
      copyrightElement.innerHTML = `&copy; ${currentYear} Slomin's® Inc. All Rights Reserved.`;
    }
  }, [currentYear]);

  useEffect(() => {
    const handleFooterMenuClick = (event) => {
      if (window.innerWidth < 768) {
        const $footerMenuTitle = $(event.currentTarget);
        const $footerMenu = $footerMenuTitle.next('.footer_menu');
        const $footerMenuBlk = $footerMenuTitle.closest('.footer_menu_blk');
        const isOpen = $footerMenuBlk.hasClass('open_footer');
        $('.footer_menu').stop().slideUp();
        $($footerMenu).stop().slideToggle(); // Wrap with $() to use jQuery methods 
        if (!isOpen) {
          $('.footer_menu_blk').removeClass('open_footer');
          $footerMenuBlk.addClass('open_footer');
        } else {
          $footerMenuBlk.removeClass('open_footer');
        }
      }
    };
    $('.footer_menu_title').on('click', handleFooterMenuClick);
    return () => {
      $('.footer_menu_title').off('click', handleFooterMenuClick);
    };
  }, []);

  const handleClick = () => {
    // Scroll the page to the top
    window.scrollTo(0, 0);
  } 
  return (
<footer className="footer slomins-spotify-footer">
  <div className='container'>
    <div className='row'>
      <div className="col-12 col-md-12 col-lg-12 footer_social_link_wrap">
    <ul>
    <li><a href="https://www.facebook.com/slomins" target="_blank" rel="noopener noreferrer" aria-label="Facebook" title="Facebook">
    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="facebook-logo">
    <path id="Path" d="M10.388 0.00416134L7.79888 0C4.89011 0 3.01034 1.9319 3.01034 4.92203V7.19141H0.407111C0.182162 7.19141 0 7.37409 0 7.59943V10.8875C0 11.1128 0.182369 11.2953 0.407111 11.2953H3.01034V19.5922C3.01034 19.8175 3.1925 20 3.41745 20H6.81392C7.03887 20 7.22103 19.8173 7.22103 19.5922V11.2953H10.2648C10.4898 11.2953 10.6719 11.1128 10.6719 10.8875L10.6732 7.59943C10.6732 7.49123 10.6302 7.38762 10.5539 7.31105C10.4777 7.23448 10.3739 7.19141 10.2659 7.19141H7.22103V5.26763C7.22103 4.34298 7.441 3.87358 8.64343 3.87358L10.3876 3.87295C10.6123 3.87295 10.7945 3.69027 10.7945 3.46514V0.411972C10.7945 0.187052 10.6125 0.00457747 10.388 0.00416134Z" fill="white"></path>
    </g>
    </svg>
    </a></li>
    <li><a href="https://www.instagram.com/slomins.inc/" target="_blank" rel="noopener noreferrer" aria-label="Instagram" title="Instagram">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="instagram">
    <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M13.75 0H6.25C2.79875 0 0 2.79875 0 6.25V13.75C0 17.2013 2.79875 20 6.25 20H13.75C17.2013 20 20 17.2013 20 13.75V6.25C20 2.79875 17.2013 0 13.75 0ZM5.09091 10.1821C5.09091 7.3706 7.37037 5.09115 10.1818 5.09115C12.9933 5.09115 15.2727 7.3706 15.2727 10.1821C15.2727 12.9935 12.9933 15.273 10.1818 15.273C7.37037 15.273 5.09091 12.9935 5.09091 10.1821ZM6.99995 10.1817C6.99995 11.9356 8.42795 13.3636 10.1818 13.3636C11.9356 13.3636 13.3636 11.9356 13.3636 10.1817C13.3636 8.42666 11.9356 6.99993 10.1818 6.99993C8.42795 6.99993 6.99995 8.42666 6.99995 10.1817ZM16 4.72699C16 5.12866 15.6744 5.45427 15.2727 5.45427C14.871 5.45427 14.5454 5.12866 14.5454 4.72699C14.5454 4.32533 14.871 3.99972 15.2727 3.99972C15.6744 3.99972 16 4.32533 16 4.72699Z" fill="white"></path>
    </g>
    </svg>
    </a></li>
    <li><a href="https://twitter.com/Slomins" target="_blank" rel="noopener noreferrer" aria-label="Twitter" title="Twitter">
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="twitter">
    <path id="Path" d="M7.93615 18C7.01625 18 6.06941 17.923 5.11689 17.7686C3.43923 17.4965 1.74096 16.5892 1.2604 16.3175L0 15.6051L1.36459 15.1411C2.85626 14.6341 3.76366 14.3195 4.88692 13.8268C3.7622 13.2637 2.89538 12.2507 2.47811 10.9413L2.16066 9.94492L2.42098 9.98602C2.17413 9.7288 1.97921 9.46771 1.82811 9.23028C1.29075 8.38645 1.00624 7.35536 1.06678 6.47176L1.1265 5.60209L1.63011 5.80378C1.41766 5.38899 1.26413 4.94602 1.17389 4.48173C0.953981 3.34946 1.13803 2.14655 1.69227 1.09466L2.13096 0.262068L2.71766 0.990627C4.5732 3.2951 6.92342 4.66211 9.71281 5.0623C9.59888 4.25035 9.68425 3.46675 9.96681 2.76437C10.2958 1.94655 10.881 1.25306 11.6588 0.758736C12.5225 0.209885 13.57 -0.0560678 14.6082 0.00987499C15.7097 0.0798448 16.7098 0.506543 17.504 1.24467C17.8919 1.14047 18.1777 1.02922 18.564 0.878876C18.7964 0.788436 19.06 0.685746 19.3898 0.568291L20.605 0.135384L19.8125 2.47543C19.8648 2.4709 19.9187 2.46704 19.975 2.46452L21.2727 2.40378L20.5057 3.48756C20.4617 3.54964 20.4505 3.56726 20.435 3.59159C20.3731 3.6879 20.2962 3.80771 19.2434 5.26113C18.9798 5.62508 18.8482 6.09909 18.8727 6.5961C18.966 8.48478 18.7419 10.1936 18.2061 11.6749C17.6994 13.0763 16.9142 14.2787 15.8726 15.2487C14.5837 16.4488 12.9401 17.2704 10.9873 17.6906C10.0295 17.8966 9.00082 18 7.93615 18Z" fill="white"></path>
    </g>
    </svg>
    </a></li>
    <li><a href="https://www.youtube.com/c/Slominsshield" target="_blank" rel="noopener noreferrer" aria-label="Youtube" title="Youtube">
    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.184 0.138C21.6225 0.219 22.329 0.432 22.98 1.59C23.658 2.7465 24 4.7385 24 8.247V8.2515V8.259C24 11.751 23.658 13.758 22.9815 14.9025C22.3305 16.0605 21.624 16.2705 20.1855 16.368C18.747 16.4505 15.1335 16.5 12.003 16.5C8.8665 16.5 5.2515 16.4505 3.8145 16.3665C2.379 16.269 1.6725 16.059 1.0155 14.901C0.345 13.7565 0 11.7495 0 8.2575V8.2545V8.25V8.2455C0 4.7385 0.345 2.7465 1.0155 1.59C1.6725 0.4305 2.3805 0.219 3.816 0.1365C5.2515 0.0405 8.8665 0 12.003 0C15.1335 0 18.747 0.0405 20.184 0.138ZM16.5002 8.2506L9.00021 3.7506V12.7506L16.5002 8.2506Z" fill="white"></path>
    </svg>
    </a></li>
    </ul>
      </div>
      <div className="col-12 col-md-12 col-lg-12 ft_legal_copy">
      <p className="text-white">Free system is available for new residential customers only, renters program available. System includes control panel, keypad, motion detector, up to 3 door contacts, doorbell camera, window decals, lawn sign, cellular communication, and battery backup.  Five year monitoring agreement required at $51.95 per month.  Additional items and features are available at an additional cost. All new customers are subject to credit approval.</p>
      <p className="text-white">
      Licensed by the NYS Dept of State License #12000021686, CA #AC07663, CT #00106209, NJ #34BA00088000, PA #097882, DE #95-107,     
      MD #107548, DC #39936122, VA DCJS #11-3014, GA #LVA205715, FL #EF20000397ACO7663</p>
      </div>
    </div>
  </div>
</footer>
  );
};

export default Footer;
