import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import SliderComponents from "../../components/common/SliderComponents";
import SeedProdPageForm  from "../../components/common/SeedProdPageForm";
const FreeDoorbellCamera = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>            
            <main className="free_doorbell_camera_pg">
                <section className="hero_blk landing_pg_hero">
                    <div className="hero_inr container-xxl">
                        <div className="row align-items-center">
                            <div className="hero_content text-left col-lg-6 col-md-6 col-12 hero_box_outer">
                                <div className='hero_box'>
                                    <span className="note-sec">Limited Time Offer</span>
                                    <h1><span>Act now & get a</span> Free Doorbell Camera</h1>                                    
                                    <p>With your Slomin's Monitoring Package.</p>
                                    <a href='#GetStartedForm' className="white_btn">Get Started Now</a>
                                    <span className='sml_txt'>*$300.00 Value! | *Professionally installed, at no extra cost</span>
                                </div>
                            </div>
                            <div className="hero_content_img text-left col-lg-6 col-md-6 col-12">
                                <div className='hr_img_blk'>
                                    <img src="../img/doorbell-camera.png" alt="Products"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home_safety_blk" data-aos="fade-up">
                    <div className="home_safety_inr text-center">
                        <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sec_title">
                                    <h2>Choose Slomin's to be your <span>Home Safety Provider</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row our-offer-items">
                            <div className="col-md-4 col-sm-6">
                                <div className="slm-item">
                                    <img src="../img/home-icon.svg" alt="icon"/>
                                    <h4>Security</h4>
                                    <p>24/7/365 monitoring of your home security system. We alert the authorities if your alarm is triggered.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="slm-item">
                                    <img src="../img/assistent.svg" alt="icon"/>
                                    <h4>Environment</h4>
                                    <p>Monitor the environment in your home. We offer options for fire / smoke / CO2 / temperature / flood.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="slm-item">
                                    <img src="../img/availablity.svg" alt="icon"/>
                                    <h4>Surveillance</h4>
                                    <p>Our video surveillance options offer the ability to remotely view and monitor your home from anywhere.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>

                    <section className="your_door_blk">
                        <div className="your_door_inr container">
                            <div className="row justify-content-center">
                                <div className="col-12" data-aos="fade-up">
                                    <div className="sec_title">
                                        <h2>Always know who’s at <span>your door</span></h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center align-items-center surveillance_blk">
                                    <div className="col-12 col-md-6" data-aos="fade-right">
                                        <div className="your_door_content">
                                            <h3>Surveillance</h3>
                                            <p>The Slomin's FREE doorbell camera is a must-have for your home security system, and allows you to keep an eye on your front door—even when you're not around.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" data-aos="fade-left">
                                        <div className="your_door_img">
                                            <img src="../img/surveilllance.jpg" alt="Surveilllance"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center align-items-center audio_video_blk">
                                    <div className="col-12 col-md-6" data-aos="fade-right">
                                        <div className="your_door_img">
                                            <img src="../img/audio-video.jpg" alt="Audio Video"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" data-aos="fade-left">
                                        <div className="your_door_content">
                                            <h3>Complete Audio & Video</h3>
                                            <p>With two-way voice and 1080 HD video you'll be able to see and hear anything at your door right from your mobile device.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center align-items-center compatability_blk">
                                    <div className="col-12 col-md-6" data-aos="fade-right">
                                        <div className="your_door_content">
                                            <h3>Mobile Compatability</h3>
                                            <p>Watch live footage from your video doorbell and control other smart security equipment directly from your phone or tablet with the MySlomin's App.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6" data-aos="fade-left">
                                        <div className="your_door_img">
                                            <img src="../img/mobile-compatibility.jpg" alt="Mobile Compatibility"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="our_services_plans" data-aos="fade-up">
                        <div className="container">
                            <div className="row text-center justify-content-center">
                                <div className="col-md-12">
                                    <div className='sec_title'>
                                        <h2>The Slomin's Shield® <span>Essential Components</span></h2>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src="../img/Product-Listing-Control-Panel-Keypad-Product-Image.jpg" alt="Control Panel Keypad"/>
                                        </div>
                                        <div className="card-content">
                                            <h4>Control Panel</h4>
                                            <p>The control panel manages all parts of your home’s security and connects to our central monitoring station.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src="../img/Product-Listing-Door-Window-Contact-Product-Image.jpg" alt="Door Window Contact"/>
                                        </div>
                                        <div className="card-content">
                                            <h4>Door & Window Contacts</h4>
                                            <p>Our contacts instantly notify you when someone enters through a window or door whether you’re at home or away.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src="../img/Product-Listing-Motion-Sensor-Product-Image.jpg" alt="Motion Sensor"/>
                                        </div>
                                        <div className="card-content">
                                            <h4>Motion Sensor</h4>
                                            <p>Motion sensors add a layer of protection to your home’s vast areas, expansive rooms, hallways, and stairwells.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src="../img/Product-Listing-Slomin-Decal-Product-Image.jpg" alt="Slomin Decal"/>
                                        </div>
                                        <div className="card-content">
                                            <h4>Lawn & Window Decals</h4>
                                            <p>Slomin’s lawn signs and window decals ward off any potential intruders.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 more_btn_blk">
                                    <Link className="red_btn" to={`/home-security-products`}>See More Products</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <SliderComponents />
                    <section className="faq_blk" data-aos="fade-up">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sec_title">
                                        <h2>Frequently Asked<span> Questions</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Who do I call if I need assistance with my alarm system?</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Please call our office at 1-800-ALARM-ME for all alarm system-related assistance, inquiries, or if you're concerned with a technician's status. We are always happy to help you.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">How long does an installation take?</button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Our basic alarm system installations typically take 3-4 hours. It's also important to note additional factors such as the size of your home, equipment type, and purchase specifics will determine exactly how long the install will take. Most of our alarm system installations are complete within 1 day.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Can I view the cameras off site?</button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                            <p>Yes. The MySlomin's App showcases all of your home camera's live-stream activity from anywhere with internet connection to providide direct, hassle-free access from your mobile device or tablet.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="claim_form_blk" id='GetStartedForm'>
                        <div className="claim_form_inr container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-4" data-aos="fade-right">
                                    <div className='claim_form_info'>
                                        <h2>Get Started Now!</h2>
                                        <p>Complete the form and a Slomin's representative will contact you soon to customize a system that's best for your home. Or, skip the form and give us a call at <a href="tel:18002527663">800-252-7663</a>.</p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-8" data-aos="fade-left">
                                <SeedProdPageForm /> 
                                    {/* <form>
                                        <div className="row">
                                            <div className='claim_form'>
                                            <div className="col-md-12 col-12">
                                                <input type="text" className="form-control" id="full-name" name="full-name" placeholder="Full Name*" required/>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <input type="text" className="form-control" id="email" name="email" placeholder="Email*" required/>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <input type="text" className="form-control" id="phone-number" name="phone-number" placeholder="Phone Number*" required/>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <input type="text" className="form-control" id="address" name="address" placeholder="Address*" required/>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <input type="text" className="form-control" id="zip" name="zip" placeholder="Zip*" required/>
                                            </div>                                        
                                            <div className="col-md-12 col-xl-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <select className="form-select" name="preferred_date" id="aptdate">
                                                        <option value="">Preferred Date</option>
                                                        <option value="2024-5-15">Wednesday, May 15</option>
                                                        <option value="2024-5-16">Thursday, May 16</option>
                                                        <option value="2024-5-17">Friday, May 17</option>
                                                        <option value="2024-5-18">Saturday, May 18</option>
                                                        <option value="2024-5-20">Monday, May 20</option>
                                                        <option value="2024-5-21">Tuesday, May 21</option>
                                                        <option value="2024-5-22">Wednesday, May 22</option>
                                                        <option value="2024-5-23">Thursday, May 23</option>
                                                        <option value="2024-5-24">Friday, May 24</option>
                                                        <option value="2024-5-25">Saturday, May 25</option>
                                                    </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select className="form-select" name="preferred_time" id="apttime">
                                                            <option value="" disabled>Preferred Time</option>
                                                            <option value="09:00">09:00 AM</option>
                                                            <option value="09:30">09:30 AM</option>
                                                            <option value="10:00">10:00 AM</option>
                                                            <option value="10:30">10:30 AM</option>
                                                            <option value="11:00">11:00 AM</option>
                                                            <option value="11:30">11:30 AM</option>
                                                            <option value="12:00">12:00 PM</option>
                                                            <option value="12:30">12:30 PM</option>
                                                            <option value="13:00">01:00 PM</option>
                                                            <option value="13:30">01:30 PM</option>
                                                            <option value="14:00">02:00 PM</option>
                                                            <option value="14:30">02:30 PM</option>
                                                            <option value="15:00">03:00 PM</option>
                                                            <option value="15:30">03:30 PM</option>
                                                            <option value="16:00">04:00 PM</option>
                                                            <option value="16:30">04:30 PM</option>
                                                            <option value="17:00">05:00 PM</option>
                                                            <option value="17:30">05:30 PM</option>
                                                            <option value="18:00">06:00 PM</option>
                                                            <option value="18:30">06:30 PM</option>
                                                            <option value="19:00">07:00 PM</option>
                                                            <option value="19:30">07:30 PM</option>
                                                            <option value="20:00">08:00 PM</option>
                                                            <option value="20:30">08:30 PM</option>
                                                            <option value="21:00">09:00 PM</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="red_btn">Submit Your Request</button>
                                            </div>
                                        </div>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                    </section>
            </main> 
        </>
    );
};

export default FreeDoorbellCamera;
