import React from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom'
function PaginatedItems({ itemsPerPage, totalItem }) {
  const navigate = useNavigate();
  const pageCount = Math.ceil(totalItem / itemsPerPage);
  const handlePageChange = (e) => {
    window.scrollTo(0, 0);
    navigate('/blog/page/' + (parseInt(e.selected) + 1));
  }
  return (
    <>
      {
        pageCount > 1 ?
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
          />
          : ""
      }
    </>
  );
}
export default PaginatedItems;


