import React, { useEffect } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import SliderComponents from "../../components/common/SliderComponents";
import { constant } from '@app/utils/constant';
import AOS from 'aos';
import PageTitleComponent from '../../components/PageTitleComponent';
const ReferallProgram = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>
          <PageTitleComponent title={"Slomin’s Referral Program"} />
            <main className="referral-program-page"> 
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <h1>Share your Shield!</h1>
                                <p>Caring about the safety and comfort of your friends, family and colleagues has never been more rewarding!</p>
                                <a href={`${constant.MYA_SITEURL}`} className="white_btn">Start Referral</a>
                            </div>
                        </div>
                    </div>
                </section> 
                <section className="referral-program-save-blk">
                    <div className="join-referral-program_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12" data-aos="fade-up">
                                <div className="join-referral-program_heading">
                                    <h2>Join the Slomin's <span>Referral Program</span> to Save</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center odd">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="join-referral-program_content">
                                    <div className="step">Step 1</div>
                                    <h2>Share your Slomin's Experience</h2>
                                    <p>What Slomin's benefits would your friends or colleagues appreciate most? Is it the peace of mind that comes with a safe and secure alarm system? The mobile convenience of home automation? Or the mid-winter comfort of a heated house with reliable oil delivery?</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="join-referral-program_img">
                                    <img src="../img/slomin-experience.png" alt="got covered" />
                                </div>
                            </div>
                        </div>                       
                    </div>
                </section>
                <section className="referral-program-save-blk ref_grey_bg">
                    <div className="join-referral-program_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="join-referral-program_img">
                                    <img src="../img/refer-friend-img.png" alt="got covered" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="join-referral-program_content">
                                    <div className="step">Step 2</div>
                                    <h2>Refer a Friend</h2>
                                    <ol>
                                        <li>Log into your MySlomins account portal.</li>
                                        <li>Select “Refer a Friend” above the red payment button.</li>
                                        <li>Fill out your Referral Bonus form and click “Submit”.</li>
                                    </ol>
                                    <p>Share your experience on social media and text or email your referral link to friends.</p>
                                    <div className='btn_blk'>
                                        <a href={`${constant.MYA_SITEURL}`} className="white_btn">MySlomins Account</a>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                    </div>
                </section>
                <section className="referral-program-save-blk">
                    <div className="join-referral-program_inr container-xxl">
                        <div className="row justify-content-center odd">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="join-referral-program_content">
                                    <div className="step">Step 3</div>
                                    <h2>Confirm your Referral for Processing</h2>
                                    <p>Your referral processing can start as soon as the installation is finished. Please give us a call mentioning the new Slomin's service account as a direct referral—full names included—and we'll honor it.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="join-referral-program_img">
                                    <img src="../img/referall-processing-img.png" alt="got covered" />
                                </div>
                            </div>
                        </div>                       
                    </div>
                </section>
                <section className="referral-program-save-blk ref_grey_bg">
                    <div className="join-referral-program_inr container-xxl">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6" data-aos="fade-right">
                                <div className="join-referral-program_img">
                                    <img src="../img/get-rewards-img.png" alt="got covered" />
                                </div>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <div className="join-referral-program_content">
                                    <div className="step">Step 4</div>
                                    <h2>Get your Rewards</h2>
                                    <p>Once processed, we'll take over from there and award your bonus credit directly into your Slomin's account. Cha-ching!</p>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </section> 
                <SliderComponents tagValue={"ReferallPage"} /> 
                <section className="home-slomins-support">
                    <div className="home-slomins-support-inr text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section_heading text-center">
                                        <h2>More Referrals mean<span> more Rewards</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row our-offer-items">
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <div className='ref_icon'>
                                            <img src="../img/security-system-customer-icon.svg" alt="icon" />
                                        </div>
                                        <h4>For each new Security System Customer</h4>
                                        <p>Receive 3 months free alarm monitoring.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <div className='ref_icon'>
                                            <img src="../img/oil-delivery-customer-icon.svg" alt="icon" />
                                        </div>
                                        <h4>For each new Oil Delivery Customer</h4>
                                        <p>Receive a $50.00 credit applied to your heating account.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 equal-height">
                                    <div className="_slm-item">
                                        <div className='ref_icon'>
                                            <img src="../img/multiple-product-referrals-icon.svg" alt="icon" />
                                        </div>
                                        <h4>For Multiple Product Referrals</h4>
                                        <p>Receive a $75.00 credit applied to your account OR 3 months free alarm monitoring.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>   
                <SubscribeCompoents /> 
            </main>
        </>
    );
};

export default ReferallProgram;
