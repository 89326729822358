import React, { useEffect } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import PageTitleComponent from '../../components/PageTitleComponent';
import AOS from 'aos';
const ThankYou = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        AOS.init({
          disable: "phone",
          duration: 700,
          easing: "ease-out-cubic",
        });
      }, []);

    return (
        <>
        <PageTitleComponent title={"Privacy Policy - Slomins"}/> 
            <main className='thank_you_page'>
                <section className="thank_you_blk">
                    <div className="container-xxl">
                        <div className="thank_you_content" data-aos="fade-up">
                            <h1>Thank you for your interest in our products and services!</h1>
                            <img src="../img/thank-you-img.png" alt="Thank you" />
                            <p>A representative will be in contact with you shortly. If you have any questions prior to meeting with the representative, you can contact us at <a href='tel:18002527663'>1-800-252-7663</a>.</p>
                        </div>
                    </div>
                </section>
                {/* subscribe block section starts here */}
                <SubscribeCompoents />
                {/* subscribe block section ends here */}
            </main>
        </>
    );
};

export default ThankYou;
