import React, { useState, useEffect, useContext } from 'react';
import { constant } from '@app/utils/constant';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import AccordionContext from "react-bootstrap/AccordionContext";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import RingNotificationBar from '@app/components/common/RingNotificationBar';
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [timer, setTimer] = useState(0); 
  const checkScrlPosition = () => {
    const sDistance = window.scrollY;
    const headerElement = document.querySelector('.header');
    if (headerElement) {
      if (sDistance > 50) {
        headerElement.classList.add('stickyHeader');
        setIsSticky(true);
      } else {
        headerElement.classList.remove('stickyHeader');
        setIsSticky(false);
      }
    }
  };
  const handleScroll = () => {
    if (!timer) {
      setTimer(
        setTimeout(() => {
          checkScrlPosition();
          setTimer(0);
        }, 250)
      );
    }
  }; 
  useEffect(() => {
    const handleScrollThrottled = () => handleScroll();
    window.addEventListener('scroll', handleScrollThrottled);
    return () => {
      window.removeEventListener('scroll', handleScrollThrottled);
    };
  }, [timer]);
  useEffect(() => {
    handleScroll(); 
  }, []); 
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null); 
  const [divHeight, setDivHeight] = useState(0);
  const [removeDivHeight, setRemoveDivHeight] = useState(true);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }; 
  const toggleSubMenu = (event) => {
    const button = event.currentTarget;
    const parentLi = button.parentElement;
    const subMenuWrap = button.nextElementSibling;  
    document.querySelectorAll('.sub_menu_wrap').forEach((submenu) => {
      if (submenu !== subMenuWrap) {
        submenu.style.display = 'none';
      }
    }); 
    subMenuWrap.style.display = subMenuWrap.style.display === 'block' ? 'none' : 'block';  
    button.classList.toggle('open_sub');  
    if (activeMenu !== parentLi) {
      if (activeMenu) activeMenu.classList.remove('active_menu');
      parentLi.classList.add('active_menu');
      setActiveMenu(parentLi);
    } else {
      parentLi.classList.remove('active_menu');
      setActiveMenu(null);
    } 
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
  const handleClick = () => { 
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
    setIsSideMenuOpen(false);
  } 
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false); 
  const handleToggleSideMenu = (e) => { 
    e.stopPropagation();
    setIsSideMenuOpen((prev) => !prev);
  }; 
  const handleDocumentClick = (event) => {
    const container = document.getElementById('sideMenuOilLanding');
    if (!container.contains(event.target)) {
      setIsSideMenuOpen(false);
    }
  }; 
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);  
function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );  
    const isCurrentEventKey = currentEventKey === eventKey;  
    return (
      <a href='#' type="button" className={ isCurrentEventKey ? 'openMenu' : '' } onClick={decoratedOnClick}>
        {children}
      </a>
    );
  }   
  const style = removeDivHeight ? { top: divHeight} : null;
  return (
    <>  
      <RingNotificationBar setDivHeight={setDivHeight} setRemoveDivHeight={setRemoveDivHeight} />
      <header className={`header ${isSticky ? 'stickyHeader' : ''}`} style={style}>
      <div className  ="container-fluid">
        <div className="header_inner row">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
                <div className="logo_wrap">
                    <a href="/"><img src="../../img/Slomins-Logo-New.svg" alt="Slomin's" /></a>
                </div>
            </div>            
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
                <div className="cart_toggle_menu_wrap text-right">
                    <div className="cart_menu_inner header_nav">  
                        <ul>
                            <li className="ph_number cir_btn"><a href="tel:18667566467" className='btn' title='Tel:18667566467'></a></li>
                            <li className="my_acc_btn cir_btn"><Button variant="btn" onClick={handleShow} title='My Account'></Button></li>
                            <li className="toggle_menu_btn" onClick={handleToggleSideMenu}>
                                <button className={classNames('hamburger_menu', { 'open_menu': isSideMenuOpen })}>
                                    <img className="menu_icon" src="../../img/toggle-menu-icon.svg" alt="hamburger" />
                                    <img className="close_icon" src="../../img/menu-close.png" alt="close" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={classNames('menu_wrap', { 'open_right_menu': isSideMenuOpen })} id="sideMenuOilLanding">
              <div className="menu_inner">
                  <div className="menu_logo_wrap">
                      <img src="../../img/Slomins-Logo-New.svg" alt="Slomin's" />
                  </div>
                  <div className="menu_phone_wrap">
                      <a href="tel:18667566467">1-866-756-6467</a>
                  </div>
                  <div className="menu_item_wrap">
                      <div className="menu_item_inner">
                      <Accordion> 
                        <ul className="components">
                          <li className="menu-item-1">
                            {/*<Accordion.Toggle as={Link} variant="link" eventKey="0">Home Security</Accordion.Toggle>*/}
                            <ContextAwareToggle eventKey="0">Home Security</ContextAwareToggle>
                            <span className="toggle-icon"></span>
                            <Accordion.Collapse eventKey="0"><>
                              <ul className="sub-menu">
                                  <li><a href={`${constant.ALARAM_SITEURL}`}>Overview</a></li>
                                  {/*
                                  <li onClick={handleClick}><Link to={"./home-security-products"}>Products</Link></li>
                                  <li onClick={handleClick}><Link to={"./home-security/home-automation"}>Home Automation</Link></li>
                                  <li onClick={handleClick}><Link to={"./home-security/home-security-cameras"}>Remote Control</Link></li>
                                  <li onClick={handleClick}><Link to={"./professional-monitoring"}>Professional Monitoring</Link></li>
                                  <li onClick={handleClick}><Link to={"./why-choose-the-slomins-shield"}>Why Us</Link></li> 
                                  */}
                              </ul>
                            </></Accordion.Collapse>
                          </li>
                          <li className="menu-item-1">
                            {/*<Accordion.Toggle as={Link} variant="link" eventKey="1">Cooling</Accordion.Toggle>*/}
                            <ContextAwareToggle eventKey="1">Cooling</ContextAwareToggle>
                            <span className="toggle-icon"></span>
                            <Accordion.Collapse eventKey="1"><>
                              <ul className="sub-menu">
                                  <li><a href={`${constant.AC_SITEURL}`} >Overview</a></li>
                                  <li><a href={`${constant.AC_SITEURL}#delivery_process`}>Installation</a></li>
                                  <li><a href={`${constant.AC_SITEURL}#our_serviceplans`}>Replacements</a></li>
                                  <li><a href={`${constant.AC_SITEURL}#our_serviceplans`}>Service &amp; Repair</a></li>
                                  <li><a href={`${constant.AC_SITEURL}#appointment`}>Get a Quote</a></li>
                              </ul>
                            </></Accordion.Collapse>
                          </li>
                          <li className="menu-item-1">
                            {/*<Accordion.Toggle as={Link} variant="link" eventKey="2">Heating</Accordion.Toggle>*/}
                            <ContextAwareToggle eventKey="2">Heating</ContextAwareToggle>
                            <span className="toggle-icon"></span>
                            <Accordion.Collapse eventKey="2"><>
                              <ul className="sub-menu">
                                  <li><a href={`${constant.OIL_SITEURL}#heating_overview`}>Overview</a></li>
                                  <li><a href={`${constant.OIL_SITEURL}#delivery_process`}>Heating Oil Delivery</a></li>
                                  <li><a href={`${constant.OIL_SITEURL}#equipment_installation`}>Equipment Installation</a></li>
                                  <li><a href={`${constant.OIL_SITEURL}#our_service`}>Service Contract</a></li>
                                  <li><a href={`${constant.OIL_SITEURL}#our_service`}>Service &amp; Repair</a></li>
                                  <li><a href={`${constant.OIL_SITEURL}#appointment`}>Get a Quote</a></li>
                              </ul>
                            </></Accordion.Collapse>
                          </li>
                          <li className="menu-item-1">
                            {/*<Accordion.Toggle as={Link} variant="link" eventKey="3">For Business</Accordion.Toggle>*/}
                            <ContextAwareToggle eventKey="3">For Business</ContextAwareToggle>
                            <span className="toggle-icon"></span>
                            <Accordion.Collapse eventKey="3"><>
                              <ul className="sub-menu">
                                  <li onClick={handleClick}><Link to={"/for-business"}>Overview</Link></li>
                                  <li><a href={`${constant.ALARAM_SITEURL}/for-business/security-system`}>Security System</a></li>
                                  <li><a href={`${constant.AC_SITEURL}/for-business/cooling`}>Cooling</a></li>
                                  <li><a href={`${constant.OIL_SITEURL}/for-business/heating`}>Heating</a></li>
                              </ul>
                            </></Accordion.Collapse>
                          </li>
                          <li className="menu-item-1">
                            {/*<Accordion.Toggle as={Link} variant="link" eventKey="4">About</Accordion.Toggle>*/}
                            <ContextAwareToggle eventKey="4">About</ContextAwareToggle>
                            <span className="toggle-icon"></span>
                            <Accordion.Collapse eventKey="4"><>
                                <ul className="sub-menu">
                                  <li onClick={handleClick}><Link to={`/about-us`}>Overview</Link></li>
                                  <li onClick={handleClick}><Link to={`/locations`}>Locations</Link></li>
                                  <li><a target="_blank" href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?ccId=1728567304_3745&cid=9f690535-b097-49a1-ac9d-0e3071b27ea3">Careers</a></li>
                                  <li onClick={handleClick}><Link to={`/customer-reviews`}>Reviews</Link></li>
                                  <li onClick={handleClick}><Link to={`/referral-program`}>Referral Program</Link></li>
                                  <li onClick={handleClick}><Link to={`/blog`}>Blog</Link></li>
                                </ul>
                            </></Accordion.Collapse>
                          </li>
                          <li className="menu-item-1">
                            {/*<Accordion.Toggle as={Link} variant="link" eventKey="5">Support</Accordion.Toggle>*/}
                            <ContextAwareToggle eventKey="5">Support</ContextAwareToggle>
                            <span className="toggle-icon"></span>
                            <Accordion.Collapse eventKey="5"><>
                                <ul className="sub-menu">
                                  <li onClick={handleClick}><Link to={`/faq`}>FAQ</Link></li>
                                  <li onClick={handleClick}><Link to={`/contact-us`}>Contact Us</Link></li>
                                  {/*<li><Link to={`/get-a-quote`}>Get a Quote</Link></li>*/}
                                </ul>
                            </></Accordion.Collapse>
                          </li>
                        </ul>
                      </Accordion>
                      </div>
                  </div>
              </div>
          </div>
          
        </div>
    </div>
      </header>
      <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Login to Your Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="account_popup_wrap">
            <div className="account_popup_inr">
              <div className="account_img_blk">
                <div className="image_wrapper">
                  <img src="../img/About-Us-Timeline-Today.jpg" alt="account" />
                </div>
              </div>
              <div className="account_content_blk">
                <div className="inner_content_wrapper">
                  <div className="inner_content">
                    <div className="account_content_box">
                      <p>Please register to access your account. Manage your products, finances, and profile here.</p>
                      <a href={`${constant.MYA_SITEURL}`} target="_blank" rel="noopener noreferrer" className="red_btn">MYSLOMINS</a>
                    </div>
                    <div className="account_content_box">
                      <p>Log into your MyShield account to manage your home security &amp; video systems.</p>
                      <a href="https://myshield.slomins.com/MyShield/Login.aspx?ReturnUrl=%2fMyshield%2f" target="_blank" rel="noopener noreferrer" className="red_btn">MYSHIELD</a>
                    </div>
                  </div>
                  <div className="account_content_bottom">
                    <p>Download the free Slomin's app for access on the go. </p>
                    <div className="badges">
                      <div className="badge_wrapper">
                        <a href="https://apps.apple.com/us/app/myslomins/id982788006" target="_blank" title="Download from the App Store"><img src="../img/app-store-badge.png" alt="App Store" /></a>
                      </div>
                      <div className="badge_wrapper">
                        <a href="https://play.google.com/store/apps/details?id=com.slomins.myslomins&amp;hl=en" target="_blank" title="Get it on Google Play"><img src="../img/google-play-badge.png" alt="Google Play" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
