import React, { useState, useEffect } from 'react';
import { constant } from '@app/utils/constant';
import { Link, useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [timer, setTimer] = useState(0);
  const location = useLocation();
  const currentPage = location.pathname;
  const getPageName = currentPage.replace(/^\//, '');
  const pageName = getPageName.endsWith('/') ? getPageName.slice(0, -1) : getPageName;
  const checkScrlPosition = () => {
    const sDistance = window.scrollY;
    const headerElement = document.querySelector('.header');
    if (headerElement) {
      if (sDistance > 50) {
        headerElement.classList.add('stickyHeader');
        setIsSticky(true);
      } else {
        headerElement.classList.remove('stickyHeader');
        setIsSticky(false);
      }
    }
  };
  const handleScroll = () => {
    if (!timer) {
      setTimer(
        setTimeout(() => {
          checkScrlPosition();
          setTimer(0);
        }, 250)
      );
    }
  };

  useEffect(() => {
    const handleScrollThrottled = () => handleScroll();
    window.addEventListener('scroll', handleScrollThrottled);
    return () => {
      window.removeEventListener('scroll', handleScrollThrottled);
    };
  }, [timer]);
  useEffect(() => {
    handleScroll();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = (event) => {
    const button = event.currentTarget;
    const parentLi = button.parentElement;
    const subMenuWrap = button.nextElementSibling;

    // Close all other sub menus
    document.querySelectorAll('.sub_menu_wrap').forEach((submenu) => {
      if (submenu !== subMenuWrap) {
        submenu.style.display = 'none';
      }
    });

    subMenuWrap.style.display = subMenuWrap.style.display === 'block' ? 'none' : 'block';

    // Toggle open_sub class
    button.classList.toggle('open_sub');

    // Toggle active_menu class
    if (activeMenu !== parentLi) {
      if (activeMenu) activeMenu.classList.remove('active_menu');
      parentLi.classList.add('active_menu');
      setActiveMenu(parentLi);
    } else {
      parentLi.classList.remove('active_menu');
      setActiveMenu(null);
    }

  };

  const handleClick = () => {
    // Scroll the page to the top
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  }


  return (
    <>
      <header className={`header landing_header ${pageName == "free-doorbell-camera" || pageName == "free-equipment-and-installation-now" || pageName == "audacy" || pageName == "next-day-installation" || pageName == "ppc-gm-affordablesecurity" || pageName == "iheartradio" || pageName == "ppc-freesecuritysystem" || pageName == "ppc-social-familysecurity" || pageName == "ppc-wirelesssecurity" || pageName == "iheart" || pageName == "ppc-social-propertysecurity" || pageName == "ppc-social-heirloomsecurity" || pageName == "ppc-gm-freedoorbell" || pageName == "ppc-freedoorbell" || pageName == "ppc-order-afforablesecurity" || pageName == "ppc-freeinstall" ? 'only_phone_header' : ''} ${isSticky ? 'stickyHeader' : ''}`}>
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-12 col-sm-3 col-lg-3">
              <div className="logo_toggle_wrap">
                <div className="logo_wrap">
                  <a href="/">
                    <img src="../../img/Slomins-Logo-New.svg" alt="Slomin's" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-9 col-lg-9">
              <div className="header_nav">
                <ul className="d-flex">
                  {pageName == "aragon" ?
                    <>
                      <li className="quote_btn"><a className='btn' href="#contact-form">Get Free Quote</a></li>
                      <li className="ph_number cir_btn"><a href="tel:18002527663" className='btn'></a></li>
                    </>
                    : ""
                  }
                  {pageName == "spotify" ?
                    <>
                      <li className="quote_btn"><a className='btn' href="#contact-form">Get Free Quote</a></li>
                      <li className="ph_number cir_btn"><a href="tel:18333071010" className='btn'></a></li>
                    </>
                    : ""
                  }
                  {pageName == "pandora" ?
                    <>
                      <li className="quote_btn"><a className='btn' href="#contact-form">Get Free Quote</a></li>
                      <li className="ph_number cir_btn"><a href="tel:18773441898" className='btn'></a></li>
                    </>
                    : ""
                  }
                  {pageName == "ppc-shopspecials" ?
                    <>
                      <li className="quote_btn"><a className='btn' href={`${constant.ALARAM_SITEURL}`}>Order Now</a></li>
                      <li className="ph_number cir_btn"><a href="tel:18334801010" className='btn'></a></li>
                    </>
                    : ""
                  }
                  {pageName == "next-day-installation" || pageName == "free-doorbell-camera" || pageName == "free-equipment-and-installation-now" || pageName == "ppc-gm-affordablesecurity" || pageName == "ppc-freesecuritysystem" || pageName == "ppc-social-familysecurity" || pageName == "ppc-wirelesssecurity" || pageName == "ppc-social-propertysecurity" || pageName == "ppc-social-heirloomsecurity" || pageName == "ppc-gm-freedoorbell" || pageName == "ppc-freedoorbell" || pageName == "ppc-order-afforablesecurity" || pageName == "ppc-freeinstall" ?
                    <>
                      <li className="ph_number cir_btn"><a href="tel:18334801010" className='btn'></a></li>
                    </>
                    : ""
                  }
                  {pageName == "audacy" || pageName == "iheartradio" ?
                    <>
                      <li className="ph_number cir_btn"><a href="tel:18773441898" className='btn'></a></li>
                    </>
                    : ""
                  }
                  {pageName == "affordablemonitoring" ?
                    <>
                      <li className="ph_number cir_btn"><a href="tel:18667566467" className='btn'></a></li>
                    </>
                    : ""
                  }
                  {pageName == "iheart" ?
                    <>
                      <li className="ph_number cir_btn"><a href="tel:15169802444" className='btn'></a></li>
                    </>
                    : ""
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
