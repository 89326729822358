import React from "react";

const Loader = (props) => {
    return (
        <>
            {props.isLoading && <div className="overlay loading">
                <div className="loading_wrap">
                    <div className="loading_inner">
                        <p>Loading....</p>
                        <div className="loading_spinner"></div>
                    </div>
                </div>
            </div>
            }

            {props.isLoading &&
                <div className="overlay_blk"></div>
            }
        </>
    )
};

export default Loader;
