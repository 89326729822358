import React, { useEffect } from 'react';
import { constant } from '@app/utils/constant';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { useParams } from 'react-router-dom';
import ProductJSONData from '../../components/products/ProductData';
import PageTitleComponent from '../../components/PageTitleComponent';
import PageNotFound from '@app/pages/Page404';
const HomeSecurityProductDetail = () => {
    const { slug } = useParams();
    let ProdDetailsArr: any = [];
    ProductJSONData.forEach((item, index) => {
        if (item.slug == slug) {
            ProdDetailsArr = {
                title: item.deatilsTitle,
                description: item.description,
                image: item.deatilsLargeImage,
                titleMain: item.title,
                deatilsSamllTitle: item.deatilsSamllTitle,
                key_features: item.key_features,
                disclaimerText: item.disclaimerText
            };
        }
    });
    if (ProdDetailsArr.length == 0) {
        //window.location.href = "/404";
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {ProdDetailsArr.titleMain !== undefined ?
                <>
                    <PageTitleComponent title={ProdDetailsArr.titleMain !== undefined ? ProdDetailsArr.titleMain + ' - Slomins' : ""} />
                    <main className="home_security_products-detail">
                        <section className="hero_blk home_security_products-detail-hero">
                            <div className="hero_inr container-xxl">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="hero_content">
                                            <h1>{ProdDetailsArr.titleMain !== undefined ? ProdDetailsArr.titleMain : ""}</h1>
                                            <p>{ProdDetailsArr.deatilsSamllTitle !== undefined ? ProdDetailsArr.deatilsSamllTitle : ""}</p>
                                            <a className="red_btn" href={`/get-a-quote/?aoi=04`}>Schedule free consultation</a>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className='prd_img_wrap'>
                                            <img src={`../../img/` + ProdDetailsArr.image} alt="slomins door/window sensor gray box" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="key-features_blk" style={{ background: '#fff' }}>

                            <div className="key-features_inr container-xxl">
                                <div className="row">
                                    {ProdDetailsArr.key_features.length > 0 ?
                                        <div className="col-12 key-features_title">
                                            <h2>Key Features</h2>
                                        </div>
                                        : ""
                                    }
                                    <div className="col-12 key-features_subtitle">
                                        <p>{ProdDetailsArr.description !== undefined ? ProdDetailsArr.description : ""}</p>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    {
                                        ProdDetailsArr.key_features.length > 0 ? ProdDetailsArr.key_features.map((row: any, podidx: any) => (
                                            <div className="col-12 col-sm-6 col-md-6 col-xxl-4" key={podidx}>
                                                <div className="key-features_box">
                                                    <div className="icon">{podidx + 1}</div>
                                                    <h3>{row.title}</h3>
                                                    <p>{row.description}</p>
                                                </div>
                                            </div>
                                        ))
                                            : ""
                                    }
                                </div>
                            </div>

                        </section>
                        <section className="get_slomin_shield-detial_blk">
                            <div className="get_slomin_shield_inr container-xxl">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <div className="get_slomin_shield_img">
                                            <img src="../../img/get-slomin-shield.png" alt="service repair" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <div className="get_slomin_shield_content">
                                            <h2>Get your Free Slomin's <span>Shield System</span></h2>
                                            <p>Get everything you need while saving big with a <a href={`${constant.ALARAM_SITEURL}`} target="_blank">free</a> Slomin's Shield system; installation included! $1145 in total savings.</p>
                                            <div className='quote_btn_wrap'>
                                                <a className="red_btn" href={`${constant.ALARAM_SITEURL}/shop`} target="_blank">Build your Package</a>
                                                <a className="white_btn" href={`/get-a-quote/?aoi=04`}>Get a Free quote</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            ProdDetailsArr.disclaimerText !== undefined && ProdDetailsArr.disclaimerText.length > 0 ?
                                <section className="faq_blk" id='disclaimer_blk'>
                                    <div className="container-xxl">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <div className="faq_box">
                                                    <div className="accordion" id="accordionDisclaimer">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingDisclaimer">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDisclaimer" aria-expanded="false" aria-controls="collapseDisclaimer">Disclaimer</button>
                                                            </h2>
                                                            <div id="collapseDisclaimer" className="accordion-collapse collapse" aria-labelledby="headingDisclaimer" data-bs-parent="#accordionDisclaimer">
                                                                <div className="accordion-body">
                                                                    <p>{ProdDetailsArr.disclaimerText !== undefined ? ProdDetailsArr.disclaimerText : ""}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                : ""
                        }
                        <SubscribeCompoents />
                    </main>
                </>
                :
                <PageNotFound />
            }
        </>
    );
};

export default HomeSecurityProductDetail;
