import { Link } from 'react-router-dom';
import { constant } from '@app/utils/constant';   
const BlogSection = (props: any) => {
    return (
        <>
            <div className={`products-list-home tab-pane fade show ${props.defaultclassName == 'active' ? 'active' : ''}`} id={`v-pills-` + props.categoryName} role="tabpanel" aria-labelledby={`v-pills-` + props.categoryName + `-tab`}>
                <div className="row text-center">
                    {
                        props.blogData.length > 0 ? props.blogData.map((row, podidx) => (
                            <div key={podidx} className="col-md-6 col-lg-4 col-12">
                                { 
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <Link to={`/` + row.slug}>
                                                <img src={constant.SITEURL+'/../img/blog/'+row.frontImageUrl} alt="slomins door/window sensor gray box" />
                                            </Link>
                                        </div>
                                        <div className="card-content">
                                            <div className="note">{row.subtitle}</div>
                                            <h4>
                                                <Link to={`/` + row.slug}>
                                                    {row.title}
                                                </Link>
                                            </h4>
                                            {/*<p>{row.description}</p>*/}
                                            <Link to={`/` + row.slug} className="button white_btn prd_learn_more">
                                                Learn More
                                            </Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        )) : ""}
                </div>
            </div>
        </>
    );
};

export default BlogSection;
